<template>
    <searchpage pageName="Applications" :filters="filters"></searchpage>
</template>

<script setup>
    import searchpage from "@/components/layout/SearchPage.vue";

    var filters = [
        {
            description: "Type",
            param: "ProductIds",
            type: "radio",
            results: [
                {
                    id: "skip",
                    name: "All",
                    checked: true
                },
                {
                    id: 1,
                    name: "Line of Credit (LOC)",
                    checked: false
                },
                {
                    id: 2,
                    name: "Refinance",
                    checked: false
                }
            ]
        },
        {
            description: "Status",
            param: "StatusIds",
            results: [
                {
                    id: 3,
                    name: "Submitted & Processing",
                    checked: false
                },
                {
                    id: 2,
                    name: "Evaluation",
                    checked: false
                },
                {
                    id: 1,
                    name: "Not Submitted",
                    checked: false
                },
                {
                    id: 8,
                    name: "Right To Cancel",
                    checked: false
                },
                {
                    id: 4,
                    name: "Complete",
                    checked: false
                },
                {
                    id: 5,
                    name: "Declined",
                    checked: false
                },
                {
                    id: 6,
                    name: "Cancelled",
                    checked: false
                }
            ]
        },
        {
            description: "Processor Filters",
            param: "FilterIds",
            results: [
                {
                    id: 4,
                    name: "Membership",
                    checked: false
                },
                {
                    id: 5,
                    name: "Final Review",
                    checked: false
                },
                {
                    id: 1,
                    name: "Analyst Review",
                    checked: false
                },
                {
                    id: 2,
                    name: "CU Review",
                    checked: false
                },
                {
                    id: 3,
                    name: "Active Credit Alert",
                    checked: false
                },
                {
                    id: 6,
                    name: "Managerial Review",
                    checked: false
                },
                {
                    id: 7,
                    name: "Priority Applications",
                    checked: false
                },
                {
                    id: 8,
                    name: "Doc Review Needed",
                    checked: false
                },
                {
                    id: 9,
                    name: "Technical Support",
                    checked: false,
                    internalOnly: true
                }
            ]
        },
        {
            description: "Stipulations",
            param: "StipulationStatusIds",
            results: [
                {
                    id: 1,
                    name: "Open",
                    checked: false
                },
                {
                    id: 2,
                    name: "Completed",
                    checked: false
                },
                {
                    id: 3,
                    name: "CU Override",
                    checked: false
                },
                {
                    id: 4,
                    name: "Cancelled",
                    checked: false
                },
                {
                    id: 5,
                    name: "Pending",
                    checked: false
                }
            ]
        }
    ];
</script>