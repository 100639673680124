import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "expanded-row"
}
const _hoisted_2 = {
  class: "expanded-data",
  colspan: 12
}
const _hoisted_3 = { class: "expanded-wrapper" }
const _hoisted_4 = { class: "action-button-container" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]

import { ref, computed } from 'vue';
	import axios from 'axios';
	import functions from '@/use/functions';
	import { PayoffDetails, PayoffPostDisbursementChange } from '@/models/loans';
	import PayoffForm from '@/components/loan/refi/PayoffForm.vue';
	import { usePayoffData } from '@/composables/loan/refi/usePayoffData';
	import { useFormFields } from '@/composables/loan/refi/usePayoffForm';
	import PermissionsWrapper from '@/components/wrappers/PermissionsWrapper.vue';
	import { Permissions, PermissionLevels } from '@/models/permissions';
	import ServicerMailingInformation from './ServicerMailingInformation.vue';
	import PaymentDetails from './PaymentDetails.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'PayoffRow',
  props: {
    payoff: {},
    onPayoffUpdatedAsync: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	// todo: expand from query?

	const payoff = computed<PayoffDetails>(() => props.payoff);

	const isExpanded = ref(false);
	const isSubmitting = ref<boolean>(false);
	const mode = ref<'view' | 'edit'>('view');

	const { payoffDisbursementStatusOptions, payoffDisbursementStatusReasonOptions, payoffDisbursementTypeOptions } =
		usePayoffData();

	const { formFields, validationSchema } = useFormFields({
		mode,
		payoff,
		payoffDisbursementStatusOptions,
		payoffDisbursementStatusReasonOptions,
		payoffDisbursementTypeOptions
	});

	const toggleExpand = () => (isExpanded.value = !isExpanded.value);

	const changeMode = (newMode: 'view' | 'edit') => (mode.value = newMode);

	async function updatePayoffAsync(
		formData: Record<string, any>,
		postDisbursementChange: PayoffPostDisbursementChange | null
	) {
		const payoffId = payoff.value.id;

		isSubmitting.value = true;

		// set to unadjusted amounts to preserve on patch
		formData['disbursementAmount'] = payoff.value.disbursementAmount;
		formData['payoffFees'] = payoff.value.payoffFees;

		const request = {
			id: payoffId,
			postDisbursementChange,
			...formData
		};

		functions.openModal({
			title: 'Confirm',
			description: `Are you sure you want to update payoff ${payoffId}?`,
			buttons: [
				{ title: 'Cancel', onClick: () => functions.closeModal() },
				{
					title: 'Save Changes',
					onClick: async () => {
						try {
							await axios.patch(`/api/loanpayoffs/${payoffId}`, request);
							await props.onPayoffUpdatedAsync();

							changeMode('view');

							functions.openModal({
								title: 'Success!',
								description: `Payoff ${payoffId} updated successfully!`
							});
						} catch (error: any) {
							let errorMessage = 'There was an issue updating the payoff. Please try again later.';

							if (error.response?.data?.errors) {
								const errorMessages: any[] = Object.values(error.response?.data?.errors).flat();

								if (errorMessages.length > 0) {
									errorMessage = errorMessages.join(' ');
								}
							}

							functions.openModal({
								title: 'Oops!',
								description: errorMessage
							});
						} finally {
							isSubmitting.value = false;
						}
					}
				}
			]
		});
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", {
      class: "data-row",
      onClick: toggleExpand
    }, [
      _createElementVNode("td", null, _toDisplayString(payoff.value.id), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.batchId), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.disbursementStatusHumanized), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.lenderName), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.servicerName), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.accountNumber), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.transactionDateFormatted), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.disbursementAmountFormatted), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.adjustedDisbursementAmountFormatted), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.disbursementTypeHumanized), 1),
      _createElementVNode("td", null, _toDisplayString(payoff.value.settleDateFormatted), 1)
    ]),
    (isExpanded.value)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
          _createElementVNode("td", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(PermissionsWrapper, {
                permissionIds: [_unref(Permissions).Disbursements],
                permissionLevel: _unref(PermissionLevels).Update
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("button", {
                      disabled: mode.value === 'edit',
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (changeMode('edit')))
                    }, "Edit", 8, _hoisted_5),
                    _withDirectives(_createElementVNode("button", {
                      disabled: isSubmitting.value,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (changeMode('view')))
                    }, " Cancel ", 8, _hoisted_6), [
                      [_vShow, mode.value !== 'view']
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["permissionIds", "permissionLevel"]),
              _createVNode(PayoffForm, {
                isSubmitting: isSubmitting.value,
                mode: mode.value,
                fields: _unref(formFields),
                validationSchema: _unref(validationSchema),
                originalDisbursementAmount: payoff.value.adjustedDisbursementAmount,
                onSubmit: updatePayoffAsync
              }, null, 8, ["isSubmitting", "mode", "fields", "validationSchema", "originalDisbursementAmount"]),
              _createVNode(ServicerMailingInformation, {
                subServicerName: payoff.value.subServicerName,
                address: payoff.value.subServicerAddress
              }, null, 8, ["subServicerName", "address"]),
              _createVNode(PaymentDetails, {
                financialInstitution: payoff.value.financialInstitution
              }, null, 8, ["financialInstitution"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})