import { defineStore, storeToRefs } from "pinia";
import { ref, computed } from "vue";
import {
  PayoffDetailViewModel,
  PayoffDocumentViewModel,
  LOSType,
  SubServicerViewModel,
  ApplicationPayoffsResponse,
  UpdateRefiLoanRequest,
  LinkUnlinkDocumentToRefiLoanRequest,
  RefiLoanRequest,
  PayoffForm,
  UpdateRefiLoanResponse,
  AddRefiLoanResponse,
  PayoffFormSchemas,
  PayoffForms,
} from "@/types/payoff";
import { FormSchema } from "@/models/form";
import { TabIdentifiers, TabStatusNames } from "@/types/tabs";
import functions from "@/use/functions";
import { $api } from "@/services/api1";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import { usePayoffFormStore } from "@/store/store/payoffFormStore";
import { useLoadingStore } from "@/store/store/loadingStore";

export const usePayoffTabStore = defineStore("payoffTab", () => {
  const applicationsStore = useApplicationStore();
  const payoffFormStore = usePayoffFormStore();
  const loadingStore = useLoadingStore();

  const { applicationId } = storeToRefs(applicationsStore);

  const newForm = ref<PayoffForm>({
    id: 0,
    refiLoanId: undefined,
    servicerName: "",
    subServicerName: "",
    creditorName: "",
    accountNumber: "",
    include: true,
    excludedLoan: false,
    payoffFinalized: false,
    servicerId: 0,
    subServicerId: 0,
    balanceEffectiveDate: undefined,
    balanceEffectiveDateDisplay: "",
    submittedPayoffBalance: undefined,
    submittedPayoffBalanceDisplay: "",
    estimatedSettleDate: undefined,
    estimatedSettleDateDisplay: "",
    payoffAmount: undefined,
    payoffAmountDisplay: "",
    isDocAttached: false,
    viewAdditionalDetails: true,
    payoffsVerified: false,
    payoffDisbursementTypeId: undefined,
    payoffDisbursementType: "",
    financialInstitutionsId: undefined,
    financialInstitution: "",
    addAttentionTo: false,
    mrcAccountNumber: "",
    routingNumber: "",
    mailedCheckDetailsId: undefined,
    payableTo: "",
    payoffAddressId: undefined,
    address: "",
    attentionTo: "",
    attentionToId: undefined,
    daysOfInterest: undefined,
    additionalFees: undefined,
    additionalFeesDisplay: "",
    interestRate: undefined,
    interestRateDisplay: "",
    dailyInterest: undefined,
    dailyInterestDisplay: "",
    tradeLineId: undefined,
    manualInput: true,
    finalizePayoff: false,
    linkedDocuments: [],
    availableDocuments: [],
    viewLinkedDocuments: false,
    documentDropdownOpen: false,
    editLinkedDocuments: true,
    currentDocumentId: 0,
    interestRateAvailable: false,
    stipulationIds: [],
    linkRequests: [],
    unlinkRequests: [],
    saveChangesClicked: false,
    cancelChangesClicked: false,
  });

  const payoffFormSchema = ref<FormSchema>({
    subServicerId: {
      name: "subServicerId",
      type: "search-dropdown",
      label: "Servicer",
      autocompleteProperty: "name",
      placeholder: "Search",
      hideLabel: false,
      required: true,
      hideErrorMessage: true,
    },
    creditorName: {
      name: "creditorName",
      type: "text",
      label: "Lender",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
      maxLength: 40,
    },
    accountNumber: {
      name: "accountNumber",
      type: "text",
      label: "Account #",
      hideLabel: false,
      required: true,
      hideErrorMessage: true,
    },
    submittedPayoffBalance: {
      name: "submittedPayoffBalance",
      type: "money",
      label: "Sub. Payoff Balance",
      hideLabel: false,
      required: true,
      hideErrorMessage: true,
    },
    balanceEffectiveDate: {
      name: "balanceEffectiveDate",
      type: "date",
      label: "Balance Eff. Date",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
    },
    estimatedSettleDate: {
      name: "estimatedSettleDate",
      type: "readonly",
      label: "Est. Settle Date",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
    },
    payoffAmount: {
      name: "payoffAmount",
      type: "readonly",
      label: "Payoff Amount",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
    },
    payoffDisbursementTypeId: {
      name: "payoffDisbursementTypeId",
      type: "select",
      label: "Payment Method",
      required: true,
    },
    routingNumber: {
      name: "routingNumber",
      type: "text",
      label: "ABA Routing Number",
      required: false,
      readOnly: true,
    },
    daysOfInterest: {
      name: "daysOfInterest",
      type: "readonly",
      label: "Days of Interest",
      required: false,
      readOnly: true,
    },
    interestRate: {
      name: "interestRate",
      type: "text",
      label: "Interest Rate",
      hideLabel: false,
      required: false,
    },
    interestRateAvailable: {
      name: "interestRateAvailable",
      type: "toggle",
      label: "",
      hideLabel: true,
      required: false,
    },
    financialInstitution: {
      name: "financialInstitution",
      type: "readonly",
      label: "Financial Inst.",
      required: false,
      readOnly: true,
    },
    mrcAccountNumber: {
      name: "mrcAccountNumber",
      type: "text",
      label: "MRC Account #",
      required: false,
      readOnly: true,
    },
    additionalFees: {
      name: "additionalFees",
      type: "money",
      label: "Additional Fees",
      required: false,
    },
    dailyInterest: {
      name: "dailyInterest",
      type: "readonly",
      label: "Daily Interest",
      required: false,
      readOnly: true,
    },
    payableTo: {
      name: "payableTo",
      type: "search-dropdown",
      label: "Payable To",
      required: true,
      placeholder: "Search",
      autocompleteProperty: "payableTo",
      autoCompleteResultsErrorMessage: "No matches found",
    },
    address: {
      name: "address",
      type: "search-dropdown",
      label: "Payoff Address",
      required: true,
      placeholder: "Search",
      autocompleteProperty: "address",
      autoCompleteResultsErrorMessage: "No matches found",
    },
    attentionTo: {
      name: "attentionTo",
      type: "search-dropdown",
      label: "Attention To",
      placeholder: "Search",
      autocompleteProperty: "name",
      required: false,
    },
    include: {
      type: "checkbox",
      label: "Include",
      name: "include",
    },
    finalizePayoff: {
      type: "checkbox",
      label: "Finalize Payoff",
      name: "finalizePayoff",
    },
  });

  const gettingPayoffDetails = ref<boolean>(true);
  const subServicers = ref<Array<SubServicerViewModel>>([]);
  const attentionTos = ref<Array<LOSType>>([]);
  const newPayoffForms = ref<Array<PayoffForm>>([]);
  const includedPayoffForms = ref<Array<PayoffForm>>([]);
  const excludedPayoffForms = ref<Array<PayoffForm>>([]);
  const addingOrUpdatingPayoff = ref<boolean>(false);
  const addingAttentionTo = ref<boolean>(false);
  const payoffFormSchemas = ref<PayoffFormSchemas>({});
  const newPayoffFormSchemas = ref<PayoffFormSchemas>({});
  const payoffTabData = ref<ApplicationPayoffsResponse>(
    {} as ApplicationPayoffsResponse
  );
  const addAdditionalLoanButtonDisabled = computed(
    () => newPayoffForms.value?.length > 0
  );

  const payoffTableHeaders = ref<Array<any>>([
    {
      name: "verified",
      label: "",
    },
    {
      name: "servicer",
      label: "Servicer",
    },
    {
      name: "lender",
      label: "Lender",
    },
    {
      name: "accountNumber",
      label: "Account #",
    },
    {
      name: "submittedPayoffBalance",
      label: "Sub. Payoff Balance",
      title: "Submitted Payoff Balance",
    },
    {
      name: "balanceEffectiveDate",
      label: "Balance Eff. Date",
      title: "Balance Effective Date",
    },
    {
      name: "estimatedSettlementDate",
      label: "Est. Settlement Date",
      title: "Estimated Settle Date",
    },
    {
      name: "payoffAmount",
      label: "Payoff Amount",
    },
    {
      name: "docAttached",
      label: "Doc Attached?",
    },
    {
      name: "toggle",
      label: "",
    },
  ]);

  //////////////////////////////////////////////////////////////
  // Getters
  //////////////////////////////////////////////////////////////
  const availableDocuments = computed<Array<PayoffDocumentViewModel>>(
    () => payoffTabData?.value?.results?.availableDocuments || []
  );

  const newPayoffFormsCount = computed(
    () => Object.keys(newPayoffFormSchemas.value).length
  );

  const newPayoffFormId = computed(() =>
    newPayoffFormsCount.value === 0 ? 0 : newPayoffFormsCount.value + 1
  );

  const totalConsolidatedLoanAmountDisplay = computed<string>(
    () =>
      payoffTabData?.value?.results?.totalConsolidatedLoanAmountDisplay || ""
  );

  const payoffsVerified = computed<boolean>(
    () => payoffTabData?.value?.results?.payoffsVerified === true
  );

  const includedLoans = computed(
    () => payoffTabData.value?.results?.includedLoans
  );

  const excludedLoans = computed(
    () => payoffTabData.value?.results?.excludedLoans
  );

  function addPayoffFormObj(arr: Array<PayoffForm>, form: PayoffForm) {
    const index = arr.findIndex((x: PayoffForm) => x.id === form.id);
    if (index > -1) {
      arr[index] = form;
    } else {
      arr.push(form);

      if (form.refiLoanId && form.refiLoanId > 0) {
        payoffFormSchemas.value[form.id] = { ...payoffFormSchema.value };
      } else {
        newPayoffFormSchemas.value[form.id] = { ...payoffFormSchema.value };
      }
    }
  }

  function getNewPayoffFormSchema(payoffForm: PayoffForm) {
    return newPayoffFormSchemas.value[payoffForm.id];
  }

  function getPayoffFormSchema(payoffForm: PayoffForm) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      return payoffFormSchemas.value[payoffForm.id];
    } else {
      return newPayoffFormSchemas.value[payoffForm.id];
    }
  }

  function buildLinkUnlinkDocumentToRefiLoanRequest(
    payoff: PayoffForm,
    document: PayoffDocumentViewModel
  ): LinkUnlinkDocumentToRefiLoanRequest {
    return {
      refiLoanId: payoff.id,
      applicationId: applicationId.value,
      applicantDocumentId: document.applicantDocumentId,
    };
  }
  function resetNewPayoffForm() {
    newForm.value = {
      id: 0,
      refiLoanId: undefined,
      servicerName: "",
      subServicerName: "",
      creditorName: "",
      accountNumber: "",
      include: true,
      excludedLoan: false,
      payoffFinalized: false,
      servicerId: 0,
      subServicerId: 0,
      balanceEffectiveDate: undefined,
      balanceEffectiveDateDisplay: "",
      submittedPayoffBalance: undefined,
      submittedPayoffBalanceDisplay: "",
      estimatedSettleDate: undefined,
      estimatedSettleDateDisplay: "",
      payoffAmount: undefined,
      payoffAmountDisplay: "",
      isDocAttached: false,
      viewAdditionalDetails: true,
      payoffsVerified: false,
      payoffDisbursementTypeId: undefined,
      payoffDisbursementType: "",
      financialInstitutionsId: undefined,
      financialInstitution: "",
      addAttentionTo: false,
      mrcAccountNumber: "",
      routingNumber: "",
      mailedCheckDetailsId: undefined,
      payableTo: "",
      payoffAddressId: undefined,
      address: "",
      attentionTo: "",
      attentionToId: undefined,
      daysOfInterest: undefined,
      additionalFees: undefined,
      additionalFeesDisplay: "",
      interestRate: undefined,
      interestRateDisplay: "",
      dailyInterest: undefined,
      dailyInterestDisplay: "",
      tradeLineId: undefined,
      manualInput: true,
      finalizePayoff: false,
      linkedDocuments: [],
      availableDocuments: [],
      viewLinkedDocuments: false,
      documentDropdownOpen: false,
      editLinkedDocuments: true,
      currentDocumentId: 0,
      interestRateAvailable: false,
      stipulationIds: [],
      linkRequests: [],
      unlinkRequests: [],
      saveChangesClicked: false,
      cancelChangesClicked: false,
    };
  }

  async function getApplicationPayoffs(applicationId: number) {
    functions.closeModal();
    includedPayoffForms.value = [];
    excludedPayoffForms.value = [];
    newPayoffForms.value = [];

    const response = await $api.payoffs.getApplicationPayoffs(applicationId);

    if (response.success) {
      payoffTabData.value = response;
      // Set subservicers
      subServicers.value = response.results.subServicers;

      // Set attentionTos
      attentionTos.value = response.results.attentionTos;

      if (response.results.includedLoans?.length > 0) {
        response.results.includedLoans.forEach(
          (payoff: PayoffDetailViewModel) => {
            const form = payoffFormStore.payoffToPayoffForm(payoff);

            addPayoffFormObj(includedPayoffForms.value, form);
          }
        );
      }

      // Add payoff forms for excluded loans
      if (response.results.excludedLoans?.length > 0) {
        response.results.excludedLoans.forEach(
          (payoff: PayoffDetailViewModel) => {
            const form = payoffFormStore.payoffToPayoffForm(payoff);
            addPayoffFormObj(excludedPayoffForms.value, form);
          }
        );
      }
    }

    gettingPayoffDetails.value = false;
  }

  async function saveChanges(
    payoffForm: PayoffForm,
    request: UpdateRefiLoanRequest | RefiLoanRequest
  ) {
    addingOrUpdatingPayoff.value = true;

    functions.setModalLoading();
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      loadingStore.setLoading(true);
      await updateRefiLoan(payoffForm, request as UpdateRefiLoanRequest);
      loadingStore.setLoading(false);
    } else {
      loadingStore.setLoading(true);
      await addRefiLoan(payoffForm, request as RefiLoanRequest);
      loadingStore.setLoading(false);
    }

    toggleSaveChangesClicked(payoffForm, false);
    if (payoffForm.viewLinkedDocuments) {
      closeLinkedDocumentsModal(payoffForm);
    }
  }

  async function updateRefiLoan(
    payoffForm: PayoffForm,
    request: UpdateRefiLoanRequest
  ) {
    addingOrUpdatingPayoff.value = true;
    const response = await $api.payoffs.updatePayoff(request);

    if (response.success) {
      functions.closeModal();

      response.linkRequests = payoffForm.linkRequests;
      response.unlinkRequests = payoffForm.unlinkRequests;

      await handleUpdateRefiLoanResponse(payoffForm, response);
    } else {
      if (response?.errors?.length > 0) {
        handleResponseErrors(response.errors);
      } else {
        return;
      }
    }

    addingOrUpdatingPayoff.value = false;
  }

  async function addRefiLoan(payoffForm: PayoffForm, request: RefiLoanRequest) {
    addingOrUpdatingPayoff.value = true;

    const response = await $api.payoffs.addPayoff(request);
    if (response.success) {
      functions.closeModal();

      response.linkRequests = payoffForm.linkRequests;
      response.unlinkRequests = payoffForm.unlinkRequests;

      await handleAddRefiLoanResponse(payoffForm, response);
    } else {
      if (response?.errors?.length > 0) {
        handleResponseErrors(response.errors);
      } else {
        return;
      }
    }

    addingOrUpdatingPayoff.value = false;
  }

  async function verifyPayoffs() {
    const response = await $api.payoffs.verifyPayoffs(applicationId.value);

    if (response.success) {
      applicationsStore.updateTabTags();
    } else {
      if (response?.errors?.length > 0) {
        handleResponseErrors(response.errors);
      }
    }
  }

  //////////////////////////////////////////////////////////////
  // Response handlers
  //////////////////////////////////////////////////////////////
  function handleResponseErrors(errors: string[]) {
    if (errors && errors.length > 0) {
      let html = "";
      errors.forEach((error: string) => {
        html += `<li style="color: red; font-weight: 700;">${error}</li>`;
      });
      const modalHtml =
        html === ""
          ? false
          : `<ul class='xmodal-changes' style="list-style: none">${html}</ul>`;

      functions.openModal({
        title: "Error",
        description: "",
        html: modalHtml,
      });
    }
  }

  function handleInvalidFormError() {
    const error =
      "*One or more required fields have not been completed, changes not saved.";
    const modaHtml = `<div style=" list-style-type: none;color: red; font-weight: 700;">${error}<div>`;
    functions.openModal({
      title: "Alert",
      description: "",
      html: modaHtml,
    });
  }

  async function handleAddRefiLoanResponse(
    payoffForm: PayoffForm,
    response: AddRefiLoanResponse
  ) {
    // Handle linking of docs
    // Link or unlink docs
    handleLinkRequests(payoffForm, response.linkRequests);
    handleUnlinkRequests(payoffForm, response.unlinkRequests);

    // #TODO handle data update without having to remount the details
    // //#TODO update data- remove from new loans forms
    // removeNewPayoffForm(payoffForm);

    // //#TODO Add to the included or exclude loans
    // addOriginalLoan(response.results.thisRefiLoan);

    // //#TODO Add to the included or exclude loan forms
    // addOriginalLoanForm(response.results.thisRefiLoan);

    // Handles consolidated loan amount
    updatePayoffTabData(response);

    removeNewPayoffForm(payoffForm);
    resetNewPayoffForm();
    await getApplicationPayoffs(applicationId.value);
  }

  async function handleUpdateRefiLoanResponse(
    payoffForm: PayoffForm,
    response: UpdateRefiLoanResponse
  ) {
    // Link or unlink docs
    handleLinkRequests(payoffForm, response.linkRequests);
    handleUnlinkRequests(payoffForm, response.unlinkRequests);

    // //#TODO - update the "backup" for refi loan form
    // updateOriginalPayoff(response.results.thisRefiLoan);

    // Handles consolidated loan amount
    updatePayoffTabData(response);

    await getApplicationPayoffs(applicationId.value);
  }

  // State Management helpers
  function updateIncludedPayoffForms(updatedPayoffForms: PayoffForm[]) {
    includedPayoffForms.value = updatedPayoffForms;
  }

  function updateExcludeddPayoffForms(updatedPayoffForms: PayoffForm[]) {
    excludedPayoffForms.value = updatedPayoffForms;
  }

  function updateExcludedPayoffForm(formData: PayoffForm) {
    const index = excludedPayoffForms.value?.findIndex(
      (payoff: PayoffForm) => payoff.id === payoff.id
    );

    excludedPayoffForms.value[index] = formData;
  }

  function updateIncludedPayoffForm(formData: PayoffForm) {
    const index = includedPayoffForms.value?.findIndex(
      (payoff: PayoffForm) => payoff.id === payoff.id
    );

    includedPayoffForms.value[index] = formData;

    return includedPayoffForms.value[index];
  }

  function updateExcludedRefiLoans(payoffs: PayoffDetailViewModel[]) {
    payoffTabData.value.results.excludedLoans = payoffs;
  }

  function updateIncludedRefiLoans(payoffs: PayoffDetailViewModel[]) {
    payoffTabData.value.results.includedLoans = payoffs;
  }

  function updateIncludedRefiLoan(payoff: PayoffDetailViewModel) {
    const index = payoffTabData.value.results.includedLoans?.findIndex(
      (payoff: PayoffForm) => payoff.id === payoff.id
    );

    payoffTabData.value.results.includedLoans[index] = payoff;

    return payoffTabData.value.results.includedLoans[index];
  }

  function updateExcludedRefiLoan(payoff: PayoffDetailViewModel) {
    const index = payoffTabData.value.results.excludedLoans?.findIndex(
      (payoff: PayoffForm) => payoff.id === payoff.id
    );

    payoffTabData.value.results.excludedLoans[index] = payoff;

    return payoffTabData.value.results.excludedLoans[index];
  }

  function updatePayoffTabData(response: UpdateRefiLoanResponse) {
    payoffTabData.value.results.totalConsolidatedLoanAmount =
      response.results.totalConsolidatedLoanAmount;
    payoffTabData.value.results.totalConsolidatedLoanAmountDisplay =
      response.results.totalConsolidatedLoanAmountDisplay;
  }

  //////////////////////////////////////////////////////////////
  // PAYOFF DOCUMENTS FUNCTIONS
  //////////////////////////////////////////////////////////////
  // #region PAYOFF DOCUMENTS FUNCTIONS
  function attachDocument(
    payoffForm: PayoffForm,
    document: PayoffDocumentViewModel
  ) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      let availableDocuments = payoffForms[index].availableDocuments;
      const linkedDocuments = payoffForms[index].linkedDocuments;
      const linkRequests = payoffForms[index].linkRequests;

      // Remove from available documents
      const availableDocIndex = getObjectIndex(
        availableDocuments,
        "documentId",
        document.documentId
      );
      if (availableDocIndex > -1) {
        availableDocuments = removeObjectByIndex(
          availableDocuments,
          availableDocIndex
        );
      }

      // Add to linked documents
      const linkedDocIndex = getObjectIndex(
        linkedDocuments,
        "documentId",
        document.documentId
      );
      if (linkedDocIndex === -1) {
        payoffForms[index].linkedDocuments.push(document);
      }

      // Add Link Request
      const linkRequestIndex = getObjectIndex(
        availableDocuments,
        "documentId",
        document.documentId
      );
      if (linkRequestIndex === -1) {
        linkRequests.push(
          buildLinkUnlinkDocumentToRefiLoanRequest(payoffForm, document)
        );
      }

      replacePayoffForm(payoffForms[index]);
    }
  }

  function detachDocument(
    payoffForm: PayoffForm,
    document: PayoffDocumentViewModel
  ) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      // Add to available documents
      const availableDocIndex = payoffForms[index].availableDocuments.findIndex(
        (availableDoc: PayoffDocumentViewModel) => availableDoc === document
      );
      if (availableDocIndex === -1) {
        payoffForms[index].availableDocuments.push(document);
      }

      // Remove from linked documents
      const linkedDocIndex = payoffForms[index].linkedDocuments.findIndex(
        (linkedDoc: PayoffDocumentViewModel) => linkedDoc === document
      );

      if (linkedDocIndex > -1) {
        payoffForms[index].linkedDocuments.splice(linkedDocIndex, 1);
      }

      // Remove link request if it exists
      const linkRequestIndex = payoffForms[index].linkRequests.findIndex(
        (request: LinkUnlinkDocumentToRefiLoanRequest) =>
          request.applicantDocumentId === document.applicantDocumentId
      );
      if (linkRequestIndex > -1) {
        payoffForms[index].linkRequests.splice(linkRequestIndex, 1);
      }

      // Add unlink request
      const unlinkRequestIndex = payoffForms[index].unlinkRequests.findIndex(
        (request: LinkUnlinkDocumentToRefiLoanRequest) =>
          request.applicantDocumentId === document.applicantDocumentId
      );
      if (unlinkRequestIndex > -1) {
        payoffForms[index].unlinkRequests.push(
          buildLinkUnlinkDocumentToRefiLoanRequest(payoffForm, document)
        );
      }
    }
  }
  // #endregion

  //////////////////////////////////////////////////////////////
  // HELPERS
  //////////////////////////////////////////////////////////////
  function getObjectIndex(
    arr: Array<any>,
    property: string,
    value: number | string
  ) {
    let index = -1;

    if (property) {
      index = arr?.findIndex((obj) => obj[`${property}`] === value);
    } else {
      index = arr?.findIndex((obj) => obj === value);
    }

    return index > -1 ? index : -1;
  }

  function removeObjFromArr(
    arr: Array<any>,
    property: string,
    value: number | string
  ) {
    const index = arr.findIndex((obj) => obj[`${property}`] === value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  function removeObjectByIndex(arr: Array<any>, index: number) {
    if (index > -1) {
      arr.splice(index, 1);
    }

    return arr;
  }

  function getOriginalPayoff(payoffForm: PayoffForm) {
    const arr = !payoffForm.excludedLoan
      ? payoffTabData.value.results.includedLoans
      : payoffTabData.value.results.excludedLoans;

    const original = arr.find(
      (p: PayoffDetailViewModel) => p.id === payoffForm.id
    );
    return original;
  }

  function getPayoffFormIndex(payoffForm: PayoffForm) {
    const payoffForms = getPayoffForms(payoffForm);
    const index = payoffForms.findIndex((r: any) => r.id === payoffForm.id);
    return index > -1 ? index : -1;
  }

  //////////////////////////////////////////////////////////////
  // FORM ACTIONS
  //////////////////////////////////////////////////////////////
  function confirmCancel(payoffForm: PayoffForm) {
    functions.openModal({
      title: "Are you sure you want to cancel the changes made?",
      description: "",
      buttons: [
        {
          title: "Keep Changes",
          onClick: () => {
            toggleCancelChangesClicked(payoffForm, false);
            functions.closeModal();
          },
        },
        {
          title: "Cancel Changes",
          onClick: () => {
            discardChanges(payoffForm);
          },
        },
      ],
    });
  }

  function discardChanges(payoffForm: PayoffForm) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const originalPayoff = getOriginalPayoff(payoffForm);
      const originalForm = payoffFormStore.payoffToPayoffForm(originalPayoff);

      originalForm.viewAdditionalDetails = true;
      originalForm.cancelChangesClicked = true;
      replacePayoffForm(originalForm);
    } else {
      removeNewPayoffForm(payoffForm);
      resetNewPayoffForm();
    }

    if (payoffForm.viewLinkedDocuments) {
      closeLinkedDocumentsModal(payoffForm);
    }

    functions.closeModal();
  }

  // #region New payoff form Functions
  function addNewPayoffForm() {
    const payoff = newForm.value;

    payoff.id = newPayoffFormId.value;

    if (availableDocuments.value && availableDocuments.value?.length > 0) {
      payoff.availableDocuments = availableDocuments.value;
    }

    addPayoffFormObj(newPayoffForms.value, payoff);
  }

  function removeNewPayoffForm(payoffForm: PayoffForm) {
    // id is same as index for new forms
    newPayoffForms.value?.splice(payoffForm.id, 1);
    delete newPayoffFormSchemas.value[payoffForm.id];
  }

  // #endregion

  //////////////////////////////////////////////////////////////
  // PAYOFF FORMS
  //////////////////////////////////////////////////////////////
  function getPayoffForms(payoffForm: PayoffForm) {
    return payoffForm.refiLoanId && payoffForm.refiLoanId > 0
      ? payoffForm.excludedLoan === false
        ? includedPayoffForms.value
        : excludedPayoffForms.value
      : newPayoffForms.value;
  }

  function getPayoffForm(payoffForm: PayoffForm) {
    const payoffForms = getPayoffForms(payoffForm);
    return payoffForms?.find((form: PayoffForm) => form.id === payoffForm.id);
  }

  //////////////////////////////////////////////////////////////
  // TABLE ROW ACTIONS
  //////////////////////////////////////////////////////////////
  function toggleSaveChangesClicked(payoffForm: PayoffForm, flag?: boolean) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const payoffForms = getPayoffForms(payoffForm);
      const index = payoffForms.findIndex((r: any) => r.id === payoffForm.id);

      if (index > -1) {
        payoffForms[index].saveChangesClicked =
          flag !== undefined ? flag : !payoffForms[index].saveChangesClicked;
      }
    } else {
      const index = newPayoffForms.value.findIndex(
        (r: any) => r.id === payoffForm.id
      );

      if (index > -1) {
        newPayoffForms.value[index].saveChangesClicked =
          flag !== undefined
            ? flag
            : !newPayoffForms.value[index].saveChangesClicked;
      }
    }
  }

  function toggleCancelChangesClicked(payoffForm: PayoffForm, flag?: boolean) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const payoffForms = getPayoffForms(payoffForm);
      const index = payoffForms.findIndex((r: any) => r.id === payoffForm.id);

      if (index > -1) {
        payoffForms[index].cancelChangesClicked =
          flag !== undefined ? flag : !payoffForms[index].cancelChangesClicked;
      }
    } else {
      const index = newPayoffForms.value.findIndex(
        (r: any) => r.id === payoffForm.id
      );

      if (index > -1) {
        newPayoffForms.value[index].cancelChangesClicked =
          flag !== undefined
            ? flag
            : !newPayoffForms.value[index].cancelChangesClicked;
      }
    }
  }

  function toggleIncludePayoff(payoffForm: PayoffForm, flag?: boolean) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const payoffForms = getPayoffForms(payoffForm);
      const index = payoffForms.findIndex((r: any) => r.id === payoffForm.id);

      if (index > -1) {
        payoffForms[index].include =
          flag !== undefined ? flag : !payoffForms[index].include;
      }
    } else {
      const index = newPayoffForms.value.findIndex(
        (r: any) => r.id === payoffForm.id
      );

      if (index > -1) {
        newPayoffForms.value[index].include =
          flag !== undefined ? flag : !newPayoffForms.value[index].include;
      }
    }
  }

  function toggleFinalizePayoff(payoffForm: PayoffForm, flag?: boolean) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const payoffForms = getPayoffForms(payoffForm);
      const index = payoffForms.findIndex((r: any) => r.id === payoffForm.id);

      if (index > -1) {
        payoffForms[index].finalizePayoff =
          flag !== undefined ? flag : !payoffForms[index].finalizePayoff;
      }
    } else {
      const index = newPayoffForms.value.findIndex(
        (r: any) => r.id === payoffForm.id
      );

      if (index > -1) {
        newPayoffForms.value[index].finalizePayoff =
          flag !== undefined
            ? flag
            : !newPayoffForms.value[index].finalizePayoff;
      }
    }
  }

  function toggleAdditionalDetails(payoffForm: PayoffForm) {
    const payoffForms = getPayoffForms(payoffForm);
    const index = payoffForms.findIndex((r: any) => r.id === payoffForm.id);

    if (index > -1) {
      payoffForms[index].viewAdditionalDetails =
        !payoffForms[index].viewAdditionalDetails;
    }
  }

  function toggleLinkedDocumentsEditing(payoffForm: PayoffForm, flag: boolean) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      payoffForms[index].editLinkedDocuments = flag;
    }
  }

  function replacePayoffForm(payoffForm: PayoffForm) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const index = getPayoffFormIndex(payoffForm);

      if (index > -1) {
        if (!payoffForm.excludedLoan) {
          includedPayoffForms.value[index] = payoffForm;
        } else {
          excludedPayoffForms.value[index] = payoffForm;
        }
      }
    } else {
      newPayoffForms.value[payoffForm.id] = payoffForm;
    }
  }

  function toggleLinkedDocumentsModal(payoffForm: PayoffForm) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      payoffForms[index].viewLinkedDocuments =
        !payoffForms[index].viewLinkedDocuments;
    }
  }

  function closeLinkedDocumentsModal(payoffForm: PayoffForm) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      payoffForms[index].viewLinkedDocuments = false;
    }
  }

  function toggleDocumentsDropdown(payoffForm: PayoffForm) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      payoffForms[index].documentDropdownOpen =
        !payoffForms[index].documentDropdownOpen;
    }
  }

  //////////////////////////////////////////////////////////////
  // TABLE ROW ADDITIONAL INFORMATION ACTIONS
  //////////////////////////////////////////////////////////////
  function getAttentionTo(payoffForm: PayoffForm) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const originalPayoff = getOriginalPayoff(payoffForm);
      const originalForm = payoffFormStore.payoffToPayoffForm(originalPayoff);

      if (originalForm.attentionTo && originalForm.attentionToId) {
        payoffForm.attentionTo = originalForm.attentionTo;
        payoffForm.attentionToId = originalForm.attentionToId;
        replacePayoffForm(payoffForm);
      } else {
        return;
      }
    } else {
      return;
    }
  }

  function getOriginalPayoffForm(payoffForm: PayoffForm) {
    if (payoffForm.refiLoanId && payoffForm.refiLoanId > 0) {
      const originalPayoff = getOriginalPayoff(payoffForm);
      const originalForm = payoffFormStore.payoffToPayoffForm(originalPayoff);
      return originalForm;
    } else {
      return newForm.value;
    }
  }

  function setCurrentDocument(
    payoffForm: PayoffForm,
    document: PayoffDocumentViewModel
  ) {
    const index = getPayoffFormIndex(payoffForm);

    if (index > -1) {
      if (!payoffForm.excludedLoan) {
        includedPayoffForms.value[index].currentDocumentId =
          document.applicantDocumentId;
      } else {
        excludedPayoffForms.value[index].currentDocumentId =
          document.applicantDocumentId;
      }
    }
  }

  function getDocumentNameByApplicantDocumentId(applicantDocumentId: number) {
    const document = availableDocuments.value?.find(
      (availableDoc: PayoffDocumentViewModel) =>
        availableDoc.applicantDocumentId === applicantDocumentId
    );
    return document ? document.documentName : "";
  }

  function getDocumentName(
    payoffForm: PayoffForm,
    applicantDocumentId: number
  ) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      payoffForms[index]?.linkedDocuments.find(
        (ld: any) => ld.applicantDocumentId === applicantDocumentId
      )?.documentName;
    }
  }

  // #region Document Dropdown Functions

  function removeDocumentLinkRequest(
    payoffForm: PayoffForm,
    linkDocumentRequest: LinkUnlinkDocumentToRefiLoanRequest
  ) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      removeObjFromArr(
        payoffForms[index].linkRequests,
        "applicantDocumentId",
        linkDocumentRequest.applicantDocumentId
      );
    }
  }

  function removeDocumentUnLinkRequest(
    payoffForm: PayoffForm,
    unlinkDocumentRequest: LinkUnlinkDocumentToRefiLoanRequest
  ) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      removeObjFromArr(
        payoffForms[index].unlinkRequests,
        "applicantDocumentId",
        unlinkDocumentRequest.applicantDocumentId
      );
    }
  }

  async function linkDocument(
    payoffForm: PayoffForm,
    linkDocumentRequest: LinkUnlinkDocumentToRefiLoanRequest
  ) {
    const response = await $api.payoffs.linkDocumentToRefiLoan(
      linkDocumentRequest
    );

    if (response.success) {
      removeDocumentLinkRequest(payoffForm, linkDocumentRequest);
    }

    return response;
  }

  async function unlinkDocument(
    payoffForm: PayoffForm,
    unlinkDocumentRequest: LinkUnlinkDocumentToRefiLoanRequest
  ) {
    const response = await $api.payoffs.unlinkDocumentFromRefiLoan(
      unlinkDocumentRequest
    );

    if (response.success) {
      removeDocumentUnLinkRequest(payoffForm, unlinkDocumentRequest);
    }

    return response;
  }

  async function handleLinkRequests(
    payoffForm: PayoffForm,
    requests: LinkUnlinkDocumentToRefiLoanRequest[]
  ) {
    try {
      if (requests.length > 0) {
        const linkDocumentPromises = requests.map(
          (request: LinkUnlinkDocumentToRefiLoanRequest) => {
            return request
              ? linkDocument(payoffForm, request)
              : Promise.resolve([]);
          }
        );

        const response = await Promise.all(linkDocumentPromises);
        //#TODO update linkedDocuments array on payoff form
        //#TODO update availableDocuments array on payoff form
      }
    } catch (error) {
      console.error("Error: handleLinkRequests()", error);
    }
  }

  async function handleUnlinkRequests(
    payoffForm: PayoffForm,
    requests: LinkUnlinkDocumentToRefiLoanRequest[]
  ) {
    try {
      if (requests.length > 0) {
        const linkDocumentPromises = requests.map(
          (request: LinkUnlinkDocumentToRefiLoanRequest) => {
            return request
              ? unlinkDocument(payoffForm, request)
              : Promise.resolve([]);
          }
        );

        const response = await Promise.all(linkDocumentPromises);

        //#TODO update linkedDocuments array on payoff form
        //#TODO update availableDocuments array on payoff form
      }
    } catch (error) {
      console.error("Error: handleLinkRequests()", error);
    }
  }
  // #endregion

  // #region AttentionTo Functions
  async function addAttentionTo(payoff: PayoffForm, attentionToInput: string) {
    addingAttentionTo.value = true;
    const response = await $api.payoffs.addAttentionTo({
      name: attentionToInput,
    });

    if (response && response?.results?.length > 0) {
      attentionTos.value = response.results;
      closeAttentionToForm(payoff);
    }
    addingAttentionTo.value = false;
  }

  function openAttentionToForm(payoffForm: PayoffForm) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      payoffForms[index].addAttentionTo = true;
    }
  }

  function closeAttentionToForm(payoffForm: PayoffForm) {
    const index = getPayoffFormIndex(payoffForm);
    const payoffForms = getPayoffForms(payoffForm);

    if (index > -1) {
      payoffForms[index].addAttentionTo = false;
    }
  }

  // #endregion

  function resetPayoffTabStore() {
    //#TODO
    gettingPayoffDetails.value = true;
    subServicers.value = [];
    attentionTos.value = [];
    newPayoffForms.value = [];
    includedPayoffForms.value = [];
    excludedPayoffForms.value = [];
    addingOrUpdatingPayoff.value = false;
    addingAttentionTo.value = false;
    payoffTabData.value = {} as ApplicationPayoffsResponse;
    payoffFormSchemas.value = {};
    newPayoffFormSchemas.value = {};
    // Close modals
    functions.closeModal();
  }

  return {
    includedLoans,
    excludedLoans,
    addingOrUpdatingPayoff,
    payoffTabData,
    gettingPayoffDetails,
    getApplicationPayoffs,
    includedPayoffForms,
    excludedPayoffForms,
    newPayoffForms,
    subServicers,
    attentionTos,
    toggleAdditionalDetails,
    discardChanges,
    closeLinkedDocumentsModal,
    toggleLinkedDocumentsModal,
    toggleDocumentsDropdown,
    toggleLinkedDocumentsEditing,
    setCurrentDocument,
    detachDocument,
    attachDocument,
    getDocumentName,
    openAttentionToForm,
    closeAttentionToForm,
    addAttentionTo,
    addingAttentionTo,
    addNewPayoffForm,
    removeNewPayoffForm,
    payoffsVerified,
    totalConsolidatedLoanAmountDisplay,
    resetPayoffTabStore,
    getPayoffForm,
    confirmCancel,
    saveChanges,
    getAttentionTo,
    getOriginalPayoffForm,
    replacePayoffForm,
    payoffTableHeaders,
    handleInvalidFormError,
    verifyPayoffs,
    getPayoffFormSchema,
    getNewPayoffFormSchema,
    getDocumentNameByApplicantDocumentId,
    payoffFormSchemas,
    updateIncludedPayoffForm,
    addAdditionalLoanButtonDisabled,
    toggleSaveChangesClicked,
    newForm,
    toggleIncludePayoff,
    toggleFinalizePayoff,
    toggleCancelChangesClicked,
  };
});
