import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "xpage-content-page",
  id: "xcredit-tab"
}
const _hoisted_2 = { class: "xtitlearea" }
const _hoisted_3 = {
  key: 0,
  class: "xwarningbox"
}

import { ref, onMounted, onUnmounted, computed } from "vue";
import ApplicationComments from "@/components/v2/shared/ApplicationComments.vue";
import CreditResultsSLR from "@/components/v2/credit/refi-loan/CreditResultsSLR.vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import { useCreditStore } from "@/store/store/creditStore";
import Loader from "@/components/Loader.vue";
import { CreditHistory } from "@/types/credit";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditTabSLR',
  setup(__props) {

const route = useRoute();
const creditStore = useCreditStore();
const isDataLoaded = ref(false);
const hasApplicantCreditReport = computed(() => {
  return (
    Object.values(creditStore.creditTab.applicants).some(
      (c) => c.hasApplicantCreditReport
    ) ?? false
  );
});

onMounted(async () => {
  try {
    const referenceId = route.params.referenceId.toString();

    creditStore.setReferenceId(referenceId);

    await creditStore.getCreditReportByReference(
      referenceId,
      {} as CreditHistory,
      false
    );

    await creditStore.Credit.knockOuts;

    const applicant = creditStore
      .ApplicantOptions(false)
      .find((a) => a.isSelected);

    creditStore.selectedApplicantId = applicant?.id || 0;
    creditStore.previousSelectedApplicantId = applicant?.id || 0;

    isDataLoaded.value = true;
  } catch (error) {
    console.log(
      `CreditTab.vue Error: onMounted() could not be initialized`,
      error
    );
  }
});

onUnmounted(() => {
  creditStore.$reset();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!isDataLoaded.value)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Credit", -1)),
            _createVNode(ApplicationComments)
          ]),
          _createVNode(PermissionsWrapper, {
            permissionIds: [_unref(Permissions).Credit],
            permissionLevel: _unref(PermissionLevels).Read
          }, {
            default: _withCtx(() => [
              (!hasApplicantCreditReport.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, " There is no credit report for this applicant. "))
                : (_openBlock(), _createBlock(CreditResultsSLR, { key: 1 }))
            ]),
            _: 1
          }, 8, ["permissionIds", "permissionLevel"])
        ]))
  ]))
}
}

})