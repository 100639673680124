import { computed, Ref } from 'vue';
import * as yup from 'yup';
import { ServiceType } from '@/models/loans';

export interface ValidationData {
	disbursementAmount: number;
	payoffDisbursementTypeOptions: Ref<ServiceType[]>;
}

export function usePostDisbursementChangeFormFields(data: ValidationData) {
	const changeFormFields = computed(() => {
		return [
			{
				name: 'adjustedAmount',
				label: 'Adjusted Amount',
				type: 'number',
				value: 0,
				width: 'col-4',
				tooltip: 'Amount to be subracted from the Disbursement Amount'
			},
			{
				name: 'adjustedFeeAmount',
				label: 'Adjusted Fee Amount',
				type: 'number',
				value: 0,
				width: 'col-4',
				tooltip: 'Fees to be subracted from the Fees Amount'
			},
			{
				name: 'payoffDisbursementTypeId',
				label: 'Method',
				type: 'select',
				value: 1,
				width: 'col-4',
				options: data.payoffDisbursementTypeOptions.value,
				tooltip: 'Method that Student Choice will receive the refund'
			},
			{
				name: 'refundProcessingDate',
				label: 'Processing Date',
				type: 'date',
				value: '',
				width: 'col-4',
				tooltip: 'Date the refund is received by Student Choice'
			},
			{
				name: 'refundSettleDate',
				label: 'Settle Date',
				type: 'date',
				value: '',
				width: 'col-4',
				tooltip: 'Estimated date the funds should settle at the lender'
			},
			{
				name: 'refundEffectiveDate',
				label: 'Effective Date',
				type: 'date',
				value: '',
				width: 'col-4',
				tooltip: 'Date the refund should be effective at the servicer'
			}
		];
	});

	const changeValidationSchema = computed(() => {
		return yup.object({
			adjustedAmount: yup
				.number()
				.typeError('Adjusted Amount is invalid')
				.required('Adjusted Amount is required')
				.min(1, 'Adjustment Amount must be greater than $0')
				.test('has-disbursed-amount', 'Disbursement Amount is required to enter Adjusted Amount', function () {
					return data.disbursementAmount !== undefined;
				})
				.test('is-under-limit', `Adjusted Amount cannot exceed $${data.disbursementAmount}`, function (value) {
					const limit = data.disbursementAmount;
					return limit ? value <= limit : false;
				}),
			adjustedFeeAmount: yup
				.number()
				.typeError('Adjusted Fee Amount is invalid')
				.min(0, 'Adjusted Fee Amount must be greater than or equal to $0'),
			refundProcessingDate: yup
				.date()
				.typeError('Processing Date is invalid')
				.required('Processing Date is required'),
			refundSettleDate: yup.date().typeError('Settle Date is invalid').required('Settle Date is required'),
			refundEffectiveDate: yup
				.date()
				.typeError('Effective Date is invalid')
				.required('Effective Date is required'),
			payoffDisbursementTypeId: yup.number().typeError('Method is invalid').required('Method is required').min(1)
		});
	});

	return {
		changeFormFields,
		changeValidationSchema
	};
}
