<template>
  <loader v-if="application.loading" />
  <page v-else>
    <ApplicationBreadcrumb
      :application="application"
    />
    <ApplicationDetails
      :application="application"
      :applicants="applicants"
      :lenderName="appDetails.lenderName"
      :moveToLoanService="moveToLoanService"
      :store="store"
      :getApplication="getApplication"
      :animate="{ ...$animate, duration: 100 }"
      :localTimezone="localTimezone"
      :toggleApplicationDetails="toggleApplicationDetails"
      @toggleApplicationDetailsMobile="toggle('application.mobileHidden')"
    />
    <ApplicantDetails
      :store="store"
      :canEdit="application.canEdit"
      :application="application"
      :applicants="applicants"
      :details="appDetails"
      :localTimezone="localTimezone"
      :toggleApplicationDetails="toggleApplicationDetails"
    />
    <application-tabs-group
      :application="application"
      :openTab="openTab"
      :isTabActive="isTabActive"
    />
    <div
      class="xpage-content"
      v-auto-animate="{ ...$animate, duration: 100 }"
      v-if="application.data"
    >
      <application-tab
        :isTabActive="isTabActive('application')"
        :application="application"
        :applicants="applicants"
        :applicant="applicants[applicants.selected]"
        :applicantType="applicants[applicants.selected].applicantTypeName"
        :authorizedThirdParty="applicants['authorizedThirdParty']"
        :backup="applicants['backup']"
        :primaryApplicant="applicants['primary']"
        :secondaryApplicant="applicants['secondary'] || {}"
        :commentsId="[{ application: application.data.id }]"
        :disabled="application.tabForms.application.formDisabled"
        :notificationsData="application.notifications.application"
        :showForms="
          (applicants.selected == 'primary' ||
            applicants.selected == 'secondary') &&
          applicants[applicants.selected]
        "
        :store="store"
        :canEdit="application.canEdit"
        @nameBtnClicked="selectApplicant($event)"
        @applicantUpdated="applicantUpdated($event)"
        @authorizedThirdPartyUpdated="authorizedThirdPartyUpdated($event)"
        @addAuthorizedThirdParty="addAuthorizedThirdParty"
        @getApplication="getApplication(route.params.referenceId)"
        @getAuthorizedThirdParty="getAuthorizedThirdParty"
        @disableForm="toggle('application.tabForms.application.formDisabled')"
        @discardChanges="
          applicants[applicants.selected] = JSON.parse(JSON.stringify($event))
        "
        @editBtnClicked="
          toggle('application.tabForms.application.formDisabled');
          applicants['backup'] = JSON.parse(
            JSON.stringify(applicants[applicants.selected])
          );
        "
        @schoolConfigurationUpdated="schoolConfigurationUpdated($event)"
        :comments="application.commentData"
      />
      <membership-tab
        :isTabActive="isTabActive('membership')"
        :application="application"
        :notificationsData="application.notifications.membership"
        :commentsId="[{ application: application.data.id }]"
        :commentsComponentKey="commentComponent"
        :membershipTypes="store.state.types['Membership'].results"
        :disabled="application.tabForms.membership.formDisabled"
        :applicants="applicants"
        :canEdit="application.canEdit"
        @toggle="toggle('application.tabForms.membership.formDisabled')"
        @submitForm="saveMembership($event)"
      />
      <income-tab
        :isTabActive="isTabActive('income')"
        :applicants="applicants"
        :canEdit="application.canEdit"
        :primaryApplicant="applicants['primary']"
        :secondaryApplicant="applicants['secondary']"
        :application="application"
        :commentsId="[{ application: application.data.id }]"
        :applicant="applicants[applicants.selected]"
        :store="store"
        :showDocuments="true"
        @getApplication="getApplication(route.params.referenceId)"
        @addBtnClicked="addIncome"
        @getApplicantIncomes="
          getApplicantIncomes(applicants[applicants.selected].id)
        "
        @applicantIncomeUpdated="applicantIncomeUpdated"
        @selectApplicant="selectApplicant($event)"
        @nameBtnClicked="selectApplicant($event)"
        @getComments="getApplicationComments"
        @programRequirementDecisionUpdated="
          programRequirementDecisionStatusUpdated($event)
        "
        @programRequirementDecisionAdded="
          programRequirementDecisionAdded($event)
        "
        @checkIncomeTab="checkIncomeTab($event)"
      />
      <identity-tab
        :isTabActive="isTabActive('identity')"
        :canEdit="application.canEdit"
        :application="application"
        :notificationsData="application.notifications.identity"
        :commentsId="[{ application: application.data.id }]"
        :commentsComponentKey="commentComponent"
        :applicants="applicants"
        :isLoading="isLoading"
        :getLexisNexisReport="getLexisNexisReport"
        :cvi="cvi"
        :nap="nap"
        :nas="nas"
        :riskIndicatorCode="riskIndicatorCode"
        :descriptions="descriptions"
        :riskIndicatorDescription="riskIndicatorDescription"
        :riskIndicatorDescriptions="riskIndicatorDescriptions"
        @getTags="getApplicationTags"
      />
      <evaluations-tab
        :isTabActive="isTabActive('evaluations')"
        :commentsId="[{ application: application.data.id }]"
        :commentsComponentKey="commentComponent"
        :applicants="applicants"
        :application="application"
        :store="store"
        :disabled="application.tabForms.evaluations.formDisabled"
        :canEdit="application.canEdit"
        :credits="credits"
        :creditScore="creditScore"
        :creditKnockouts="creditKnockouts"
        :creditKnockoutsFactors="creditKnockoutsFactors"
        :getCreditsByApplication="getCreditsByApplication"
        :income="applicants[applicants.selected].incomes?.[0]"
      />
      <e-sign-tab
        :isTabActive="isTabActive('esign')"
        :canEdit="application.canEdit"
        :commentsId="[{ application: application.data.id }]"
        :commentsComponentKey="commentComponent"
        :notificationsData="application.notifications.esign"
        :applicants="applicants"
        :application="application"
        :store="store"
        @setEsignData="setEsignData($event)"
      />
      <pricing-tab
        :isTabActive="isTabActive('pricing')"
        :commentsId="[{ application: application.data.id }]"
        :commentsComponentKey="commentComponent"
        :disabled="application.tabForms.pricing.formDisabled"
        :applicants="applicants"
        :application="application"
        :canEdit="application.canEdit"
        :store="store"
        :lenderProgramName="appDetails.lenderName"
        @toggle="toggle('application.tabForms.pricing.formDisabled')"
        @getLoanConfig="getLoanConfig()"
      />
      <overrides-tab
        :isTabActive="isTabActive('overrides')"
        :canEdit="application.canEdit"
        :commentsId="[{ application: application.data.id }]"
        :commentsComponentKey="commentComponent"
        :applicants="applicants"
        :application="application"
      />
      <stipulations-tab
        :isTabActive="isTabActive('stipulations')"
        :commentsId="[{ application: application.data.id }]"
        :commentsComponentKey="commentComponent"
        :applicants="applicants"
        :application="application"
        :primaryApplicant="applicants['primary']"
        :secondaryApplicant="applicants['secondary']"
        :store="store"
        @getApplicationDocs="getDocumentsByApplication"
        @getApplicantDocs="getDocumentsByApplicant"
        @getApplicationStips="getApplicationStipulations"
        @stipsUpdated="stipsUpdated($event)"
        @stipUpdated="stipUpdated($event)"
        @getApplicationComments="getApplicationComments"
      />
      <final-review-tab
        :isTabActive="isTabActive('final-review')"
        :application="application"
        :commentsId="[{ application: application.data.id }]"
        :commentData="application.commentData"
        :canEdit="application.canEdit"
      />

      <CreditTabSLR v-if="isTabActive('credit')" />
  
      <documents-tab
        :isTabActive="isTabActive('documents')"
        :application="application"
        :applicants="applicants"
        :canEdit="application.canEdit"
        :generateLegalPacket="generateLegalPacket"
        :commentsId="[{ application: application.data.id }]"
        :commentData="application.commentData"
        @toggle="toggle('application.documentsUploadDisplay')"
        @getApplicationComments="getApplicationComments"
        @getPdfDocument="getPdfDocument($event)"
        :store="store"
        :getDocumentsByApplication="getDocumentsByApplication"
        :getDocumentsByApplicant="getDocumentsByApplicant"
        :initializeSelectedTypes="initializeSelectedTypes"
        :commentsComponentKey="commentComponent"
      />
    </div>
    <div v-else>
      <div class="xpage-breadcrumbs">
        <router-link to="/dashboard" class="icon-home"></router-link>
        <span>></span>
        <router-link to="/applications">Applications</router-link>
      </div>
      <div class="xwarningbox" style="margin: 30px">
        The Application ID {{ route.params.referenceId }} failed to load or does
        not exist
      </div>
    </div>
  </page>
</template>

<script setup>
import {
  reactive,
  onMounted,
  watch,
  onUnmounted,
  ref,
  provide,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import page from "@/components/layout/Page.vue";
import loader from "@/components/Loader.vue";
import axios from "axios";
import functions from "@/use/functions";
import { $api } from "@/services/api1.ts";
import useFunctions from "@/use/functions1";
import useIncomeTab from "@/use/incomeTab";
import ApplicationBreadcrumb from "@/components/application/ApplicationBreadcrumb.vue";
import ApplicationDetails from "@/components/application/ApplicationDetails.vue";
import ApplicantDetails from "@/components/application/ApplicationApplicantDetails.vue";
import MembershipTab from "@/components/membership/MembershipTab.vue";
import IdentityTab from "@/components/identity/IdentityTab.vue";
import IncomeTab from "@/components/income/IncomeTab.vue";
import DocumentsTab from "@/components/documents/DocumentsTab.vue";
import FinalReviewTab from "@/components/final-review/FinalReviewTab.vue";
import CreditTabSLR from "@/components/v2/credit/CreditTabSLR.vue";
import EvaluationsTab from "@/components/evaluations/EvaluationsTab.vue";
import OverridesTab from "@/components/overrides/OverridesTab.vue";
import StipulationsTab from "@/components/stipulations/StipulationsTab.vue";
import ESignTab from "@/components/esign/ESignTab.vue";
import PricingTab from "@/components/pricing/PricingTab.vue";
import useStipulations from "@/use/stipulations";
import ApplicationTab from "@/components/application/ApplicationTab.vue";
import ApplicationTabsGroup from "@/components/application/ApplicationTabsGroup.vue";
import { ApplicationDetail } from "@/types/application";
import { useProgramRequirementsStore } from "@/store/store/programRequirementsStore.ts";
import { usePdfViewerModalStore } from "@/store/store/pdfViewerModalStore";
import { useDocumentsTabStore } from "@/store/store/documentsTabStore.ts";
import { useApplicationStore } from "@/store/store/applicationStore.ts";
import { useApplicantsStore } from "@/store/store/applicantsStore.ts";
import { useEvaluationsTabStore } from "@/store/store/evaluationsStore";

const pdfViewerModalStore = usePdfViewerModalStore();
const programRequirementsStore = useProgramRequirementsStore();
const documentsTabStore = useDocumentsTabStore();
const applicationStore = useApplicationStore();
const applicantsStore = useApplicantsStore();

const {
  decodeHTMLEncodedStr,
  getDocServiceType,
  getStipulationTypes,
  replaceObjectWithId,
  getAllSchoolLevels,
  getGraduateDegreePrograms,
} = useFunctions();
const { initializeIncomeTab } = useIncomeTab();
const isLoading = ref(false);

const showLoader = ref(false);
provide("showLoader", showLoader);

const lexisNexisResults = ref(null);
const generateLegalPacket = ref(null);
const membershipNotificationCount = ref(1);
const cvi = ref({ id: "", description: "" });
const nap = ref({ id: "", description: "" });
const nas = ref({ id: "", description: "" });
const descriptions = ref(null);
const riskIndicatorDescription = ref([]);
const riskIndicatorDescriptions = ref([]);
const riskIndicatorCode = ref([]);
const credits = ref([]);
const creditScore = ref(null);
const creditKnockouts = ref([]);
const creditKnockoutsFactors = ref([]);
const initializeSelectedTypes = ref(null);
const verifyIncomeTab = ref(false);

//////////////
// vue data //
//////////////

var route = useRoute();
var router = useRouter();
var application = reactive({
  applicants: [],
  commentData: [],
  creditData: false,
  creditReport: {},
  creditId: 0,
  currentTab: "Application",
  data: false,
  debtData: {},
  documentsData: [],
  documents: false,
  documentsUploadDisplay: false,
  formDisabled: true,
  formSaved: false,
  identitySelected: false,
  identityDocuments: false,
  incomeData: [
    { showDocument: false },
    { showDocument: false },
    { showDocument: false },
  ],
  loanConfigData: false,
  loading: true,
  mobileHidden: true,
  newComment: "",
  notifications: {
    application: [],
    income: [],
    identity: [],
    esign: [],
    membership: [],
  },
  programLimits: [],
  programRequirements: [],
  programResults: [],
  programTypeId: 0,
  programData: [],
  ratePlanData: [],
  focusComments: false,
  showComments: false,
  showDetails: false,
  showOwnerDropdown: false,
  statusDropdown: false,
  statusComment: "",
  statusSelected: false,
  stipulationsData: [],
  tabs: {
    application: "incomplete",
    income: "incomplete",
    identity: "incomplete",
    esign: "incomplete",
    membership: "incomplete",
  },
  canEdit: false,
  autocompleteSchoolName: {
    id: 0,
    name: "",
  },
  tags: [],
  tabForms: {
    application: {
      formDisabled: true,
      formSaved: false,
    },
    credit: {
      formDisabled: true,
      formSaved: false,
    },
    evaluations: {
      formDisabled: true,
      formSaved: false,
    },
    membership: {
      formDisabled: true,
      formSaved: false,
    },
    pricing: {
      formDisabled: true,
      formSaved: false,
    },
  },
});

var appDetails = reactive < ApplicationDetail > { lenderName: "" };

var applicants = reactive({
  primary: false,
  secondary: false,
  backup: {},
  authorizedThirdParty: {
    id: 0,
    sourceTypeId: 1,
    address: {
      applicantId: 0,
      id: 0,
      addressTypeId: 1,
      address1: null,
      address2: null,
      city: null,
      stateId: null,
      postalCode: undefined,
      countryId: null,
    },
    authorizedByApplicantId: null,
    firstName: null,
    lastName: null,
    emailAddress: null,
    phoneNumber: undefined,
    phoneNumberTypeId: null,
    dateOfBirth: null,
  },
  selected: false,
});

// Adds in support for updating Application tab comment section without needing to reload page
const commentComponent = ref(0);
localStorage.setItem("cache.commentComponent", commentComponent.value);

const forceRerender = (component) => {
  component.value += 1;
  localStorage.setItem(`cache.commentComponent`, commentComponent.value);
};

const schoolSearchSchema = {
  name: "school",
  type: "autocomplete",
  autocompleteProperty: "name",
  autoCompleteResultsErrorMessage: "No matches found",
  hideLabel: false,
  label: "School",
  placeholder: "Search...",
  required: false,
};

/////////////////
// vue methods //
/////////////////
async function getApplicantIncomes(applicantId) {
  const incomes = await $api.applicants.getIncomes(applicantId);
  let applicant = applicants[applicants.selected];
  if (incomes && incomes.length > 0) {
    // list of income types, and the value to multiply by to find the annual amount
    const incomeTypes = {
      Annual: 1,
      Monthly: 12,
      "Bi-Weekly": 26,
      Weekly: 52,
      Bonus: 1,
      "Semi-Monthly": 24,
      Quarterly: 4,
    };

    let statedIncome = 0;
    // for each of the incomes
    incomes.forEach((income) => {
      // add helper props
      income.isEditing = false;
      // use incomeDate to assign a year
      if (income.incomeDate) {
        const dt = new Date(income.incomeDate);
        income.year = dt.getFullYear();
      }
      // calculate the total income based on the income type provided
      income.incomeTotal =
        income.incomeAmount * incomeTypes[income.incomePeriodTypeName];

      // calculate total stated
      if (income.isVerified === false) {
        statedIncome += income.incomeTotal;
      }
    });

    applicant.incomes = incomes;
    applicant.statedIncome = statedIncome;

    let verifiedIncome = await $api.applicants.getApplicantCalculatedIncome(
      applicant.id
    );
    applicant.totalVerifiedIncome = verifiedIncome.currentIncomeAmount || 0;
    applicant.totalVerifiedPriorIncomeAmount =
      verifiedIncome.priorIncomeAmount || 0;
    applicant.totalVerifiedPriorPriorIncomeAmount =
      verifiedIncome.priorPriorIncomeAmount || 0;
  }
}

async function getApplicationsDetails() {

  const applicationId = application.data.id;

  const url = `/api/applications/${applicationId}/details`;
  try {
    const response = await axios.get(url);
    // console.log(response.data);
    appDetails = response.data;
  } catch (error) {
    console.log(
      "ApplicationApplicantDetails.vue applicationsDetails() Error retrieving details:",
      error
    );
  }
}

async function getApplication(referenceId) {
  ///////////////////////////////////////////////////////////////////////////////////
  // description: fetches the application by reference id and formats the response //
  ///////////////////////////////////////////////////////////////////////////////////
  await axios(`/api/applications/ref/${referenceId}`, { require: "json" })
    .then((response) => {
      // set the application data to the response data for application
      application.data = response.data.application;

      // if the lenders api has loaded
      if (store.state.types["Lenders"]) {
        // set the lender name
        application.data.lenderName =
          store.state.types["Lenders"].find(
            (lender) => lender.id == application.data.lenderId
          )?.name || "";
      }

      response.data.applicants.forEach(async (applicant) => {
        // if the applicant has incomes
        if (applicant.incomes) {
          // list of income types, and the value to multiply by to find the annual amount
          const incomeTypes = {
            Annual: 1,
            Monthly: 12,
            "Bi-Weekly": 26,
            Weekly: 52,
            Bonus: 1,
            "Semi-Monthly": 24,
            Quarterly: 4,
          };
          let statedIncome = 0;

          // for each of the incomes
          applicant.incomes.forEach(async (income) => {
            // use incomeDate to assign a year
            if (income.incomeDate) {
              const dt = new Date(income.incomeDate);
              income.year = dt.getFullYear();
            }
            // edit flag for table
            income.isEditing = false;

            // calculate the total income based on the income type provided
            income.incomeTotal =
              income.incomeAmount * incomeTypes[income.incomePeriodTypeName];

            // calculate total stated
            if (income.isVerified === false) {
              statedIncome += income.incomeTotal;
            }
          });

          applicant.statedIncome = statedIncome;
        } else {
          applicant.incomes = [];
        }
        // if the applicant does not have the following data models, create them as blank objects/arrays so they can be used in vue v-model
        applicant.contactInfo = applicant.contactInfo || {};
        applicant.membership = applicant.membership || {};
        applicant.addresses = applicant.addresses || [{}];

        if (applicant.addresses) {
          const address = applicant.addresses[0];
          address.address1 = decodeHTMLEncodedStr(address.address1);
          address.city = decodeHTMLEncodedStr(address.city);
          address.address2 = decodeHTMLEncodedStr(address.address2);
        }
        applicant.dateOfBirth = applicant.dateOfBirth?.split("T")?.[0] || "";

        applicant.firstName = decodeHTMLEncodedStr(applicant.firstName);
        applicant.middleName = decodeHTMLEncodedStr(applicant.middleName);
        applicant.lastName = decodeHTMLEncodedStr(applicant.lastName);

        // create a full name for the applicant, use "no name" if both first/last names are blank
        applicant.fullName = functions.capitalize(
          `${decodeHTMLEncodedStr(applicant.firstName) || ""} ${
            decodeHTMLEncodedStr(applicant.lastName) || ""
          }`.trim() || "No Name"
        );

        // create initials for the applicant from the first character of each names
        applicant.initials = `${applicant.firstName?.charAt(0) || ""}${
          applicant.lastName?.charAt(0) || ""
        }`.trim();

        // create a phone number display for the applicant
        applicant.contactInfo.phoneDisplay = functions.phoneFormat(
          applicant.contactInfo?.phoneNumber || ""
        );

        // if the applicantTypeId is "1" then the applicant is "primary", otherwise the applicant is "secondary"
        var applicantType =
          applicant.applicantTypeId == 1 ? "primary" : "secondary";

        // set the applicant data based on the applicantType
        applicants[applicantType] = applicant;

        if (applicants[applicantType].userId) {
          const username = await $api.applicants.getUsername({
            userId: applicants[applicantType].userId,
          });
          if (username && username != []) {
            applicants[applicantType].username = username;
          }
        }
        let verifiedIncome = await $api.applicants.getApplicantCalculatedIncome(
          applicant.id
        );
        applicant.totalVerifiedIncome = verifiedIncome.currentIncomeAmount || 0;
        applicant.totalVerifiedPriorIncomeAmount =
          verifiedIncome.priorIncomeAmount || 0;
        applicant.totalVerifiedPriorPriorIncomeAmount =
          verifiedIncome.priorPriorIncomeAmount || 0;
      });

      // set applicants for easy access
      application.applicants = response.data.applicants;
      // set the applicant.selected to primary if there is a primary applicant, otherwise set to secondary
      applicants.selected = applicants.primary ? "primary" : "secondary";

      // if a application schoolConfiguration is missing or is null
      if (
        !application.data.schoolConfiguration ||
        application.data.schoolConfiguration == null
      ) {
        // add it to the application data object
        application.data.schoolConfiguration = {};
      } else {
        application.autocompleteSchoolName.name =
          application.data.schoolConfiguration.schoolName;
      }

      // not submitted, eval queue, submitted & processing
      const editableStatusIds = [1, 2, 3];

      application.canEdit = editableStatusIds.includes(
        application.data.applicationStatusId
      );

      getApplicationComments();
      getApplicationTags();
      // open tab that is currently set or default to application
      let currentTab =
        localStorage.getItem("cache.currentTab") || "application";
      openTab(currentTab);
    })
    .catch((error) => {
      functions.openModal({
        title: "Error",
        description: "There was an issue loading the application",
      });

      console.log(`Error: getApplication()`, error);
    });

  applicationStore.application = application;
  forceRerender(commentComponent);
}

async function getApplicationComments() {
  const commentsResponse = await $api.applications.getApplicationComments(
    application.data.id
  );
  application.commentData = commentsResponse;
}

async function getApplicationTags() {
  const tags = await $api.applications.getApplicationTags(application.data.id);

  if (tags) {
    application.tags = tags;
  }
}

async function getLoanConfig() {
  await axios(`/api/applications/${application.data.id}/loanconfigs`, {
    require: "json",
  })
    .then((response) => {
      application.loanConfigData = response.data;
    })
    .catch((error) => {
      console.log(`Error: getLoanConfig()`, error);
    });

    await getApplicationTags();
}

async function getInitialDraw() {
  await axios(`/api/applications/${application.data.id}/initialdraw`, {
    require: "json",
  })
    .then((response) => {
      application.initialDrawData = response.data;
    })
    .catch((error) => {
      console.log(`Error: getInitialDraw()`, error);
    });
}

/////////////////
//// Credit /////
/////////////////
function setLiabilities(event) {
  // console.log("setLiabilities", event);
  if (application.creditReport[applicants.selected]) {
    if (
      event?.applicantLiabilities &&
      event?.applicantLiabilities?.length > 0
    ) {
      application.creditReport[applicants.selected].applicantLiabilities =
        event?.applicantLiabilities;
    }

    if (
      event?.creditReportLiabilities &&
      event?.creditReportLiabilities?.length > 0
    ) {
      application.creditReport[applicants.selected].creditReportLiabilities =
        event?.creditReportLiabilities;
    }
  }
}

async function getApplicationCredits() {
  const response = await $api.applications.getApplicationCredits(
    application.data.id
  );
  if (Array.isArray(response) && response.length > 0) {
    application.creditData = response;
    console.log("creditData response", response);

    application.creditData?.[0]?.applicantCredits?.forEach((credit) => {
      if (applicants["primary"]?.id == credit.applicantId) {
        getCreditReportDataByCreditReportId(credit.creditId, "primary");
      } else if (applicants["secondary"]?.id == credit.applicantId) {
        getCreditReportDataByCreditReportId(credit.creditId, "secondary");
      }
    });
  }
}

async function getCreditReportDataByCreditReportId(creditReportId, person) {
  let decisionTypeId = null;

  const creditReportResponse = await $api.credits.getRawCreditReport(
    creditReportId
  );
  const creditScoreResponse = await $api.credits.getCreditScore(creditReportId);
  const creditKnockoutsResponse = await $api.credits.getCreditKnockouts(
    creditReportId
  );
  const creditKnockoutsFactors = await $api.credits.getCreditKnockoutsFactors(
    creditReportId
  );
  const creditReportLiabilities =
    await $api.applicants.getLiabilitiesByCreditId(creditReportId);

  const allCreditTab = {
    ...creditReportResponse,
    creditScore: creditScoreResponse,
    creditKnockouts: creditKnockoutsResponse,
    creditKnockoutsFactors: creditKnockoutsFactors,
    applicantLiabilities: [],
    creditReportLiabilities: creditReportLiabilities,
  };

  application.creditReport = {
    ...application.creditReport,
    [person]: allCreditTab,
  };
  decisionTypeId =
    application.creditReport[person]?.creditReport?.decisionTypeId;
  if (decisionTypeId) {
    application[`${person}DecisionTypeId`] = decisionTypeId;
  }

  return {
    decisionTypeId,
    creditScore,
    creditKnockouts,
    creditKnockoutsFactors,
    creditReportLiabilities,
  };
}

async function getCreditReport(creditReportId, person) {
  let decisionTypeId = null;
  try {
    const creditResponse = await axios(`/api/credits/${creditReportId}`);
    const creditScoreResponse = await axios(
      `/api/credits/creditscore/${creditReportId}`
    );
    const creditKnockoutsResponse = await axios(
      `/api/credits/knockouts/${creditReportId}`
    );
    const creditKnockoutsFactors = await axios(
      `/api/credits/factors/${creditReportId}`
    );

    const allCreditTab = {
      ...creditResponse.data,
      creditScore: creditScoreResponse.data,
      creditKnockouts: creditKnockoutsResponse.data,
      creditKnockoutsFactors: creditKnockoutsFactors.data,
      applicantLiabilities: [],
      creditReportLiabilities: [],
    };

    application.creditReport = {
      ...application.creditReport,
      [person]: allCreditTab,
    };
    decisionTypeId =
      application.creditReport[person]?.creditReport?.decisionTypeId;

    if (decisionTypeId) {
      application[`${person}DecisionTypeId`] = decisionTypeId;
    }
  } catch (error) {
    console.log(
      `Error: getCreditReport(): Error for ID ${creditReportId}`,
      error
    );
  }
  return {
    decisionTypeId,
    creditScore,
    creditKnockouts,
    creditKnockoutsFactors,
  };
}

async function getCreditsByApplication() {
  try {
    const response = await axios(
      `/api/applications/${application.data.id}/credits`,
      {
        require: "json",
      }
    );

    if (Array.isArray(response.data) && response.data.length > 0) {
      application.creditData = response.data;
    }
  } catch (error) {
    console.log(`Error: getCreditsByApplication()`, error);
  }
}

async function getDebtByCreditFromLiabilities(
  applicantId,
  creditReportId,
  person
) {
  const response = await $api.applicants.getDebtByCreditFromLiabilities(
    applicantId,
    creditReportId
  );
  if (response?.data?.applicantId) {
    // set the debtData with the response
    application.debtData[person] = response.data;
  } else {
    console.log(`Error: getDebtByCreditFromLiabilities()`, response);
  }
}

async function getDebtByCredit(creditId, person) {
  await axios(`/api/credits/${creditId}/debt`, { require: "json" })
    .then((response) => {
      // set the debtData with the response
      application.debtData[person] = response.data;
    })
    .catch((error) => {
      console.log(`Error: getDebtByCredit()`, error);
    });
}

async function getPdfDocument(event) {
  try {
    pdfViewerModalStore.openPdfModal({
      title: "View PDF Document",
      description: event?.name,
      docId: event?.document,
      fullscreen: true,
      documents: application.documents,
      application: application,
      splitting: event?.splitting || false,
    });
  } catch (error) {
    handlePdfDocumentError(error);

    pdfViewerModalStore.openPdfModal({
      title: "Error",
      description: "There was an error getting the requested document",
    });
  }
}

async function getProgramLimits() {
  const response = await $api.applications.getProgramLimits(
    application?.data?.id
  );
  if (response && response.length > 0) {
    application.programLimits = response;
  }
}

async function getApplicationProgramRequirements() {
  const response = await $api.applications.getApplicationProgramRequirements(
    application?.data?.id
  );

  if (Array.isArray(response)) {
    application.programRequirements = response;
    programRequirementsStore.programRequirementsDecisions =
      application.programRequirements;
  }
}

async function getApplicationProgramResult() {
  const response = await $api.applications.getApplicationProgramResult(
    application?.data?.id
  );
  // console.log("Application-Single.vue getApplicationProgramResult() ", response);
  if (response && response.length > 0) {
    application.programResults = response;
  }
}

async function programRequirementDecisionStatusUpdated(event) {
  replaceObjectWithId(application.programRequirements, event?.id, event);
  checkTabs();
  await getApplicationTags();
}

async function programRequirementDecisionAdded(event) {
  if (event.length > application.programRequirements) {
    application.programRequirements = event;
    await getApplicationTags();
    checkTabs();
  }
}

async function getProgramLimit(typeId) {
  await axios(
    `/api/applications/${application.data.id}/programlimits/${typeId}`,
    { require: "json" }
  )
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(`Error: getProgramLimit()`, error);
    });
}

/////////////////
// Identity //
/////////////////
const getLexisNexisReport = async (person) => {
  try {
    const response = await axios({
      url: `/api/id/ExecuteIdVerification`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: store.state.oidcStore.user.preferred_username,
        applicantId: applicants[person].id,
        applicationId: application.data.id,
      },
    });
    lexisNexisResults.value = response.data;
    await getIdentityByApplicant(person);
  } catch (error) {
    console.log(error);
  }
};

async function getIdentityByApplicant(person) {
  try {
    const response = await axios.get(
      `/api/applicants/${applicants[person].id}/identityverifications`
    );

    if (!Array.isArray(response.data) || response.data.length == 0) {
      return;
    }

    applicants[person].identityData = response.data;

    // use only the first (newest) returned (ordered by desc on lastChangedAt)
    const identity = applicants[person].identityData[0];

    // don't try to get results if error; allow repull
    if (
      !identity.identityVerificationStatusId ||
      identity.identityVerificationStatusId === 4
    ) {
      return;
    }

    const identificationResult = await getIdentificationResults(
      identity.identityVerificationId
    );

    identity.identificationResult = identificationResult;
    identity.identificationResultHtml =
      functions.objectToHtmlTable(identificationResult);

    const { cviScore, napScore, nasScore, instantIdRiskIndicatorCodes } =
      identificationResult.instantId;

    const verification = {
      firstName: { title: "First Name", verified: false, errors: [] },
      lastName: { title: "Last Name", verified: false, errors: [] },
      address: { title: "Address", verified: false, errors: [] },
      phone: { title: "Phone", verified: false, errors: [] },
      ssn: { title: "SSN", verified: false, errors: [] },
      dob: { title: "Date of Birth", verified: false, errors: [] },
    };

    applicants[person].cvi = {
      id: cviScore.id,
      description: cviScore.description,
    };

    applicants[person].nap = {
      id: napScore.id,
      description: napScore.description,
    };

    applicants[person].nas = {
      id: nasScore.id,
      description: nasScore.description,
    };

    applicants[person].instantIdRiskIndicatorCodes = [];

    if (instantIdRiskIndicatorCodes) {
      const descriptions = [];
      const riskIndicators = [];

      instantIdRiskIndicatorCodes.forEach((item) => {
        const id = item.riskIndicatorCode?.id;
        const description = item.riskIndicatorCode?.description;

        if (id && description) {
          descriptions.push(description);
          riskIndicators.push({ id, description });
        }
      });

      riskIndicatorDescriptions.value = descriptions;

      applicants[person].riskIndicatorDescription = descriptions;
      applicants[person].instantIdRiskIndicatorCodes = riskIndicators;
    }

    applicants[person].verification = verification;
  } catch (error) {
    console.log(`Error: getIdentityByApplicant()`, error);
  }
}

async function getIdentificationResults(id) {
  try {
    const response = await axios.get(`/api/id/${id}`);
    return response.data;
  } catch (error) {
    console.log(`Error: getIdentificationResults()`, error);
  }
}

async function getDocumentsByApplication() {
  try {
    const response = await axios.get(
      `/api/documents/application/${application.data.id}`,
      {}
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error: getDocumentsByApplication()`, error);
  }
}

async function getDocumentsByApplicant() {
  try {
    application.documents = [];

    const getDocuments = async (id) => {
      const response = await axios.get(`/api/documents/applicant/${id}`, {});
      return response.data;
    };

    const documentPromises = ["primary", "secondary"].map((person) => {
      return applicants[person]
        ? getDocuments(applicants[person].id)
        : Promise.resolve([]);
    });

    const allDocuments = await Promise.all(documentPromises);
    application.documents = allDocuments.flat();
    documentsTabStore.documents = application.documents;
    // console.log("Application-Single.vue getDocumentsByApplicant()", allDocuments);
  } catch (error) {
    throw new Error(`Error: getDocumentsByApplicant()`, error);
  }
}

async function getApplicationRatePlans() {
  try {
    const response = await axios.get(
      `/api/applications/${application.data?.id}/rateplans`
    );

    if (response.data.length > 0) {
      application.ratePlanData = response.data;
    }
  } catch (error) {
    console.error(`Failed to get application rate plans`, error);
  }
}

/////////////////////
//// Income Tab ////
////////////////////
async function getDtiByApplicant(applicantId, person) {
  await axios(`/api/applicants/${applicantId}/dtis`, { require: "json" })
    .then(async (response) => {
      // if the response is an array and contains at least 1 item
      if (Array.isArray(response.data) && response.data.length > 0) {
        applicants[person].dti = response.data;

        // for each of the dti's
        applicants[person].dti.forEach((dti) => {
          // add the percentage
          dti.dtiPercentage = (Number(dti.calculatedDTI) * 100).toFixed(0);
        });

        await getApplicationProgramRequirements();
      }

      // console.log(`getDtiByApplicant() ${person}`, applicants[person]);
    })
    .catch((error) => {
      console.log(`Error: getDtiByApplicant()`, error);
    });
}

///////////////////////
// Stipulations Tab //
//////////////////////
const { updateStipulationObj } = useStipulations();
function stipsUpdated(updatedStipsData) {
  application.stipulationsData = updatedStipsData;
}

function stipUpdated(updatedStip) {
  updateStipulationObj(
    application.stipulationsData,
    updatedStip.id,
    updatedStip
  );
}

async function getApplicationStipulations() {
  const response = await $api.applications.getApplicationStipulations(
    application.data.id
  );
  if (response.count && response.count > 0) {
    application.stipulationsData = response.results;
  }
}

async function updateStipulationsData(updatedStip) {
  console.log("Application-Single.vue updateStipulationsData()", updatedStip);
  updateStipulationObj(
    application.stipulationsData,
    updatedStip.id,
    updatedStip
  );
}

/////////////////////
// Application Tab //
////////////////////
async function applicantUpdated(event) {
  console.log("applicantUpdated", event);
  replaceObjectWithId(application.applicants, event.id, event);
  applicants[applicants.selected] = event;
}

async function schoolConfigurationUpdated(event) {
  application.data.schoolConfiguration = event;
}

async function authorizedThirdPartyUpdated(event) {
  console.log("authorizedThirdPartyUpdated", event);
  applicants["authorizedThirdParty"] = event;
}
function addAuthorizedThirdParty() {
  applicants.selected = "authorizedThirdParty";
  application.tabForms.application.formDisabled = false;
  applicants["backup"] = JSON.parse(
    JSON.stringify(applicants[applicants.selected])
  );
}

function selectApplicant(applicantType) {
  applicants.selected = applicantType;
  toggleSelected(applicants.selected);
  application.tabForms.application.formDisabled = true;
  applicants[applicants.selected].firstName = decodeHTMLEncodedStr(
    applicants[applicants.selected].firstName
  );
  applicants[applicants.selected].middleName = decodeHTMLEncodedStr(
    applicants[applicants.selected].middleName
  );
  applicants[applicants.selected].lastName = decodeHTMLEncodedStr(
    applicants[applicants.selected].lastName
  );

  // temporarily needed to restore 'review your changes' modal
  applicants["backup"] = JSON.parse(
    JSON.stringify(applicants[applicants.selected])
  );
}

async function getAuthorizedThirdParty() {
  await axios(`/api/applications/${application.data.id}/authorizedusers`, {
    require: "json",
  })
    .then((response) => {
      // if the response is an array and contains at least 1 comment
      if (Array.isArray(response.data) && response.data.length > 0) {
        // set the application authorized third party
        applicants.authorizedThirdParty = response.data[0];

        // format the date of birth for the input field
        applicants.authorizedThirdParty.dateOfBirth =
          applicants.authorizedThirdParty.dateOfBirth?.split("T")?.[0] || "";

        applicants.authorizedThirdParty.initials =
          initial(applicants.authorizedThirdParty.firstName) +
          initial(applicants.authorizedThirdParty.lastName);
        applicants.authorizedThirdParty.fullName = `${decodeHTMLEncodedStr(
          applicants.authorizedThirdParty.firstName
        )} ${decodeHTMLEncodedStr(applicants.authorizedThirdParty.lastName)}`;
        applicants.authorizedThirdParty.applicantTypeName =
          "Authorized Third Party";
      } else {
        applicants.authorizedThirdParty.addedByUserId =
          store.state.oidcStore.user.sub;
      }
    })
    .catch((error) => {
      console.log(`Error: getAuthorizedThirdParty()`, error);
    });
}

async function resolveIssuesPost() {
  // this request will return true if the response is successful, false if unsucessful
  return await axios({
    url: `/api/applicants/update`,
    method: "POST",
    require: "json",
    data: {
      applicationId: 14,
      ssn: 555555555,
      contactInfo: {
        applicantId: 16,
        phoneNumber: "5558885057",
        phoneNumberTypeId: 1,
        phoneNumberTypeName: "Home",
      },
      id: 16,
    },
  })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the Applicant Info",
      });

      console.log(`Error: updateApplicantInfo()`, error);

      return false;
    });
}

async function moveToLoanService(confirmation) {
  if (confirmation) {
    functions.openModal({
      title: "Confirm",
      description:
        "Are you sure you want to move this application to Loan Service?",
      buttons: [
        { title: "Cancel", onClick: () => functions.closeModal() },
        { title: "Submit", onClick: () => moveToLoanService() },
      ],
    });
  } else {
    functions.closeModal();

    await axios({
      url: `/api/applications/${application.data.id}/migrate`,
      method: "POST",
      require: "json",
      data: {
        AuthorizedByUserId: store.state.oidcStore.user.sub,
        MigrateApplicationTargets: application.data.id,
      },
    })
      .then((response) => {
        // success
        functions.openModal({
          title: "Success",
          description:
            "Application has been successfully moved to Loan Service",
          buttons: [
            {
              title: "Close",
              onClick: () => {
                functions.closeModal();

                // refresh the page
                router.go(0);
              },
            },
          ],
        });
      })
      .catch((error) => {
        console.log(`Error: moveToLoanService()`, error);

        var modal = {
          title: "Error",
          description:
            "There was an issue moving this application to Loan Service",
        };

        if (error.response?.data) {
          // optionally override error if message is present
          if (error.response.data.message) {
            modal.description = error.response.data.message;
          }

          // build unordered list of request results errors
          if (
            error.response.data.errors !== undefined &&
            error.response.data.errors.length > 0
          ) {
            var errorList = "<ul>";

            // add each error to list
            for (var i = 0; i < error.response.data.errors.length; i++) {
              errorList += "<li>" + error.response.data.errors[i] + "</li>";
            }

            errorList += "</ul>";

            modal.html = errorList;
          }
        }

        functions.openModal(modal);
      });
  }
}

// Application Tab

async function updateApplicationSchoolConfiguration() {
  const route = `/api/applications/${application.data.id}/school`;

  try {
    // get input value
    application.data.schoolConfiguration.schoolName =
      application.autocompleteSchoolName.name;

    return await axios.patch(route, application.data.schoolConfiguration);
  } catch (error) {
    console.log(`API Patch Error: ${route}`, error);
    return false;
  }
}

/////////////////////
//// Esign Tab /////
////////////////////
function setEsignData(event) {
  application.esignData = event;
}
// INCOME TAB

async function checkIncomeTab(event) {
  application.tabs.income = event;

  verifyIncomeTab.value = false;

  await getApplicationTags();
}
function addIncome(incomeDocument) {
  /////////////////////////////////////////////////////
  // description: adds a new income row to the table //
  /////////////////////////////////////////////////////

  const newIncome = {
    isEditing: true,
    id: 0,
    incomeTotal: 0,
    applicantDocumentId: incomeDocument.documentId,
    applicantEmploymentId: null,
    applicantEmploymentTypeId: null,
    applicantEmploymentName: null,
    applicantId: applicants[applicants.selected].id,
    employmentEndDate: null,
    employmentStartDate: null,
    incomeAmount: undefined,
    incomeDate: null,
    incomePeriodTypeId: undefined,
    incomeStatusTypeId: undefined,
    incomeTypeId: undefined,
    incomeYearTypeId: null,
    isVerified: true,
  };
  applicants[applicants.selected].incomes.push(newIncome);
}

// MEMBERSHIP TAB FUNCTIONS
async function saveMembership(event) {
  application.tabForms.membership.formDisabled = true;
  const primary = event?.primaryApplicantMembership?.applicantId
    ? await updateApplicantMembership("primary", event?.primaryApplicantMembership)
    : null;
  const secondary = event?.secondaryApplicantMembership?.applicantId
    ? await updateApplicantMembership(
        "secondary",
        event?.secondaryApplicantMembership
      )
    : null;
    if (primary || secondary) {
      await getApplicationProgramRequirements();
      await getApplicationTags();
      checkMembershipTab();
      functions.openModal({
        title: "Success!",
        description: "Applicant Membership updated successfully",
      });
  }
}


async function updateApplicantMembership(applicantType, event) {
  let membershipRequest = {
    applicantId: event.applicantId,
    memberId: event.memberId,
    membershipStatusId: event.membershipStatusId,
    isJointOnStudentAccount: event.isJointOnStudentAccount,
    updatedByUserId: store.state.oidcStore.user.sub,
  };
  const res = await $api.applicants.updateMembership(membershipRequest);
  if (!res.applicantId) {
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the Applicant Membership",
    });
  } else {
    applicants[applicantType].membership.isMember = res.isMember;
    applicants[applicantType].membership.memberId = res.memberId;
    applicants[applicantType].membership.membershipStatusId =
      res.membershipStatusId;
    applicants[applicantType].membership.membershipStatusName =
      res.membershipStatusName;
    applicants[applicantType].membership.isJointOnStudentAccount =
      res.isJointOnStudentAccount;
    applicants[applicantType].membership.statusChangedAtUtc =
      res.statusChangedAtUtc;

    await getApplicationProgramRequirements();
    checkMembershipTab();
    return true;
  }
}

function checkMembershipTab() {
  const membershipRequirement = application.programRequirements?.find(
    (r) => r.requirementTypeName === "Membership"
  );
  if (membershipRequirement) {
    const isComplete =
      membershipRequirement.requirementStatusName === "Passed" ||
      membershipRequirement.requirementStatusName === "Passed - CU Override";
    application.tabs.membership = isComplete ? "complete" : "incomplete";
  } else {
    application.tabs.membership = "incomplete";
  }
  }

function handleMembershipStatusNotifications(person) {
  let verified =
    applicants[person].membership.membershipStatusName?.includes("Verified");
  let stated =
    applicants[person].membership.membershipStatusName?.includes("Stated");
  // check if membership is stated
  if (stated && !verified) {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Membership Status for the ${applicants[person].applicantTypeName} must be updated`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Incomplete",
        title: `The Membership Status for the ${applicants[person].applicantTypeName} must be updated`,
        text: `The ${applicants[person].applicantTypeName}'s Membership Status must be changed from 'Stated' to 'Verified'`,
      });
    }
  }

  // check if verified
  if (!stated && verified) {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Membership Status for the ${applicants[person].applicantTypeName} must be updated`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Complete",
        title: `The Membership Status for the ${applicants[person].applicantTypeName} must be updated`,
        text: `The ${applicants[person].applicantTypeName}'s Membership Status must be changed from 'Stated' to 'Verified'`,
      });
    } else {
      if (find) {
        find.status = "Complete";
      }
    }
  }
}

function handleMembershipVerificationNotifications(person) {
  // if the secondary applicant set to 'not required'
  if (
    person === "secondary" &&
    applicants["secondary"].membership.membershipStatusId === 4
  ) {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Verification for the ${applicants[person].applicantTypeName} has not been completed`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Complete",
        title: `The Verification for the ${applicants[person].applicantTypeName} has not been completed`,
        text: `The ${applicants[person].applicantTypeName} Verification must be set to 'Verified'`,
      });
    } else {
      if (find) {
        find.status = "Complete";
      }
    }
  }
  // if the applicant's membership is not verified
  if (!applicants[person].membership.isMember) {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Verification for the ${applicants[person].applicantTypeName} has not been completed`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Incomplete",
        title: `The Verification for the ${applicants[person].applicantTypeName} has not been completed`,
        text: `The ${applicants[person].applicantTypeName} Verification must be set to 'Verified'`,
      });
    }
  } else {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Verification for the ${applicants[person].applicantTypeName} has not been completed`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Complete",
        title: `The Verification for the ${applicants[person].applicantTypeName} has not been completed`,
        text: `The ${applicants[person].applicantTypeName} Verification must be set to 'Verified'`,
      });
    } else {
      if (find) {
        find.status = "Complete";
      }
    }
  }
}

function handleMemberIdNotifications(person) {
  // if the secondary applicant set to 'not required'
  if (
    person === "secondary" &&
    applicants["secondary"].membership.membershipStatusId === 4
  ) {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Member ID for the ${applicants[person].applicantTypeName} must be updated`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Complete",
        title: `The Member ID for the ${applicants[person].applicantTypeName} must be updated`,
        text: `The ${applicants[person].applicantTypeName}'s Member ID must be changed`,
      });
    } else {
      if (find) {
        find.status = "Complete";
      }
    }
  }

  // if applicant's memberid is null
  if (
    applicants[person].membership.memberId === null ||
    applicants[person].membership.memberId === ""
  ) {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Member ID for the ${applicants[person].applicantTypeName} must be updated`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Incomplete",
        title: `The Member ID for the ${applicants[person].applicantTypeName} must be updated`,
        text: `The ${applicants[person].applicantTypeName}'s Member ID must be changed`,
      });
    }
  } else {
    const find = application.notifications.membership.find(
      (m) =>
        m.title ===
        `The Member ID for the ${applicants[person].applicantTypeName} must be updated`
    );
    if (!find) {
      // add the record to a notification
      application.notifications.membership.push({
        id: membershipNotificationCount.value++,
        status: "Complete",
        title: `The Member ID for the ${applicants[person].applicantTypeName} must be updated`,
        text: `The ${applicants[person].applicantTypeName}'s Member ID must be changed`,
      });
    } else {
      if (find) {
        find.status = "Complete";
      }
    }
  }
}

function checkApplicantMembership(person) {
  handleMembershipVerificationNotifications(person);
  handleMembershipStatusNotifications(person);
  handleMemberIdNotifications(person);

  return application.notifications.membership;
}

function checkTabs() {
  ///////////////////////////
  // check application tab //
  ///////////////////////////

  // if the application has not been submitted
  if (application.data.submittedAtUtc === null) {
    // add the record to a notification
    application.notifications.application.push({
      id: 1,
      status: "Incomplete",
      title: `An application must be submitted by the ${applicants["primary"].applicantTypeName}`,
      text: `The ${applicants["primary"].applicantTypeName} application has not been submitted`,
    });
  } else {
    // set the application tab to complete
    application.tabs.application = "complete";
  }

  //////////////////////
  // check income tab //
  //////////////////////

  var incomeId = 1;

  // verify the income tab
  ["primary", "secondary"].forEach((person) => {
    // if this applicant exists
    if (applicants[person]) {
      // if the applicant has incomes
      if (applicants[person].incomes) {
        // filter to get all the incomes that are unverified
        var unverifiedIncomes = applicants[person].incomes.filter(
          (income) => income.isVerified == false
        );

        // if there are more than 0 unverified incomes
        if (unverifiedIncomes.length > 0) {
          // add the record to a notification
          application.notifications.income.push({
            id: incomeId++,
            status: "Incomplete",
            title: `${applicants[person].applicantTypeName} Income Verification`,
            text: `The ${applicants[person].applicantTypeName} income has not been verified`,
          });
        }
      }
    }
  });

  // Handle income tab handled by the initializeIncomeTab()

  //////////////////////////
  // check membership tab //
  //////////////////////////
  checkMembershipTab();
}

async function applicantIncomeUpdated() {
  await getCreditsByApplication();
  // for each of the credits
  if (application.creditData?.[0]?.applicantCredits) {
    for (const credit of application.creditData[0].applicantCredits) {
      if (applicants["primary"]?.id == credit.applicantId) {
        await getDebtByCreditFromLiabilities(
          credit.applicantId,
          credit.creditId,
          "primary"
        );
        await getDtiByApplicant(credit.applicantId, "primary");
      } else if (applicants["secondary"]?.id == credit.applicantId) {
        getDebtByCreditFromLiabilities(
          credit.applicantId,
          credit.creditId,
          "secondary"
        );
        await getDtiByApplicant(credit.applicantId, "secondary");
      }
    }
  }
}

async function applicantLiabilityUpdated() {
  await getCreditsByApplication();
  await getApplicantIncomes(applicants[applicants.selected].id);
  // for each of the credits
  if (application.creditData?.[0]?.applicantCredits) {
    for (const credit of application.creditData[0].applicantCredits) {  
      if (applicants["primary"]?.id == credit.applicantId) {
        await getDebtByCreditFromLiabilities(
          credit.applicantId,
          credit.creditId,
          "primary"
        );
        await getDtiByApplicant(credit.applicantId, "primary");
      } else if (applicants["secondary"]?.id == credit.applicantId) {
        await getDebtByCreditFromLiabilities(
          credit.applicantId,
          credit.creditId,
          "secondary"
        );
        await getDtiByApplicant(credit.applicantId, "secondary");
      }
    }
  }
}
async function openTab(tab) {
  // if you are opening the identity tab
  if (tab === "application") {
    //
  } else if (tab === "income") {
    await getCreditsByApplication();
    await getApplicantIncomes(applicants[applicants.selected].id);
    // for each of the credits
    if (application.creditData?.[0]?.applicantCredits) {
      for (const credit of application.creditData[0].applicantCredits) {    
        if (applicants["primary"]?.id == credit.applicantId) {
          // getDebtByCredit(credit.creditId, "primary");
          await getDebtByCreditFromLiabilities(
            credit.applicantId,
            credit.creditId,
            "primary"
          );
          await getDtiByApplicant(credit.applicantId, "primary");
        } else if (applicants["secondary"]?.id == credit.applicantId) {
          // getDebtByCredit(credit.creditId, "secondary");
          getDebtByCreditFromLiabilities(
            credit.applicantId,
            credit.creditId,
            "secondary"
          );
          await getDtiByApplicant(credit.applicantId, "secondary");
        }
      }
    }
  } else if (tab === "identity") {
    await getIdentityByApplicant("primary");

    if (applicants["secondary"]) {
      await getIdentityByApplicant("secondary");
    }
  } else if (tab === "pricing") {
    await getApplicationRatePlans();
  } else if (tab === "credit") {
    await getCreditsByApplication();
    await getApplicantIncomes(applicants[applicants.selected].id);
    // for each of the credits
    if (application.creditData?.[0]?.applicantCredits) {
      for (const credit of application.creditData[0].applicantCredits) {    
        if (applicants["primary"]?.id == credit.applicantId) {
          // getDebtByCredit(credit.creditId, "primary");
          await getDebtByCreditFromLiabilities(
            credit.applicantId,
            credit.creditId,
            "primary"
          );
          await getDtiByApplicant(credit.applicantId, "primary");
        } else if (applicants["secondary"]?.id == credit.applicantId) {
          // getDebtByCredit(credit.creditId, "secondary");
          getDebtByCreditFromLiabilities(
            credit.applicantId,
            credit.creditId,
            "secondary"
          );
          await getDtiByApplicant(credit.applicantId, "secondary");
        }
      }
    }
    // await getCreditsByApplication();
    // // for each of the credits
    // application.creditData?.[0]?.applicantCredits?.forEach((credit) => {
    // 	if (applicants["primary"]?.id == credit.applicantId) {
    // 		getCreditReport(credit.creditId, "primary");
    // 	} else if (applicants["secondary"]?.id == credit.applicantId) {
    // 		getCreditReport(credit.creditId, "secondary");
    // 	}
    // });
  }

  // set the current tab to open the tab
  application.currentTab = tab;
  localStorage.setItem("cache.currentTab", application.currentTab);
}

// function getCurrentTab() {
// 	let currentTab = localStorage.getItem("cache.currentTab") || "application";
// 	application.currentTab = currentTab;
// }

function initial(name) {
  if (typeof name !== "undefined" && name !== null) {
    return name.charAt(0);
  } else {
    return "";
  }
}

function isTabActive(tab) {
  return application.currentTab === tab;
}

function toggle(key) {
  // toggles the input variable true/false
  eval(`${key}=!${key}`);
}

function toggleApplicationDetails() {
  toggle("application.showDetails");

  if (localStorage.getItem("cache.applicationDetailsDisplay") == "false") {
    // set the data
    localStorage.setItem("cache.applicationDetailsDisplay", true);
  } else {
    // set the data
    localStorage.setItem("cache.applicationDetailsDisplay", false);
  }
}

function toggleSelected(selected) {
  // set the data
  if (!localStorage.getItem("cache.selected")) {
    // default to primary
    localStorage.setItem("cache.selected", "primary");
  } else {
    localStorage.setItem("cache.selected", selected);
  }
}

// getPdfDocument() - Extended error handling; not vital to the application
function handlePdfDocumentError(error) {
  if (error.response) {
    console.log(`Error: Response Data:`, error.response.data);
    console.log(`Error: Response Status`, error.response.status);
    console.log(`Error: Response Headers`, error.response.headers);
  } else if (error.request) {
    console.log(`Error: Request`, error.request);
  } else {
    console.log(`Axios Error`, error.message);
  }
  console.log(`Error: getPdfDocument()`, error);
}

//////////////////////////////////////////////////////////////
// vue watch - executes each time the url parameters change //
//////////////////////////////////////////////////////////////

watch(
  () => route.params.referenceId,
  () => {
    // refresh the page
    router.go(0);
  }
);

// update pinia stores
watch(
  () => application,
  (newVal) => {
    applicationStore.application = newVal;
  },
  { deep: true, immediate: true }
);
watch(
  () => applicants,
  (newVal) => {
    applicantsStore.applicants = newVal;
  },
  { deep: true, immediate: true }
);

/////////////////////////////////////////////////////////////////
// vue onMounted - executes when the page is finished mounting //
/////////////////////////////////////////////////////////////////

onMounted(async () => {
  application.loading = true;

  await Promise.all([
    functions.getType("Addresses"),
    functions.getType("ApplicationStatus"),
    functions.getType("Countries"),
    functions.getType("Citizenship"),
    functions.getType("DocumentStatus"),
    functions.getType("Employments"),
    functions.getType("Income"),
    functions.getType("IncomePeriods"),
    functions.getType("IncomeStatus"),
    functions.getType("IncomeYears"),
    functions.getType("Rates"),
    functions.getType("ProgramRequirements"),
    functions.getType("Products"),
    functions.getType("PhoneNumbers"),
    functions.getType("Membership"),
    functions.getType("Stipulations"),
    functions.getType("States"),
    functions.getType("Requirements"),
  ]);

  setTimeout(() => {
    functions.getType("JudgmentDenials");
    functions.getType("DocumentStatusReasons");
    functions.getType("ProcessingCategories");
    functions.getType("RequirementStatuses");
    functions.getType("StipulationStatuses");
    functions.getType("StipulationStatusReasons");
  }, 1000);

  getDocServiceType("DocumentType");
  getStipulationTypes();
  await useEvaluationsTabStore();

  await getAllSchoolLevels();
  await functions.getSchoolMajors();
  await getGraduateDegreePrograms();

  await functions.getLenders();
  await getApplication(route.params.referenceId);

  functions.getType("SchoolLevels", application.data.programTypeId);

  // set the browser document title
  document.title = `${
    decodeHTMLEncodedStr(applicants["primary"].lastName) || ""
  },
		${decodeHTMLEncodedStr(applicants["primary"].firstName?.charAt(0)) || ""}.
		(${application.data.referenceId?.trim() || ""}) - Loan Origination System`;

  await functions.getSchoolsByProgram(
    application?.data?.programTypeId,
    application?.data?.productTypeId,
    application?.data?.lenderId
  );
  await getApplicationsDetails();
  await getApplicationComments();
  await getApplicationProgramResult();
  await getApplicationProgramRequirements();
  await getDocumentsByApplicant();
  await getDocumentsByApplication();
  await getLoanConfig();
  await getInitialDraw();
  await getAuthorizedThirdParty();
  await getProgramLimits();
  await getApplicationStipulations();
  application.tabs.income = await initializeIncomeTab(application);
  checkTabs();

  application.loading = false;

  setTimeout(function () {
    // if the localstore has cache.applicationDetailsDisplay and its not set to false
    if (localStorage.getItem("cache.applicationDetailsDisplay") !== "false") {
      application.showDetails = true;
    }
  }, 5);

  functions.logPageView({
    id: application.data.referenceId,
    page: "applications",
    category: "Application",
    timestamp: Date.now(),
    firstName: decodeHTMLEncodedStr(applicants["primary"].firstName),
    lastName: decodeHTMLEncodedStr(applicants["primary"].lastName),
    name: decodeHTMLEncodedStr(applicants["primary"].fullName),
    lenderId: application.data.lenderId,
  });
});

onUnmounted(() => {
  localStorage.removeItem("cache.selected");
  localStorage.removeItem("cache.currentTab");
});
</script>
