export enum Type {
  Addresses = "Addresses",
  ApplicationStatus = "ApplicationStatus",
  ApplicationStatusReasons = "ApplicationStatusReasons",
  Countries = "Countries",
  Citizenship = "Citizenship",
  DocumentStatus = "DocumentStatus",
  DocumentStatusReasons = "DocumentStatusReasons",
  DocumentType = "DocumentType",
  Employments = "Employments",
  GraduateDegreePrograms = "GraduateDegreePrograms",
  Income = "Income",
  IncomePeriods = "IncomePeriods",
  IncomeStatus = "IncomeStatus",
  IncomeYears = "IncomeYears",
  JudgmentDenials = "JudgmentDenials",
  Majors = "Majors",
  Membership = "Membership",
  NationalIdNumberTypes = "NationalIdNumberTypes",
  PhoneNumbers = "PhoneNumbers",
  ProgramRequirements = "ProgramRequirements",
  Products = "Products",
  Rates = "Rates",
  RequirementStatuses = "RequirementStatuses",
  Stipulations = "Stipulations",
  StipulationStatuses = "StipulationStatuses",
  StipulationStatusReasons = "StipulationStatusReasons",
  StipulationTypes = "StipulationTypes",
  States = "States",
  Requirements = "Requirements",
}

export interface TypeDictionary {
  [index: string]: Array<LOSType>;
}

export interface LOSType {
  id: number;
  abbreviation?: string;
  name?: string;
  description?: string;
  programTypeId?: number;
  createdBy?: string;
  visible?: boolean;
  createdAtUtc?: Date;
  updatedBy?: string;
  updatedAtUtc?: Date;
  disabled: boolean;
  selected: boolean;
}

export enum DocumentEntityTypes {
  Application = 1,
  Loan = 2,
}

export interface BaseOption {
  label: string;
  value: number;
}

export interface StipContextOption {
  text: string;
  id: string;
  showItemOnClick: boolean;
}

export enum StipulationTypes {
  AdditionalIncome = 18,
  EstablishMembership = 21,
  UnfreezeCredit = 42,
  UnSuppressBureau = 43,
  ContactCBRCorrection = 44,
  ConfirmPayoffRequest = 62,
  LoanAccountStatement = 63,
  PayoffStatement = 65,
  ResponsibilityForUnderPayment = 68,
  ServicerPayoffAddress = 71,
}

export enum StipulationStatuses {
  Open = 1,
  Completed = 2,
  CUOverride = 3,
  Cancelled = 4,
  Pending = 5,
}

export enum StipulationStatusReasons {
  Open = 1,
  Fulfilled = 2,
  Override = 3,
  UnableToProvide = 4,
  Replaced = 5,
  ItemNotNeeded = 6,
}

export enum Products {
  LOC = 1,
  REFI = 2,
}

export class ProductIds {
  public static get LOC(): number {
    return Products.LOC;
  }
  public static get REFI(): number {
    return Products.REFI;
  }
}

export enum ApplicationStatusTypeNames {
  NOT_SUBMITTED = "Not Submitted",
  EVALUATION = "Evaluation",
  SUBMITTED_AND_PROCESSING = "Submitted & Processing",
  COMPLETE = "Complete",
  DECLINED = "Declined",
  CANCELLED = "Cancelled",
  SMR_DECLINED = "SMR Declined",
  RIGHT_TO_CANCEL = "Right To Cancel",
}

export enum DegreeLevels {
  HighSchoolDiploma = 1,
  AssociatesDegree = 2,
  BachelorsDegree = 3,
  MastersDegree = 4,
  DoctoralDegree = 5,
  MedicalDegree = 6,
}

export class DegreeLevelIds {
  public static get HighSchoolDiploma(): number {
    return DegreeLevels.HighSchoolDiploma;
  }
  public static get AssociatesDegree(): number {
    return DegreeLevels.AssociatesDegree;
  }
  public static get BachelorsDegree(): number {
    return DegreeLevels.BachelorsDegree;
  }
  public static get MastersDegree(): number {
    return DegreeLevels.MastersDegree;
  }
  public static get DoctoralDegree(): number {
    return DegreeLevels.DoctoralDegree;
  }
  public static get MedicalDegree(): number {
    return DegreeLevels.MedicalDegree;
  }
}

export enum ProgramTypes {
  Undergraduate = 1,
  DomesticGraduate = 2,
  InternationalGraduate = 3,
  Parent = 4,
  AdvancedDegree = 5,
  Medical = 6,
  Standard = 7,
  InternationalUndergraduate = 8,
  CareerEd = 9,
}

export enum RateTypes{
  Fixed = 1,
  Variable = 2
}

export class ProgramTypeIds {
  public static get Undergraduate(): number {
    return ProgramTypes.Undergraduate;
  }
  public static get DomesticGraduate(): number {
    return ProgramTypes.DomesticGraduate;
  }
  public static get InternationalGraduate(): number {
    return ProgramTypes.InternationalGraduate;
  }
  public static get Parent(): number {
    return ProgramTypes.Parent;
  }
  public static get AdvancedDegree(): number {
    return ProgramTypes.AdvancedDegree;
  }
  public static get Medical(): number {
    return ProgramTypes.Medical;
  }
  public static get Standard(): number {
    return ProgramTypes.Standard;
  }
  public static get InternationalUndergraduate(): number {
    return ProgramTypes.InternationalUndergraduate;
  }
  public static get CareerEd(): number {
    return ProgramTypes.CareerEd;
  }
}
