import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "xfields" }
const _hoisted_4 = { class: "xfield" }
const _hoisted_5 = { class: "xfield" }
const _hoisted_6 = { class: "xfield" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 0,
  class: "error-message"
}
const _hoisted_10 = { class: "textarea-analyst" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "remaining-textbox" }
const _hoisted_13 = { class: "xfield" }
const _hoisted_14 = { class: "button-container" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["disabled"]

import { AnalystReview, Recommendation } from "@/types/evaluation";
  import Loader from "@/components/Loader.vue";
  import functions from "@/use/functions";
  import axios from "axios";
  import { computed, onMounted, ref } from "vue";
  import { useStore } from "vuex";
  interface Props {
    application: any;
    recommendation?: AnalystReview[];
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'EvaluationsAnalystReview',
  props: {
    application: {},
    recommendation: {}
  },
  emits: ["submitForm"],
  setup(__props: any, { emit: __emit }) {

  const analystReview = ref<AnalystReview>({
    reviewBy: "",
    applicationExceptionId: 0,
    reviewDateTime: "",
    recommendationId: 0,
    comments: "",
    exceptions: [],
  });

  const isSubmitting = ref(false);

  const recommendations = ref<Array<Recommendation>>([]);

  const store = useStore();
  const props = __props;
  const emit = __emit;

  function reloadWindow() {
    window.location.reload();
  }

  const remainingCharacters = computed(
    () => 512 - (analystReview.value.comments?.length || 0)
  );

  async function submitForm() {
    const referenceId = props.application.data.referenceId;
    const url = `/api/applications/${referenceId}/Exception`;
    try {
      if (analystReview.value.applicationExceptionId == 0) {
        const response = await axios.post(url, analystReview.value);

        if (response.data.success) {
          analystReview.value = response.data.results;
          functions.openModal({
            title: "Analyst Review Recommendation",
            description: "Analyst Review Recommendation has been saved.",
            buttons: [
              {
                title: "OK",
                onClick: async () => {
                  await functions.closeModal();
                  reloadWindow();
                },
              },
            ],
          });
        }
      } else {
        const response = await axios.put(url, analystReview.value);

        if (response.data.success) {
          analystReview.value = response.data.results;
          reloadWindow();
        }
      }
    } catch (error) {
      console.log("Error saving details:", error);
    }
  }

  function resetForm() {
    analystReview.value = {
      reviewBy: "",
      applicationExceptionId: 0,
      reviewDateTime: "",
      recommendationId: 0,
      comments: "",
      exceptions: [],
    };
  }

  async function getRecommendations() {
    const url = "/api/types/Analyst/Review/Recomendations";
    try {
      const response = await axios.get(url);
      recommendations.value = response.data;
    } catch (error) {
      console.log("Error saving details:", error);
    }
  }

  async function getExistingException() {
    const referenceId = props.application.data.referenceId;
    const url = `/api/applications/${referenceId}/Analyst/Exception`;
    try {
      const response = await axios.get(url);

      if (response.data.success) {
        analystReview.value = response.data.results;

        analystReview.value.isDisabled =
          analystReview.value.recommendationId != 5;

        console.table(analystReview.value);
      }
    } catch (error) {
      console.log("Error saving details:", error);
    }
  }

  onMounted(() => {
    getRecommendations();
    getExistingException();
  });

return (_ctx: any,_cache: any) => {
  return (isSubmitting.value)
    ? (_openBlock(), _createBlock(Loader, { key: 0 }))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        onSubmit: _withModifiers(submitForm, ["prevent"]),
        autocomplete: "off",
        class: "card-container"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "card-header" }, [
            _createElementVNode("h3", null, "Analyst Review")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[4] || (_cache[4] = _createElementVNode("label", null, "Initiated By", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((analystReview.value.reviewBy) = $event)),
                  class: "input-field-textarea",
                  placeholder: "Initiated By",
                  disabled: ""
                }, null, 512), [
                  [_vModelText, analystReview.value.reviewBy]
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[5] || (_cache[5] = _createElementVNode("label", null, "Date and Time", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((analystReview.value.reviewDateTime) = $event)),
                  class: "input-field-textarea",
                  placeholder: "Date and Time",
                  disabled: ""
                }, null, 512), [
                  [_vModelText, analystReview.value.reviewDateTime]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[7] || (_cache[7] = _createElementVNode("label", null, [
                  _createTextVNode("Recommendation"),
                  _createElementVNode("i", null, "*")
                ], -1)),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((analystReview.value.recommendationId) = $event)),
                  class: "input-field",
                  required: "",
                  disabled: analystReview.value.isDisabled
                }, [
                  _cache[6] || (_cache[6] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Select a Recommendation", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recommendations.value, (recommendation, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: recommendation.id
                    }, _toDisplayString(recommendation.name), 9, _hoisted_8))
                  }), 128))
                ], 8, _hoisted_7), [
                  [_vModelSelect, analystReview.value.recommendationId]
                ]),
                (
                !analystReview.value.recommendationId ||
                analystReview.value.recommendationId === 0
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Please select a recommendation "))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[8] || (_cache[8] = _createElementVNode("label", { class: "title-writeup" }, "Analyst Recommendation", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((analystReview.value.comments) = $event)),
                disabled: analystReview.value.isDisabled,
                id: "message",
                maxlength: "2000",
                rows: "4",
                class: "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
                placeholder: "Write up your recommendation here..."
              }, null, 8, _hoisted_11), [
                [_vModelText, analystReview.value.comments]
              ]),
              _createElementVNode("div", _hoisted_12, _toDisplayString(remainingCharacters.value) + " characters remaining ", 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  class: "draw-button submit",
                  onClick: _withModifiers(resetForm, ["prevent"]),
                  disabled: analystReview.value.isDisabled
                }, " Cancel ", 8, _hoisted_15),
                _createElementVNode("button", {
                  class: "draw-button submit",
                  type: "submit",
                  disabled: analystReview.value.isDisabled
                }, " Save ", 8, _hoisted_16)
              ])
            ])
          ])
        ])
      ], 32))
}
}

})