import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "tabs-container scrollbar-container" }
const _hoisted_3 = ["onClick"]

import '@/assets/css/scrollbar.css';
	import { computed, onMounted, ref } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import axios from 'axios';
	import Page from '@/components/layout/Page.vue';
	import Loader from '@/components/Loader.vue';
	import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
	import Header from '@/components/loan/Header.vue';
	import DetailsTab from '@/components/loan/tabs/DetailsTab.vue';
	import DrawsTab from '@/components/loan/tabs/DrawsTab.vue';
	import RefiDisbursementsTab from '@/components/loan/tabs/RefiDisbursementsTab.vue';
	import AnnualReviewTab from '@/components/loan/tabs/AnnualReviewTab.vue';
	import DocumentsTab from '@/components/loan/tabs/DocumentsTab.vue';
	import CommentsTab from '@/components/loan/tabs/CommentsTab.vue';
	import { LoanDetails } from '@/models/loans';

	interface TabViewModel {
		name: string;
		identifier: string;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Loan',
  setup(__props) {

	const route = useRoute();
	const router = useRouter();

	const isLoading = ref<boolean>(true);
	const details = ref({} as LoanDetails);
	const referenceId = ref<string>();
	const loanId = ref<number>();
	const tabs = ref<TabViewModel[]>([]);
	const activeTab = ref<string>('details');

	const tabComponents = {
		details: DetailsTab,
		draws: DrawsTab,
		'refi-disbursements': RefiDisbursementsTab,
		'annual-review': AnnualReviewTab,
		documents: DocumentsTab,
		comments: CommentsTab
	};

	const activeTabComponent = computed(() => tabComponents[activeTab.value] || null);

	const activeTabProps = computed(() => {
		switch (activeTab.value) {
			case 'details':
				return { loanDetails: details.value };
			case 'draws':
				return {
					loanId: loanId.value,
					availableToDrawAmount: details.value?.availableToBorrow?.availableToDrawAmount,
					programTypeId: details.value?.loan?.programTypeId,
					productTypeId: details.value?.loan?.productType,
					lenderId: details.value?.loan?.lenderId,
					onDrawsChanged: getLoanAsync
				};
			case 'refi-disbursements':
				return {
					loanId: loanId.value,
					lenderId: details.value?.loan?.lenderId,
					onPayoffsChanged: getLoanAsync
				};
			case 'documents':
				return {
					loanId: loanId.value,
					borrowers: details.value?.borrowers
				};
			case 'annual-review':
			case 'comments':
				return {
					loanId: loanId.value
				};
			default:
				return {};
		}
	});

	function selectTab(tabIdentifier: string) {
		activeTab.value = tabIdentifier;

		router.replace({
			query: {
				...route.query,
				drawId: undefined,
				disbursementId: undefined,
				documentsSortKey: undefined,
				documentsSortOrder: undefined,
				payoffSortKey: undefined,
				payoffSortOrder: undefined
			},
			hash: `#${tabIdentifier}`
		});
	}

	async function getLoanAsync() {
		try {
			const response = await axios(`/api/loanservice/loans/details/ref/${referenceId.value}`);

			details.value = response.data;
			loanId.value = response.data.loan.id;
			tabs.value = response.data.tabs;
		} catch (error) {
			router.push('/page-not-found');
		}
	}

	onMounted(async () => {
		const id = route.params.referenceId;

		referenceId.value = String(id);

		await getLoanAsync();

		await new Promise((resolve) => setTimeout(resolve, 3000)); // :P

		const initialTab = window.location.hash.replace('#', '');

		if (initialTab && tabs.value.some((tab) => tab.identifier === initialTab)) {
			activeTab.value = initialTab;
		}

		isLoading.value = false;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createBlock(Loader, { key: 0 }))
      : (_openBlock(), _createBlock(Page, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(Breadcrumbs, { "root-path": "/loans" }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(Header, {
                "reference-id": referenceId.value,
                "loan-details": details.value
              }, null, 8, ["reference-id", "loan-details"]),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: tab.identifier,
                    class: _normalizeClass({ 'tab-button': true, active: activeTab.value === tab.identifier }),
                    onClick: ($event: any) => (selectTab(tab.identifier))
                  }, _toDisplayString(tab.name), 11, _hoisted_3))
                }), 128))
              ]),
              (_openBlock(), _createBlock(_resolveDynamicComponent(activeTabComponent.value), _mergeProps(activeTabProps.value, { class: "tab-content" }), null, 16))
            ])
          ]),
          _: 1
        }))
  ]))
}
}

})