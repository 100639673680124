import { defineStore } from "pinia";
import { ref, computed } from "vue";
import {
  PayoffDetailViewModel,
  PaymentMethodViewModel,
  SubServicerViewModel,
  UpdateRefiLoanRequest,
  PayoffForm,
  MailedChecksViewModel,
  PayableViewModel,
  FinancialInstitutionViewModel,
  RefiLoanRequest,
} from "@/types/payoff";
import { FieldSchema, FormSchema, Option } from "@/models/form";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import functions from "@/use/functions";
import { PayoffDisbursementTypeIds } from "@/types/payoff";
import { usePayoffTabStore } from "@/store/store/payoffTabStore";
import moment from "moment-timezone";

export const usePayoffFormStore = defineStore("payoffForm", () => {
  const applicationsStore = useApplicationStore();
  const payoffTabStore = usePayoffTabStore();

  const linkedDocumentsModalOpen = ref<boolean>(false);
  const subServicers = ref([] as SubServicerViewModel[]);
  const payoffForms = ref([] as PayoffForm[]);
  const payoffFormData = ref({} as PayoffForm);

  const applicationId = computed(() => applicationsStore.viewModel?.id);
  const attentionTos = computed(() => payoffTabStore.attentionTos);
  const currentPayoffForm = computed(() => payoffFormData.value);
  const currentFormFields = computed(() => defaultFields.value);
  const showCheckFields = computed(
    () =>
      selectedPayoffDisbursementTypeId.value === PayoffDisbursementTypeIds.CHECK
  );
  const showACHFields = computed(
    () =>
      selectedPayoffDisbursementTypeId.value === PayoffDisbursementTypeIds.ACH
  );

  // GETTERS
  const selectedPayoffDisbursementTypeId = computed<number>(() =>
    getPayoffDisbursementTypeId(currentPayoffForm.value)
  );

  const selectedSubServicerId = computed<number>(() =>
    getSelectedSubServicerId(currentPayoffForm.value)
  );

  const selectedPayableToId = computed<number | undefined>(() =>
    getSelectedPayableToId(currentPayoffForm.value)
  );

  const selectedSubServicer = computed<SubServicerViewModel | undefined>(() =>
    getSelectedServicer(currentPayoffForm.value)
  );

  const selectedSubServicerPaymentMethods = computed<PaymentMethodViewModel[]>(
    () => getSelectedServicerPaymentMethods(currentPayoffForm.value)
  );

  const selectedSubServicerPaymentMethod = computed<PaymentMethodViewModel>(
    () => getSelectedServicerPaymentMethod(currentPayoffForm.value)
  );

  const selectedSubServicerPaymentMethodOptions = computed<Option[]>(() =>
    getSelectedServicerPaymentMethodOptions(currentPayoffForm.value)
  );

  const selectedSubServicerFinancialInstitutions = computed<
    FinancialInstitutionViewModel[]
  >(() => getSelectedServicerFinancialInstitutions(currentPayoffForm.value));

  const payableToOptions = computed<Array<MailedChecksViewModel>>(() =>
    getSelectedServicerPayableTos(currentPayoffForm.value)
  );

  const payoffAddressOptions = computed<PayableViewModel[]>(() =>
    getSelectedServicerPayoffAddresses(currentPayoffForm.value)
  );

  const selectedPayableTo = computed(() =>
    getSelectedPayableTo(currentPayoffForm.value)
  );

  const defaultPayableTo = computed<MailedChecksViewModel | boolean>(() =>
    firstOrDefaultItem(payableToOptions.value)
  );

  const defaultPayoffAddress = computed<PayableViewModel | boolean>(() =>
    firstOrDefaultItem(payoffAddressOptions.value)
  );

  const defaultFinancialInstitution = computed<
    FinancialInstitutionViewModel | boolean
  >(() => firstOrDefaultItem(selectedSubServicerFinancialInstitutions.value));

  // Form States
  const payoffFormSchema = ref<FormSchema>({
    subServicerId: {
      name: "subServicerId",
      type: "select",
      label: "Servicer",
      autocompleteProperty: "name",
      placeholder: "Search",
      hideLabel: false,
      required: true,
      hideErrorMessage: true,
    },
    creditorName: {
      name: "creditorName",
      type: "text",
      label: "Lender",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
      maxLength: 40,
    },
    accountNumber: {
      name: "accountNumber",
      type: "text",
      label: "Account #",
      hideLabel: false,
      required: true,
      hideErrorMessage: true,
    },
    submittedPayoffBalance: {
      name: "submittedPayoffBalance",
      type: "money",
      label: "Sub. Payoff Balance",
      hideLabel: false,
      required: true,
      hideErrorMessage: true,
    },
    balanceEffectiveDate: {
      name: "balanceEffectiveDate",
      type: "date",
      label: "Balance Eff. Date",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
    },
    estimatedSettleDate: {
      name: "estimatedSettleDate",
      type: "readonly",
      label: "Est. Settle Date",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
    },
    payoffAmount: {
      name: "payoffAmount",
      type: "readonly",
      label: "Payoff Amount",
      hideLabel: false,
      required: false,
      hideErrorMessage: true,
    },
    payoffDisbursementTypeId: {
      name: "payoffDisbursementTypeId",
      type: "select",
      label: "Payment Method",
      required: true,
    },
    routingNumber: {
      name: "routingNumber",
      type: "text",
      label: "ABA Routing Number",
      required: false,
      readOnly: true,
    },
    daysOfInterest: {
      name: "daysOfInterest",
      type: "readonly",
      label: "Days of Interest",
      required: false,
      readOnly: true,
    },
    interestRate: {
      name: "interestRate",
      type: "text",
      label: "Interest Rate",
      hideLabel: false,
      required: false,
    },
    interestRateAvailable: {
      name: "interestRateAvailable",
      type: "toggle",
      label: "",
      hideLabel: true,
      required: false,
    },
    financialInstitution: {
      name: "financialInstitution",
      type: "readonly",
      label: "Financial Inst.",
      required: false,
      readOnly: true,
    },
    mrcAccountNumber: {
      name: "mrcAccountNumber",
      type: "text",
      label: "MRC Account #",
      required: false,
      readOnly: true,
    },
    additionalFees: {
      name: "additionalFees",
      type: "money",
      label: "Additional Fees",
      required: false,
    },
    dailyInterest: {
      name: "dailyInterest",
      type: "readonly",
      label: "Daily Interest",
      required: false,
      readOnly: true,
    },
    payableTo: {
      name: "payableTo",
      type: "select",
      label: "Payable To",
      required: showCheckFields.value,
      placeholder: "Search",
      autocompleteProperty: "payableTo",
      autoCompleteResultsErrorMessage: "No matches found",
    },
    address: {
      name: "address",
      type: "select",
      label: "Payoff Address",
      required: showCheckFields.value,
      placeholder: "Search",
      autocompleteProperty: "address",
      autoCompleteResultsErrorMessage: "No matches found",
    },
    attentionTo: {
      name: "attentionTo",
      type: "select",
      label: "Attention To",
      placeholder: "Search",
      autocompleteProperty: "name",
      required: false,
    },
    include: {
      type: "checkbox",
      label: "Include",
      name: "include",
      required: false,
    },
    finalizePayoff: {
      type: "checkbox",
      label: "Finalize Payoff",
      name: "finalizePayoff",
      required: false,
    },
  });

  const defaultFields = ref([
    {
      fieldName: "subServicerId",
      fieldLabel: "Servicer",
      fieldValue: computed(() => payoffFormData.value.subServicerId),
      fieldDisplayValue: computed(() => payoffFormData.value.subServicerName),
      schema: computed(() => payoffFormSchema.value.subServicerId),
      options: computed(() => subServicers.value),
      component: "search-dropdown",
      readOnly: false,
      display: true,
    },
    {
      fieldName: "creditorName",
      fieldLabel: "Lender",
      fieldValue: computed(() => payoffFormData.value.creditorName),
      fieldDisplayValue: undefined,
      schema: computed(() => payoffFormSchema.value.creditorName),
      readOnly: false,
      display: true,
    },
    {
      fieldName: "accountNumber",
      fieldLabel: "Account #",
      fieldValue: computed(() => payoffFormData.value.accountNumber),
      fieldDisplayValue: undefined,
      schema: computed(() => payoffFormSchema.value.accountNumber),
      readOnly: false,
      display: true,
    },
    {
      fieldName: "payoffDisbursementTypeId",
      fieldLabel: "Payment Method",
      fieldValue: computed(() => payoffFormData.value.payoffDisbursementTypeId),
      fieldDisplayValue: computed(
        () => payoffFormData.value.payoffDisbursementType
      ),
      options: computed(() => selectedSubServicerPaymentMethodOptions.value),
      schema: computed(() => payoffFormSchema.value.payoffDisbursementTypeId),
      readOnly: false,
      display: true,
    },
    {
      fieldLabel: "ABA Routing Number",
      fieldName: "routingNumber",
      fieldValue: computed(() => payoffFormData.value.routingNumber),
      fieldDisplayValue: undefined,
      schema: computed(() => payoffFormSchema.value.routingNumber),
      readOnly: true,
      component: undefined,
      display: computed(() => showACHFields.value),
    },
    {
      fieldLabel: "MRC Account #",
      fieldName: "mrcAccountNumber",
      fieldValue: computed(() => payoffFormData.value.mrcAccountNumber),
      fieldDisplayValue: undefined,
      schema: computed(() => payoffFormSchema.value.mrcAccountNumber),
      readOnly: true,
      component: undefined,
      display: computed(() => showACHFields.value),
    },
    {
      fieldLabel: "Financial Inst.",
      fieldName: "financialInstitution",
      fieldValue: computed(() => payoffFormData.value.financialInstitution),
      fieldDisplayValue: undefined,
      schema: payoffFormSchema.value.financialInstitution,
      options: [],
      readOnly: true,
      component: undefined,
      display: computed(() => showACHFields.value),
    },
    {
      fieldLabel: "Payable To",
      fieldName: "payableTo",
      fieldValue: computed(() => payoffFormData.value.payableTo),
      fieldDisplayValue: computed(() => payoffFormData.value.payableTo),
      schema: computed(() => payoffFormSchema.value.payableTo),
      options: computed(() => payableToOptions.value),
      component: "search-dropdown",
      readOnly: false,
      display: computed(() => showCheckFields.value),
    },
    {
      fieldLabel: "Payoff Address",
      fieldName: "address",
      fieldValue: computed(() => payoffFormData.value.address),
      fieldDisplayValue: computed(() => payoffFormData.value.address),
      schema: computed(() => payoffFormSchema.value.address),
      options: computed(() => payoffAddressOptions.value),
      component: "search-dropdown",
      readOnly: false,
      display: computed(() => showCheckFields.value),
    },
    {
      fieldLabel: "Attention To",
      fieldName: "attentionTo",
      fieldValue: computed(() => payoffFormData.value.attentionToId),
      fieldDisplayValue: computed(() => payoffFormData.value.attentionTo),
      schema: computed(() => payoffFormSchema.value.attentionTo),
      options: computed(() => attentionTos.value),
      component: "search-dropdown",
      readOnly: false,
      display: computed(() => showCheckFields.value),
    },
    {
      fieldName: "balanceEffectiveDate",
      fieldLabel: "Balance Eff. Date",
      fieldValue: computed(() => payoffFormData.value.balanceEffectiveDate),
      fieldDisplayValue: computed(
        () => payoffFormData.value.balanceEffectiveDateDisplay
      ),
      schema: computed(() => payoffFormSchema.value.balanceEffectiveDate),
      readOnly: false,
      display: true,
    },
    {
      fieldName: "submittedPayoffBalance",
      fieldLabel: "Sub. Payoff Balance",
      fieldValue: computed(() => payoffFormData.value.submittedPayoffBalance),
      fieldDisplayValue: computed(
        () => payoffFormData.value.submittedPayoffBalanceDisplay
      ),
      schema: computed(() => payoffFormSchema.value.submittedPayoffBalance),
      readOnly: false,
      display: true,
      validateOnKeyUp: false,
    },
    {
      fieldLabel: "Interest Rate",
      fieldName: "interestRate",
      fieldValue: computed(() => payoffFormData.value.interestRate),
      fieldDisplayValue: computed(
        () => payoffFormData.value.interestRateDisplay
      ),
      schema: computed(() => payoffFormSchema.value.interestRate),
      component: undefined,
      readOnly: false,
      display: true,
    },
    {
      fieldLabel: "Days of Interest",
      fieldName: "daysOfInterest",
      schema: computed(() => payoffFormSchema.value.daysOfInterest),
      fieldValue: computed(() => payoffFormData.value.daysOfInterest),
      fieldDisplayValue: computed(() => payoffFormData.value.daysOfInterest),
      readOnly: true,
      component: undefined,
      display: true,
    },
    {
      fieldName: "estimatedSettleDate",
      fieldLabel: "Settlement Date",
      fieldValue: computed(() => payoffFormData.value.estimatedSettleDate),
      fieldDisplayValue: computed(
        () => payoffFormData.value.estimatedSettleDateDisplay
      ),
      schema: computed(() => payoffFormSchema.value.estimatedSettleDate),
      readOnly: true,
      display: true,
    },
    {
      fieldLabel: "Daily Interest",
      fieldName: "dailyInterest",
      fieldValue: computed(() => payoffFormData.value.dailyInterest),
      fieldDisplayValue: computed(
        () => payoffFormData.value.dailyInterestDisplay
      ),
      schema: computed(() => payoffFormSchema.value.dailyInterest),
      component: undefined,
      display: true,
    },
    {
      fieldLabel: "Additional Fees",
      fieldName: "additionalFees",
      fieldValue: computed(() => payoffFormData.value.additionalFees),
      fieldDisplayValue: computed(
        () => payoffFormData.value.additionalFeesDisplay
      ),
      schema: computed(() => payoffFormSchema.value.additionalFees),
      component: undefined,
      display: true,
    },
    {
      fieldLabel: "Payoff Amount",
      fieldName: "payoffAmount",
      fieldValue: computed(() => payoffFormData.value.payoffAmount),
      fieldDisplayValue: computed(
        () => payoffFormData.value.payoffAmountDisplay
      ),
      schema: computed(() => payoffFormSchema.value.payoffAmount),
      readOnly: true,
      display: true,
    },
  ]);

  const defaultForm = ref<PayoffForm>({
    id: 0,
    refiLoanId: undefined,
    servicerName: "",
    subServicerName: "",
    creditorName: "",
    accountNumber: "",
    include: false,
    excludedLoan: false,
    payoffFinalized: false,
    servicerId: 0,
    subServicerId: 0,
    balanceEffectiveDate: undefined,
    submittedPayoffBalance: 0,
    submittedPayoffBalanceDisplay: "",
    estimatedSettleDate: undefined,
    estimatedSettleDateDisplay: "",
    payoffAmount: undefined,
    payoffAmountDisplay: "",
    payoffDisbursementTypeId: undefined,
    payoffDisbursementType: "",
    financialInstitutionsId: undefined,
    financialInstitution: "",
    mrcAccountNumber: "",
    routingNumber: "",
    mailedCheckDetailsId: undefined,
    payableTo: "",
    payoffAddressId: undefined,
    address: "",
    attentionTo: "",
    attentionToId: undefined,
    daysOfInterest: 0,
    additionalFees: undefined,
    additionalFeesDisplay: "",
    interestRate: undefined,
    interestRateDisplay: "N/A",
    dailyInterest: undefined,
    dailyInterestDisplay: "",
    tradeLineId: undefined,
    manualInput: true,
    finalizePayoff: false,
    interestRateAvailable: false,
    linkedDocuments: [],
    availableDocuments: [],
    viewLinkedDocuments: false,
    documentDropdownOpen: false,
    editLinkedDocuments: false,
    currentDocumentId: 0,
    stipulationIds: [],
    linkRequests: [],
    unlinkRequests: [],
    saveChangesClicked: false,
    cancelChangesClicked: false,
    isDocAttached: false,
    addAttentionTo: false,
  });

  // Formatters
  const moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function formatDate(dateInput?: string | Date) {
    const validISOFormat =
      dateInput &&
      typeof dateInput === "string" &&
      /\d{2,4}-\d{1,2}-\d{1,2}/.test(dateInput);

    if (validISOFormat) {
      const formattedDate = moment(dateInput)
        .tz(moment.tz.guess())
        .format("MM/DD/YYYY");

      return formattedDate;
    } else {
      console.log("Error: invalid ISO format");
    }
  }

  function convertToNumber(input: any) {
    if (input && typeof input === "string") {
      return parseInt(input);
    } else {
      return input;
    }
  }

  function maskAccountNumber(value: string) {
    return value ? value.replace(/\d(?=\d{4})/g, "*") : value;
  }

  function mask(field: string, value: string) {
    switch (field) {
      case "accountNumber":
        return maskAccountNumber(value);
      default:
        return value;
    }
  }

  function formatMoneyDisplay(moneyInput?: string | number) {
    if (moneyInput) {
      const money =
        typeof moneyInput === "string" && moneyInput?.trim()?.length > 0
          ? parseFloat(moneyInput)
          : typeof moneyInput === "number"
          ? moneyInput
          : undefined;

      if (money) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(money);
      } else {
        return "N/A";
      }
    }
  }

  function formatPercentage(percentageInput?: string | number) {
    if (
      (typeof percentageInput === "string" &&
        percentageInput?.trim()?.length > 0) ||
      typeof percentageInput === "number"
    ) {
      const percentage =
        typeof percentageInput === "string"
          ? parseFloat(percentageInput)
          : percentageInput;
      const percentageFormatted = percentage.toFixed(3);

      return percentageFormatted;
    } else {
      return;
    }
  }

  function formatFields(form: PayoffForm, originalPayoffForm?: PayoffForm) {

    form.submittedPayoffBalanceDisplay = form.submittedPayoffBalance
      ? formatMoneyDisplay(form.submittedPayoffBalance)
      : "";

    form.balanceEffectiveDateDisplay = form.balanceEffectiveDate
      ? formatDate(form.balanceEffectiveDate)
      : undefined;
    return form;
  }

  function getFieldDisplayName(field: string) {
    switch (field) {
      case "additionalFeesDisplay":
        return "additionalFees";
      case "balanceEffectiveDateDisplay":
        return "balanceEffectiveDate";
      case "submittedPayoffBalanceDisplay":
        return "submittedPayoffBalance";
      case "payoffAmountDisplay":
        return "payoffAmount";
      case "payoffDisbursementType":
        return "paymentMethod";
      case "address":
        return "payoffAddress";
      case "interestRateDisplay":
        return "interestRate";
      case "dailyInterestDisplay":
        return "dailyInterest";
      case "subServicerName":
        return "servicer";
      default:
        return field;
    }
  }

  // HELPERS
  function firstOrDefaultItem(array: any[] | undefined) {
    return array && array?.length === 1 ? array[0] : false;
  }

  function getSelectedServicer(payoffForm: PayoffForm) {
    return subServicers.value.find(
      (subServicer: SubServicerViewModel) =>
        subServicer.id === payoffForm.subServicerId
    );
  }

  function getSelectedServicerPaymentMethods(payoffForm: PayoffForm) {
    const servicer = getSelectedServicer(payoffForm);
    return servicer?.paymentMethods || [];
  }

  function getSelectedServicerPaymentMethodOptions(payoffForm: PayoffForm) {
    const servicer = getSelectedServicer(payoffForm);
    return (
      servicer?.paymentMethods?.map((p: PaymentMethodViewModel) => {
        return {
          label: p.payoffDisbursementType,
          value: p.payoffDisbursementTypeId,
        };
      }) || []
    );
  }

  function getSelectedServicerFinancialInstitutions(payoffForm: PayoffForm) {
    const paymentMethod = getSelectedServicerPaymentMethod(payoffForm);
    return (
      (paymentMethod?.financialInstitutions as FinancialInstitutionViewModel[]) ||
      []
    );
  }

  // Get selected servicer payableTos (mailedChecks)
  function getSelectedServicerPayableTos(payoffForm: PayoffForm) {
    const paymentMethod = getSelectedServicerPaymentMethod(payoffForm);
    return paymentMethod?.mailedChecks as MailedChecksViewModel[];
  }

  function getSelectedPayableTo(payoffForm: PayoffForm) {
    const payableTos = getSelectedServicerPayableTos(payoffForm);
    return payableTos?.find(
      (p: MailedChecksViewModel) => p.id === payoffForm.mailedCheckDetailsId
    );
  }

  // Get selected servicer mailed check payoff addresses (payables)
  function getSelectedServicerPayoffAddresses(payoffForm: PayoffForm) {
    const payableTos = getSelectedServicerPayableTos(payoffForm);

    return (
      (payableTos?.find(
        (p: MailedChecksViewModel) => p.id === payoffForm.mailedCheckDetailsId
      )?.payables as PayableViewModel[]) || []
    );
  }

  // Get selected servicer payment method
  function getSelectedServicerPaymentMethod(payoffForm: PayoffForm) {
    const paymentMethods = getSelectedServicerPaymentMethods(payoffForm);
    return (
      paymentMethods?.find(
        (pm: PaymentMethodViewModel) =>
          pm.payoffDisbursementTypeId === payoffForm.payoffDisbursementTypeId
      ) || ({} as PaymentMethodViewModel)
    );
  }

  function getPayoffDisbursementTypeId(payoffForm: PayoffForm) {
    return typeof payoffForm?.payoffDisbursementTypeId === "string"
      ? parseInt(payoffForm?.payoffDisbursementTypeId)
      : Number(payoffForm?.payoffDisbursementTypeId);
  }

  function getSelectedSubServicerId(payoffForm: PayoffForm) {
    return typeof payoffForm?.payoffDisbursementTypeId === "string"
      ? parseInt(payoffForm?.payoffDisbursementTypeId)
      : Number(payoffForm?.payoffDisbursementTypeId);
  }

  function getSelectedPayableToId(payoffForm: PayoffForm) {
    return typeof payoffForm?.mailedCheckDetailsId === "string"
      ? parseInt(payoffForm?.mailedCheckDetailsId)
      : Number(payoffForm?.mailedCheckDetailsId);
  }

  function getCurrentPayoffForm(payoffForm: PayoffForm) {
    if (payoffForm && payoffForm.id > 0 && !linkedDocumentsModalOpen.value) {
      return payoffTabStore.getPayoffForm(payoffForm);
    } else {
      return payoffFormData.value;
    }
  }

  function getPayoffForm(payoffId: number) {
    return payoffForms.value?.find(
      (payoffForm: PayoffForm) => payoffForm.id === payoffId
    );
  }

  function setPayoffFormData(payoffForm: PayoffForm) {
    payoffFormData.value = payoffForm;
  }

  function comparePayoffFormObjects(
    obj1: PayoffForm,
    obj2: PayoffForm,
    path = [] as any[]
  ) {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // description: takes an input of two objects and returns an array of all the differences between the two //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    let mismatchedKeys = [] as any;

    if (typeof obj1 !== typeof obj2) {
      return mismatchedKeys;
    }

    if (typeof obj1 !== "object" || obj1 === null) {
      if (obj1 !== obj2) {
        mismatchedKeys.push(path);
      }
      return mismatchedKeys;
    }

    for (const key of Object.keys(obj1)) {
      const newPath = [...path, key] as any;
      // console.log("key", { key, newPath });

      // eslint-disable-next-line no-prototype-builtins
      if (!obj2.hasOwnProperty(key)) {
        mismatchedKeys.push(newPath);
      }
      // if
      else if (
        (obj1[key] === undefined &&
          obj2[key] !== null &&
          obj2[key] !== undefined) ||
        (obj1[key] !== undefined &&
          obj1[key] !== null &&
          obj2[key] === undefined)
      ) {
        mismatchedKeys.push(newPath);
      } else if (
        (obj1[key] === null && obj2[key] !== null) ||
        (obj1[key] !== null && obj2[key] === null)
      ) {
        mismatchedKeys.push(newPath);
      } else {
        mismatchedKeys = [
          ...mismatchedKeys,
          ...comparePayoffFormObjects(obj1[key], obj2[key], newPath),
        ];
      }
    }

    return mismatchedKeys;
  }

  function checkPayoffFormChanges(object1: PayoffForm, object2: PayoffForm) {
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // description: returns an html string of changes in a form by comparing the before object with the after object //
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const ignoreFields = [
      "addAttentionTo",
      "additionalFees",
      "attentionToId",
      "balanceEffectiveDate",
      "currentDocumentId",
      "cancelChangesClicked",
      "dailyInterest",
      "daysOfInterest",
      "editLinkedDocuments",
      "estimatedSettleDate",
      "estimatedSettleDateDisplay",
      "financialInstitutionsId",
      "isDocAttached",
      "interestRate",
      "mailedCheckDetailsId",
      "manualInput",
      "newForm",
      "payoffAmount",
      "payoffAddressId",
      "payoffDisbursementTypeId",
      "servicerId",
      "servicerName",
      "stipulationIds",
      "submittedPayoffBalance",
      "subServicerId",
      "tradeLineId",
      "viewAdditionalDetails",
      "viewLinkedDocuments",
      "interestRateAvailable",
      "unlinkedDocuments",
      "linkedDocuments",
      "availableDocuments",
      "documentDropdownOpen",
      "refiLoanId",
      "excludedLoan",
      "saveChangesClicked",
    ];

    var html = "";

    comparePayoffFormObjects(object1, object2).forEach((reference) => {
      var before = reference.reduce((o, key) => o[key], object2);
      var after = reference.reduce((o, key) => o[key], object1);
      // else

      if (before === after || ignoreFields.includes(reference[0])) {
        return;
      } else if (
        reference[0] === "linkRequests" ||
        reference[0] === "unlinkRequests"
      ) {
        const action =
          reference[0] === "linkRequests" ? "Link Document" : "Unlink Document";
        const documentName =
          payoffTabStore.getDocumentNameByApplicantDocumentId(
            after.applicantDocumentId
          );
        // Handle docs
        html += `<tr><td class="xbefore">${action}</td><td class="xafter">${documentName}</td></tr>`;
      } else {
        reference[0] = getFieldDisplayName(reference[0]);

        if (before === null || before === undefined) {
          before = "";
        }

        if (after === null || after === undefined) {
          after = "";
        }

        // get the last variable from the reference array, and convert the camelCase to add spaces
        var name = reference.pop().replace(/([a-z])([A-Z])/g, `$1 $2`);

        // Handle amount
        html += `<tr><td class="xbefore">${name}</td><td class="xafter">${before}</td><td class="xafter">${after}</td></tr>`;
      }
    });

    return html === ""
      ? false
      : `<table class='xmodal-changes'>${html}</table>`;
  }

  async function confirmPayoffFormChanges(formData: PayoffForm) {
    let original = {} as PayoffForm;
    if (formData.refiLoanId && formData.refiLoanId > 0) {
      original = payoffTabStore.getOriginalPayoffForm(formData);
    } else {
      original = { ...defaultForm.value };
    }

    const modalHtml = checkPayoffFormChanges(formData, original);
    if (modalHtml) {
      functions.openModal({
        title: "Confirm",
        description: "Please review your changes before submitting...",
        html: modalHtml,
        buttons: [
          {
            title: "Cancel",
            onClick: () => {
              payoffTabStore.toggleSaveChangesClicked(formData, false);
              functions.closeModal();
            },
          },
          {
            title: "Save Changes",
            onClick: async () => {
              const request =
                convertPayoffFormToUpdateRefiLoanRequest(formData);

              await payoffTabStore.saveChanges(formData, request);
            },
          },
        ],
      });
    } else {
      functions.openModal({
        title: "Alert",
        description: "No changes made",
      });

      payoffTabStore.toggleSaveChangesClicked(formData, false);
    }
  }

  function convertPayoffFormToUpdateRefiLoanRequest(
    payoffForm: PayoffForm
  ): UpdateRefiLoanRequest | RefiLoanRequest {
    const refiLoanRequest = {
      include: payoffForm.include || false,
      servicerFinancialInstitutionsId: payoffForm?.financialInstitutionsId,
      servicerMailedCheckDetailsId: payoffForm.mailedCheckDetailsId,
      servicerAttentionToId: payoffForm.attentionToId,
      servicerAddressId: payoffForm.payoffAddressId,
      subServicerId: payoffForm.subServicerId,
      submittedPayoffBalance: payoffForm.submittedPayoffBalance,
      tradeLineId: payoffForm.tradeLineId,
      estimatedSettleDate: payoffForm.estimatedSettleDate,
      creditorName: payoffForm.creditorName, // [MaxLength(50)]
      accountNumber: payoffForm.accountNumber, //[MaxLength(50)]
      payoffAmount: payoffForm.payoffAmount,
      additionalFees: payoffForm.additionalFees,
      dailyInterest:
        payoffForm.dailyInterestDisplay === "N/A"
          ? null
          : payoffForm.dailyInterest,
      balanceEffectiveDate: payoffForm.balanceEffectiveDate
        ? new Date(payoffForm.balanceEffectiveDate)
        : null,
      applicationId: applicationId.value,
      interestRate:
        payoffForm.interestRateDisplay === undefined ||
        payoffForm.interestRateDisplay === "N/A"
          ? null
          : payoffForm.interestRate,
      finalized: payoffForm.finalizePayoff,
    };

    if (payoffForm.id > 0) {
      refiLoanRequest["refiLoanId"] = payoffForm.id;
    }
    return refiLoanRequest;
  }

  function payoffToPayoffForm(payoff: PayoffDetailViewModel): PayoffForm {
    return {
      id: payoff?.id || 0,
      refiLoanId: payoff?.id || undefined,
      servicerName: payoff?.servicerName,
      subServicerName: payoff?.subServicerName,
      creditorName: payoff?.creditorName,
      accountNumber: payoff.accountNumber,
      include: payoff.include,
      excludedLoan: payoff.include === false,
      payoffFinalized: payoff.additionalInformation.finalizePayoff === true,
      servicerId: payoff.servicerId,
      subServicerId: payoff.subServicerId,
      balanceEffectiveDate: payoff.balanceEffectiveDate
        ? payoff.balanceEffectiveDate?.toString()?.split("T")?.[0]
        : undefined,
      balanceEffectiveDateDisplay: payoff.balanceEffectiveDateDisplay
        ? payoff.balanceEffectiveDateDisplay
        : undefined,
      submittedPayoffBalance: payoff.submittedPayoffBalance,
      submittedPayoffBalanceDisplay: payoff.submittedPayoffBalanceDisplay,
      estimatedSettleDate: payoff.estimatedSettleDate,
      estimatedSettleDateDisplay: payoff.estimatedSettleDateDisplay,
      payoffAmount: payoff.payoffAmount,
      payoffAmountDisplay: payoff.payoffAmountDisplay,
      isDocAttached: payoff.isDocAttached,
      viewAdditionalDetails: payoff.viewAdditionalDetails,
      payoffsVerified: payoff.payoffsVerified,
      payoffDisbursementTypeId:
        payoff.additionalInformation.payoffDisbursementTypeId,
      payoffDisbursementType:
        payoff.additionalInformation.payoffDisbursementType,
      financialInstitutionsId:
        payoff.additionalInformation.financialInstitutionsId,
      financialInstitution: payoff.additionalInformation.financialInstitution,
      addAttentionTo: payoff.additionalInformation.addAttentionTo,
      mrcAccountNumber: payoff.additionalInformation.accountNumber,
      routingNumber: payoff.additionalInformation.routingNumber,
      mailedCheckDetailsId: payoff.additionalInformation.mailedCheckDetailsId,
      payableTo: payoff.additionalInformation.payableTo,
      payoffAddressId: payoff.additionalInformation.payoffAddressId,
      address: payoff.additionalInformation.address,
      attentionTo: payoff.additionalInformation.attentionTo,
      attentionToId: payoff.additionalInformation.attentionToId,
      daysOfInterest: payoff.additionalInformation.daysOfInterest,
      additionalFees: payoff.additionalInformation.additionalFees,
      additionalFeesDisplay: payoff.additionalInformation.additionalFeesDisplay,
      interestRate: payoff.additionalInformation.interestRate || undefined,
      interestRateDisplay: payoff.additionalInformation.interestRateDisplay,
      dailyInterest: payoff.additionalInformation.dailyInterest,
      dailyInterestDisplay: payoff.additionalInformation.dailyInterestDisplay,
      tradeLineId: payoff.additionalInformation.tradeLineId,
      manualInput: payoff.additionalInformation.manualInput,
      finalizePayoff: payoff.additionalInformation.finalizePayoff,
      linkedDocuments: payoff.additionalInformation.linkedDocuments,
      availableDocuments: payoff.additionalInformation.availableDocuments,
      viewLinkedDocuments: payoff.additionalInformation.viewLinkedDocuments,
      documentDropdownOpen: payoff.additionalInformation.documentDropdownOpen,
      editLinkedDocuments: payoff.additionalInformation.editLinkedDocuments,
      currentDocumentId: payoff.additionalInformation.currentDocumentId,
      interestRateAvailable:
        (payoff.additionalInformation.interestRate &&
          payoff.additionalInformation.interestRate > 0) ||
        false,
      stipulationIds: payoff.additionalInformation.stipulationIds,
      linkRequests: [],
      unlinkRequests: [],
      saveChangesClicked: false,
      cancelChangesClicked: false,
    };
  }

  function resetPayoffForm() {
    let payoffForm = {} as PayoffForm;
    payoffForm = defaultForm.value;
    payoffFormData.value = payoffForm;
    return payoffForm;
  }

  function resetPaymentMethodFields(payoffForm: PayoffForm) {
    // Reset Payable to
    payoffForm.mailedCheckDetailsId = undefined;
    payoffForm.payableTo = "";

    // Reset Payoff Address
    payoffForm.payoffAddressId = undefined;
    payoffForm.address = "";

    // Reset Attention To
    payoffForm.attentionToId = undefined;
    payoffForm.attentionTo = "";

    // Reset financial institution
    payoffForm.financialInstitution = "";
    payoffForm.financialInstitutionsId = undefined;

    // Reset MRC account number
    payoffForm.mrcAccountNumber = "";

    // Reset ABA routing number
    payoffForm.routingNumber = "";

    return payoffForm;
  }

  function toggleCheckRequiredFields(schema: FormSchema, flag: boolean) {
    // PayableTo required
    schema.payableTo.required = flag;
    // Payoff Address required
    schema.address.required = flag;
    // AttentionTo not required
    schema.attentionTo.required = false;

    return schema;
  }

  function toggleSchemaRequiredFields(
    formSchema?: FormSchema,
    payoffDisbursementTypeId?: number
  ) {
    let schema = formSchema ? formSchema : payoffFormSchema.value;
    const paymentTypeId = payoffDisbursementTypeId
      ? payoffDisbursementTypeId
      : selectedPayoffDisbursementTypeId.value;

    switch (paymentTypeId) {
      case PayoffDisbursementTypeIds.CHECK:
        schema = toggleCheckRequiredFields(schema, true);
        return schema;
      case PayoffDisbursementTypeIds.ACH:
        schema = toggleCheckRequiredFields(schema, false);
        return schema;
      default:
        schema = toggleCheckRequiredFields(schema, false);
        return schema;
    }
  }

  function setDefaultPayoffAddressOption(payoffForm?: PayoffForm) {
    if (
      defaultPayoffAddress.value &&
      typeof defaultPayoffAddress.value === "object"
    ) {
      payoffFormData.value.payoffAddressId = defaultPayoffAddress.value?.id;
      payoffFormData.value.address = defaultPayoffAddress.value?.address;
    }
  }

  function setDefaultPayableToOption(payoffForm?: PayoffForm) {
    const form = payoffForm ? payoffForm : payoffFormData.value;

    if (defaultPayableTo.value && typeof defaultPayableTo.value === "object") {
      payoffFormData.value.mailedCheckDetailsId = defaultPayableTo.value.id;
      payoffFormData.value.payableTo = defaultPayableTo.value.payableTo;
      setDefaultPayoffAddressOption();
    }
  }

  function setDefaultACHFields(
    payoffForm?: PayoffForm,
    defaultFinancialInsitution?: FinancialInstitutionViewModel
  ) {
    const form = payoffForm ? payoffForm : payoffFormData.value;
    const financialInstitution = defaultFinancialInsitution
      ? defaultFinancialInsitution
      : defaultFinancialInstitution.value;
    if (financialInstitution && typeof financialInstitution === "object") {
      form.financialInstitution = financialInstitution.name;
      form.financialInstitutionsId = financialInstitution.id;

      // set account number
      form.mrcAccountNumber = financialInstitution.accountNumber;

      // set routing number
      form.routingNumber = financialInstitution.routingNumber;
    }
  }

  function setServicerDefaultPaymentMethod() {
    if (selectedSubServicerPaymentMethods.value?.length === 1) {
      payoffFormData.value.payoffDisbursementTypeId =
        selectedSubServicerPaymentMethods.value[0]?.payoffDisbursementTypeId;
      payoffFormData.value.payoffDisbursementType =
        selectedSubServicerPaymentMethods.value[0]?.payoffDisbursementType;
    }
  }

  function setDefaultPaymentMethod() {
    resetPaymentMethodFields(payoffFormData.value);

    setServicerDefaultPaymentMethod();

    // Handle CHECK fields
    if (
      selectedSubServicerPaymentMethod.value &&
      selectedSubServicerPaymentMethod.value?.isCheck
    ) {
      setDefaultPayableToOption();
    }
    // Handle ACH fields
    if (
      selectedSubServicerPaymentMethod.value &&
      selectedSubServicerPaymentMethod.value?.isACH
    ) {
      setDefaultACHFields();
    }

    payoffFormSchema.value = toggleSchemaRequiredFields();
  }

  function handlePaymentMethodSelection() {
    setDefaultPaymentMethod();
  }

  interface PayoffFormFieldUpdatePayload {
    value: string | number | object | Array<any>;
    fieldName: string;
    payoffForm: PayoffForm;
    schema: FormSchema | FieldSchema;
  }

  function handlePayoffFormFieldUpdate(payload: PayoffFormFieldUpdatePayload) {
    payoffFormData.value = payload.payoffForm;
    payoffFormSchema.value = payload.schema;

    switch (payload?.fieldName) {
      case "subServicerId":
        setDefaultPaymentMethod();
        break;
      case "payoffDisbursementTypeId":
        payoffFormSchema.value = toggleSchemaRequiredFields(payload.schema);
        break;
      case "interestRateAvailable":
        payoffFormSchema.value = toggleInterestRateFields(payload.schema);
        break;
      default:
        return;
    }
  }

  /////////////////////////////////////|
  ////////////// Toggles //////////////|
  /////////////////////////////////////|

  function toggleAdditionalInformation(payoffForm: PayoffForm, flag: boolean) {
    payoffForm.viewAdditionalDetails = flag;
  }

  function toggleInterestRateFields(schema: FormSchema) {
    schema.interestRate.errors = [];
    schema.interestRate.valid = true;

    schema.dailyInterest.errors = [];
    schema.dailyInterest.valid = true;

    // interest rate toggle green = manual interest rate - read only daily interest
    if (payoffFormData.value.interestRateAvailable) {
      schema.interestRate.required = true;
      schema.dailyInterest.required = false;
      schema.interestRate.type = "percentage";
      schema.dailyInterest.type = "readonly";
    }

    // interest rate toggle gray -  interest rate = N/A and daily interest editable
    if (!payoffFormData.value.interestRateAvailable) {
      schema.interestRate.required = false;
      schema.dailyInterest.required = true;
      schema.interestRate.type = "readonly";
      schema.dailyInterest.type = "money";
    }

    payoffFormSchema.value = schema;
    return schema;
  }

  return {
    checkPayoffFormChanges,
    toggleAdditionalInformation,
    attentionTos,
    subServicers,
    payoffForms,
    payoffFormData,
    currentPayoffForm,
    currentFormFields,
    payableToOptions,
    selectedPayoffDisbursementTypeId,
    selectedSubServicerId,
    selectedPayableToId,
    selectedSubServicer,
    selectedSubServicerPaymentMethods,
    selectedSubServicerPaymentMethod,
    selectedSubServicerPaymentMethodOptions,
    selectedSubServicerFinancialInstitutions,
    payoffAddressOptions,
    selectedPayableTo,
    defaultPayableTo,
    defaultPayoffAddress,
    defaultFinancialInstitution,
    payoffFormSchema,
    defaultFields,
    linkedDocumentsModalOpen,
    firstOrDefaultItem,
    convertToNumber,
    maskAccountNumber,
    mask,
    getPayoffDisbursementTypeId,
    getSelectedSubServicerId,
    getSelectedPayableToId,
    getCurrentPayoffForm,
    resetPayoffForm,
    resetPaymentMethodFields,
    toggleCheckRequiredFields,
    toggleSchemaRequiredFields,
    setDefaultPayoffAddressOption,
    setDefaultPayableToOption,
    setDefaultACHFields,
    setServicerDefaultPaymentMethod,
    setDefaultPaymentMethod,
    handlePaymentMethodSelection,
    handlePayoffFormFieldUpdate,
    toggleInterestRateFields,
    getPayoffForm,
    confirmPayoffFormChanges,
    getSelectedServicer,
    getSelectedServicerPaymentMethods,
    getSelectedServicerPaymentMethod,
    getSelectedServicerPaymentMethodOptions,
    getSelectedServicerFinancialInstitutions,
    getSelectedServicerPayableTos,
    getSelectedServicerPayoffAddresses,
    getSelectedPayableTo,
    formatFields,
    payoffToPayoffForm,
    formatMoneyDisplay,
    formatPercentage,
    formatDate,
    convertPayoffFormToUpdateRefiLoanRequest,
  };
});
