import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "xpage-overview-collapse" }
const _hoisted_2 = { class: "xpage-overview" }
const _hoisted_3 = { class: "xpage-overview-cols" }
const _hoisted_4 = { class: "xpage-overview-cols-inner" }
const _hoisted_5 = {
  class: "xpage-overview-col",
  "data-type": "details"
}
const _hoisted_6 = { class: "xdetails-table" }
const _hoisted_7 = { class: "xdetails-col" }
const _hoisted_8 = { class: "xtext" }
const _hoisted_9 = { class: "xdetails-col" }
const _hoisted_10 = { class: "xtext" }
const _hoisted_11 = { class: "xdetails-col" }
const _hoisted_12 = { class: "xtext" }
const _hoisted_13 = { class: "xdetails-col" }
const _hoisted_14 = {
  key: 0,
  class: "xtext"
}
const _hoisted_15 = { class: "xdetails-col" }
const _hoisted_16 = { class: "xtext" }
const _hoisted_17 = { class: "xdetails-col" }
const _hoisted_18 = { class: "xtext" }
const _hoisted_19 = {
  key: 0,
  class: "xpage-overview-col",
  "data-type": "applicant"
}
const _hoisted_20 = { colspan: "2" }
const _hoisted_21 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_22 = { class: "xusericon-picture" }
const _hoisted_23 = { class: "xblackpill" }
const _hoisted_24 = { class: "xname" }
const _hoisted_25 = { class: "xtext" }
const _hoisted_26 = { class: "xtext" }
const _hoisted_27 = {
  class: "xtext",
  style: {"text-transform":"capitalize"}
}
const _hoisted_28 = { class: "xtext" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "xtext" }
const _hoisted_31 = { class: "xtext" }
const _hoisted_32 = {
  key: 1,
  class: "xpage-overview-col",
  "data-type": "missing-applicant"
}
const _hoisted_33 = {
  key: 2,
  class: "xpage-overview-col",
  "data-type": "applicant"
}
const _hoisted_34 = { colspan: "2" }
const _hoisted_35 = {
  class: "xusericon",
  "data-size": "small"
}
const _hoisted_36 = { class: "xusericon-picture" }
const _hoisted_37 = { class: "xblackpill" }
const _hoisted_38 = { class: "xname" }
const _hoisted_39 = { class: "xtext" }
const _hoisted_40 = { class: "xtext" }
const _hoisted_41 = {
  class: "xtext",
  style: {"text-transform":"capitalize"}
}
const _hoisted_42 = { class: "xtext" }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { class: "xtext" }
const _hoisted_45 = { class: "xtext" }
const _hoisted_46 = {
  key: 3,
  class: "xpage-overview-col",
  "data-type": "missing-coapplicant"
}

import { onMounted } from "vue";
import { ApplicationDetail } from "@/types/application-details";
import slideDown from "@/components/SlideDown.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment-timezone";
import functions from "@/use/functions";
import { NationalIdNumberTypes } from "@/types/application-details"

interface Props {
	application: any;
	applicants: any;
	details: ApplicationDetail,
	store: any;
	localTimezone: string;
	toggleApplicationDetails: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationApplicantDetails',
  props: {
    application: {},
    applicants: {},
    details: {},
    store: {},
    localTimezone: {},
    toggleApplicationDetails: {}
  },
  emits: ["toggleApplicationDetails"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;


const applicantNationalIdLabel = (applicantType: string) => 
		(props.applicants[applicantType]?.nationalIdNumberTypeId == NationalIdNumberTypes.SSN) ? 
		NationalIdNumberTypes[NationalIdNumberTypes.SSN] : NationalIdNumberTypes[NationalIdNumberTypes.ITIN]

onMounted(async () => {
	//await applicationsDetails();
	// console.log("ApplicationApplicantDetails.vue mounted");
	functions.getType("ApplicationStatus");
	// console.log(ApplicationApplicantDetails.vue props.application.data);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["xcollapse-desktop", [
					_ctx.application.showDetails ? 'icon-up-open-big' : 'icon-down-open-big',
				]]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleApplicationDetails()), ["prevent"]))
      }, null, 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(slideDown, {
        class: _normalizeClass({ xhideformobile: _ctx.application.mobileHidden })
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "xdetails-col-header" }, "Application Details", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "xname" }, "ID", -1)),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.application.data.referenceId), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "xname" }, "Created", -1)),
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(moment)(`${_ctx.application.data.created}Z`)
												.tz(_ctx.localTimezone)
												.format("MMMM D, YYYY, h:mm A z")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "xname" }, "Expiration", -1)),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(moment)(`${_ctx.application.data.created}Z`)
												.tz(_ctx.localTimezone)
												.add(60, "days")
												.format("MMMM D, YYYY, h:mm A z")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "xname" }, "Analyst Recommendation", -1)),
                    (_ctx.details.hasRecommendation)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.details.analystRecomendation), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "xname" }, "CU Service", -1)),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.details.isFullService ? "Full Service" : "Self Service"), 1)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "xname" }, "Status", -1)),
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.application.data.applicationStatusName), 1)
                  ])
                ])
              ]),
              (_ctx.applicants['primary'])
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("table", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.applicants["primary"].initials), 1),
                            _cache[8] || (_cache[8] = _createElementVNode("div", {
                              class: "xusericon-gender",
                              "data-gender": ""
                            }, null, -1))
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.applicants["primary"].fullName || "No Name") + " ", 1),
                          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.applicants["primary"].applicantTypeName), 1)
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_24, _toDisplayString(applicantNationalIdLabel("primary")), 1),
                        _createElementVNode("td", _hoisted_25, _toDisplayString(_unref(functions).ssnFormat(_ctx.applicants["primary"]?.nationalIdNumber) ||
											"Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[9] || (_cache[9] = _createElementVNode("td", { class: "xname" }, "Birthdate", -1)),
                        _createElementVNode("td", _hoisted_26, _toDisplayString(_unref(moment)(_ctx.applicants["primary"]?.dateOfBirth).format(
												"MMMM D, YYYY"
											) || "Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[10] || (_cache[10] = _createElementVNode("td", { class: "xname" }, "Address", -1)),
                        _createElementVNode("td", _hoisted_27, _toDisplayString([
												_ctx.applicants["primary"].addresses?.[0].address1,
												_ctx.applicants["primary"].addresses?.[0].address2,
												_ctx.applicants["primary"].addresses?.[0].city,
												_ctx.applicants["primary"].addresses?.[0].stateName,
												_ctx.applicants["primary"].addresses?.[0].postalCode,
												_ctx.applicants["primary"].addresses?.[0].countryName,
											]
												.filter((n) => n)
												.join(", ") || "Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[11] || (_cache[11] = _createElementVNode("td", { class: "xname" }, "Phone", -1)),
                        _createElementVNode("td", _hoisted_28, [
                          _createTextVNode(_toDisplayString(_ctx.applicants["primary"].contactInfo?.phoneDisplay ||
											"Not Provided") + " ", 1),
                          (_ctx.applicants['primary'].contactInfo?.phoneNumber)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_29, "(" + _toDisplayString(_ctx.applicants["primary"].contactInfo?.phoneNumberTypeName) + ") ", 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[12] || (_cache[12] = _createElementVNode("td", { class: "xname" }, "Email", -1)),
                        _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.applicants[
												"primary"
											].contactInfo?.emailAddress?.toLowerCase() ||
											"Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[13] || (_cache[13] = _createElementVNode("td", { class: "xname" }, "Username", -1)),
                        _createElementVNode("td", _hoisted_31, _toDisplayString(_ctx.applicants["primary"].username
												? _ctx.applicants["primary"].username
												: "—"), 1)
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[14] || (_cache[14] = [
                    _createElementVNode("div", null, "This application is missing a Primary Applicant", -1)
                  ]))),
              (_ctx.applicants['secondary'])
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createElementVNode("table", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.applicants["secondary"].initials), 1),
                            _cache[15] || (_cache[15] = _createElementVNode("div", {
                              class: "xusericon-gender",
                              "data-gender": ""
                            }, null, -1))
                          ]),
                          _createTextVNode(" " + _toDisplayString(_ctx.applicants["secondary"].fullName || "No Name") + " ", 1),
                          _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.applicants["secondary"].applicantTypeName), 1)
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_38, _toDisplayString(applicantNationalIdLabel("secondary")), 1),
                        _createElementVNode("td", _hoisted_39, _toDisplayString(_unref(functions).ssnFormat(_ctx.applicants["secondary"]?.nationalIdNumber) ||
											"Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[16] || (_cache[16] = _createElementVNode("td", { class: "xname" }, "Birthdate", -1)),
                        _createElementVNode("td", _hoisted_40, _toDisplayString(_unref(moment)(_ctx.applicants["secondary"]?.dateOfBirth).format(
												"MMMM D, YYYY"
											) || "Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[17] || (_cache[17] = _createElementVNode("td", { class: "xname" }, "Address", -1)),
                        _createElementVNode("td", _hoisted_41, _toDisplayString([
												_ctx.applicants["secondary"].addresses[0].address1,
												_ctx.applicants["secondary"].addresses[0].address2,
												_ctx.applicants["secondary"].addresses[0].city,
												_ctx.applicants["secondary"].addresses[0].stateName,
												_ctx.applicants["secondary"].addresses[0].postalCode,
												_ctx.applicants["secondary"].addresses[0].countryName,
											]
												.filter((n) => n)
												.join(", ") || "Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[18] || (_cache[18] = _createElementVNode("td", { class: "xname" }, "Phone", -1)),
                        _createElementVNode("td", _hoisted_42, [
                          _createTextVNode(_toDisplayString(_ctx.applicants["secondary"].contactInfo?.phoneDisplay ||
											"Not Provided") + " ", 1),
                          (_ctx.applicants['secondary'].contactInfo?.phoneNumber)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_43, "(" + _toDisplayString(_ctx.applicants["secondary"].contactInfo
													?.phoneNumberTypeName) + ")", 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[19] || (_cache[19] = _createElementVNode("td", { class: "xname" }, "Email", -1)),
                        _createElementVNode("td", _hoisted_44, _toDisplayString(_ctx.applicants[
												"secondary"
											].contactInfo?.emailAddress?.toLowerCase() ||
											"Not Provided"), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _cache[20] || (_cache[20] = _createElementVNode("td", { class: "xname" }, "Username", -1)),
                        _createElementVNode("td", _hoisted_45, _toDisplayString(_ctx.applicants["secondary"].username
												? _ctx.applicants["secondary"].username
												: "—"), 1)
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_46, _cache[21] || (_cache[21] = [
                    _createElementVNode("div", null, "This application has no Co-Applicant", -1)
                  ]))),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "clear" }, null, -1))
            ])
          ], 512), [
            [_vShow, _ctx.application.showDetails]
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}
}

})