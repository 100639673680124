import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ServiceType } from '@/models/loans';

export function usePostDisbursementChangeData() {
	const payoffDisbursementTypeOptions = ref<ServiceType[]>([]);

	async function getMethodTypeOptionsAsync() {
		try {
			const response = await axios.get('/api/payofftypes/PayoffDisbursementType');

			payoffDisbursementTypeOptions.value = response.data.map((type: ServiceType) => ({
				id: type.id,
				name: type.name
			}));
		} catch (error) {
			console.error('Error fetching payoff method types:', error);
		}
	}

	onMounted(async () => {
		await getMethodTypeOptionsAsync();
	});

	return {
		payoffDisbursementTypeOptions
	};
}
