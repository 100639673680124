import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "table-wrapper" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "header-label" }
const _hoisted_6 = { key: 0 }

import { onMounted, ref, computed } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import axios from 'axios';
	import functions from '@/use/functions';
	import Spinner from '@/components/shared/Spinner.vue';
	import PayoffRow from '@/components/loan/refi/PayoffRow.vue';
	import { PayoffDetails } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RefiDisbursementsTab',
  props: {
    loanId: {},
    lenderId: {},
    onPayoffsChanged: { type: Function }
  },
  setup(__props: any) {

	const props = __props;

	const route = useRoute();
	const router = useRouter();

	const isLoading = ref<boolean>(true);
	const payoffs = ref<PayoffDetails[]>([]);

	const headers = ref([
		{ label: 'Disb Id', sortable: true, key: 'id' },
		{ label: 'Batch Id', sortable: true, key: 'batchId' },
		{ label: 'Status', sortable: true, key: 'disbursementStatusHumanized' },
		{ label: 'Lender', sortable: true, key: 'lenderName' },
		{ label: 'Servicer', sortable: true, key: 'servicerName' },
		{ label: 'Account #', sortable: true, key: 'accountNumber' },
		{ label: 'Sent Date', sortable: true, key: 'transactionDateFormatted' },
		{ label: 'Original Disb', sortable: true, key: 'disbursementAmountAmountFormatted' },
		{ label: 'Total', sortable: true, key: 'adjustedDisbursementAmountAmountFormatted' },
		{ label: 'Payment Method', sortable: true, key: 'disbursementTypeHumanized' },
		{ label: 'Settle Date', sortable: true, key: 'settleDateFormatted' }
	]);

	const sortKey = ref('');
	const sortOrder = ref(1);

	const sortedPayoffs = computed(() => {
		if (!sortKey.value) {
			return payoffs.value; // no sorting, return original order
		}

		return [...payoffs.value].sort((a, b) => {
			let valA = a ? a[sortKey.value] : null;
			let valB = b ? b[sortKey.value] : null;

			if (typeof valA === 'string') {
				valA = valA.toLowerCase();
			}

			if (typeof valB === 'string') {
				valB = valB.toLowerCase();
			}

			if (valA < valB) {
				return -1 * sortOrder.value;
			} else if (valA > valB) {
				return 1 * sortOrder.value;
			}

			return 0;
		});
	});

	const sortBy = (key: string) => {
		if (sortKey.value === key) {
			if (sortOrder.value === 1) {
				sortOrder.value = -1; // second click, descending
			} else if (sortOrder.value === -1) {
				sortKey.value = ''; // third click, reset sort
				sortOrder.value = 1; // default sort order for the next click
			}
		} else {
			sortKey.value = key;
			sortOrder.value = 1; // first click, ascending
		}

		router.push({
			query: {
				...route.query,
				payoffSortKey: sortKey.value,
				payoffSortOrder: sortOrder.value.toString()
			},
			hash: route.hash
		});
	};

	const getSortArrow = (key: string) => {
		if (sortKey.value === key) {
			return sortOrder.value === 1 ? '▲' : sortOrder.value === -1 ? '▼' : ' ';
		}

		return '';
	};

	const isActiveSort = (key: string) => (sortKey.value === key ? 'active-sort' : '');

	async function refreshDataAsync() {
		await getPayoffsAsync();
		await props.onPayoffsChanged();
	}

	async function getPayoffsAsync() {
		try {
			const response = await axios(`/api/loanpayoffs/loan/${props.loanId}/lender/${props.lenderId}`);

			payoffs.value = response.data;
		} catch (error) {
			functions.openModal({
				title: 'Oops!',
				description: 'There was an issue getting the draws. Please try again later.'
			});
		}
	}

	function intializeSorting() {
		const querySortKey = route.query.payoffSortKey as string;
		const querySortOrder = parseInt(route.query.payoffSortOrder as string, 10);

		if (querySortKey) {
			sortKey.value = querySortKey;
		}

		if (!isNaN(querySortOrder) && (querySortOrder === 1 || querySortOrder === -1)) {
			sortOrder.value = querySortOrder;
		}
	}

	onMounted(async () => {
		intializeSorting();

		await getPayoffsAsync();

		isLoading.value = false;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(Spinner)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("table", null, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(headers.value, (header) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: header.key,
                      class: _normalizeClass(isActiveSort(header.key)),
                      onClick: ($event: any) => (sortBy(header.key))
                    }, [
                      _createElementVNode("span", _hoisted_5, [
                        _createTextVNode(_toDisplayString(header.label) + " ", 1),
                        (header.sortable)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(getSortArrow(header.key)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ], 10, _hoisted_4))
                  }), 128))
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedPayoffs.value, (payoff) => {
                  return (_openBlock(), _createBlock(PayoffRow, {
                    key: payoff.id,
                    payoff: payoff,
                    onPayoffUpdatedAsync: refreshDataAsync
                  }, null, 8, ["payoff"]))
                }), 128))
              ])
            ])
          ])
        ]))
  ]))
}
}

})