import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "decisions-knockouts-panel" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = {
  key: 0,
  class: "previous-pull-wrapper"
}
const _hoisted_4 = { class: "ribbon right" }
const _hoisted_5 = { class: "panel-header" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "panel-title-container" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "panel-summary" }
const _hoisted_10 = { class: "score-circle" }
const _hoisted_11 = { class: "credit-score" }
const _hoisted_12 = { class: "tab-container" }
const _hoisted_13 = { class: "tab-header" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  class: "tab-content"
}
const _hoisted_16 = { class: "knockouts-content" }
const _hoisted_17 = { class: "knockouts-table" }
const _hoisted_18 = { class: "score-factors" }
const _hoisted_19 = {
  key: 1,
  class: "tab-content"
}
const _hoisted_20 = { class: "knockouts-content" }
const _hoisted_21 = { class: "knockouts-table" }
const _hoisted_22 = {
  key: 2,
  class: "tab-content"
}
const _hoisted_23 = { class: "knockouts-content" }
const _hoisted_24 = { class: "knockouts-table" }
const _hoisted_25 = { class: "footer-link flex-container" }

import { ref, computed, onMounted } from "vue";
import { useCreditStore } from "@/store/store/creditStore";
import { ApplicantSlimVM } from "@/types/applicant";
import CreditReportLiabilitiesSLR from "@/components/v2/credit/refi-loan/CreditReportLiabilitiesSLR.vue";
import CreditTabKOModalSLR from "@/components/v2/credit/refi-loan/CreditTabKOModalSLR.vue";

interface Props {
  isPreviousPull?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditDecisionsPanelSLR',
  props: {
    isPreviousPull: { type: Boolean, default: false }
  },
  emits: ["closePreviousPull"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const creditStore = useCreditStore();
const creditHistoryId = ref(0);

const tabs = ["Knockouts", "Credit Alerts", "Supplemental Information"];
const activeTab = ref("Knockouts");
const showModal = ref(false);
const isCreditModalOpen = ref(false);

const ribbonText = computed(() => {
  return props.isPreviousPull ? "Previous Pull" : "X";
});

const closePanel = () => {
  emit("closePreviousPull");
};

const tableTabHeaders = computed(() => {
  if (activeTab.value === "Knockouts") {
    return ["Knockout Factor", "Result", "Status"];
  } else if (activeTab.value === "Credit Alerts") {
    return ["Alert Factors", "Result", "Status"];
  } else if (activeTab.value === "Supplemental Information") {
    return ["Supplemental Factors", "Result", "Status"];
  }
  return [];
});

const knockoutDecisionColor = computed(() => {
  return getLocalDecisionMapping(
    creditStore.CreditSelected(props.isPreviousPull).knockoutDecision
  );
});

const creditAlertDecisionColor = computed(() => {
  return getLocalDecisionMapping(
    creditStore.CreditSelected(props.isPreviousPull).creditAlertDecision
  );
});

const creditScoreDecisionColor = computed(() => {
  return getLocalDecisionMapping(
    creditStore.CreditSelected(props.isPreviousPull).creditScoreDecision
  );
});

const applicantDecisionColor = computed(() => {
  return getLocalDecisionMapping(
    creditStore.CreditSelected(props.isPreviousPull).applicantDecision
  );
});

function openCreditReport() {
  isCreditModalOpen.value = true;
}

function closeCreditReport(event) {
  isCreditModalOpen.value = false;

  creditHistoryId.value = event;
}

function changeCreditReport(applicant: ApplicantSlimVM) {
  creditStore.updateActiveApplicant(applicant.id, props.isPreviousPull);

  const creditHistory = creditStore
    .CreditSelected(props.isPreviousPull)
    .creditHistories.find((c) => c.isSelected);
  if (creditHistory !== undefined) {
    creditHistoryId.value = creditHistory.id;
  }
}

onMounted(() => {
  const creditHistory = creditStore
    .CreditSelected(props.isPreviousPull)
    .creditHistories.find((a) => a.isSelected);

  if (creditHistory !== undefined) {
    creditHistoryId.value = creditHistory.id;
  }
});

function getLocalDecisionMapping(status: string | undefined) {
  if (!status) {
    console.warn("No status provided, returning default.");
    return { color: "transparent", textColor: "#000" };
  }
  const normalizedStatus = status.trim().toLowerCase();

  const mapping = Object.values(creditStore.localDecisionMapping).find(
    (decision) => decision.label.trim().toLowerCase() === normalizedStatus
  );
  return mapping || { color: "transparent", textColor: "#000" };
}

function getDecisionMapping(status: string | undefined) {
  if (!status) {
    console.warn("No status provided, returning default.");
    return { color: "transparent", textColor: "#000" };
  }
  const normalizedStatus = status.trim().toLowerCase();

  const mapping = Object.values(creditStore.decisionMapping).find(
    (decision) => decision.label.trim().toLowerCase() === normalizedStatus
  );
  return mapping || { color: "transparent", textColor: "#000" };
}

function handleClick() {
  if (creditStore.KnockoutDetails && creditStore.KnockoutDetails.length > 0) {
    showModal.value = true;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "select-applicant-text" }, "Select An Applicant", -1)),
        (props.isPreviousPull)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(ribbonText.value), 1),
                _createElementVNode("button", {
                  class: "close-button",
                  onClick: _withModifiers(closePanel, ["stop"])
                }, "X")
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).ApplicantOptions(
            props.isPreviousPull
          ), (applicant, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["xnamebutton", { xselected: applicant.isSelected }]),
            onClick: ($event: any) => (changeCreditReport(applicant))
          }, _toDisplayString(applicant.applicantTypeName), 11, _hoisted_6))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "panel-title" }, "Applicant Credit Decision & Knockouts", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "decision-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((creditHistoryId).value = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (
            _unref(creditStore).handleCreditHistoryChange(
              creditHistoryId.value,
              props.isPreviousPull
            )
          ))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).CreditSelected(
              props.isPreviousPull
            ).creditHistories, (history, index) => {
            return (_openBlock(), _createElementBlock("option", {
              value: history.id,
              key: index
            }, _toDisplayString(history.name), 9, _hoisted_8))
          }), 128))
        ], 544), [
          [_vModelSelect, creditHistoryId.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", null, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "summary-label" }, "Knockout Decision", -1)),
          _createElementVNode("span", {
            class: "status-text",
            style: _normalizeStyle({ color: knockoutDecisionColor.value.textColor })
          }, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull).knockoutDecision), 5)
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "operator" }, "+", -1)),
        _createElementVNode("div", null, [
          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "summary-label" }, "Credit Alert Decision", -1)),
          _createElementVNode("span", {
            class: "status-text",
            style: _normalizeStyle({ color: creditAlertDecisionColor.value.textColor })
          }, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull)
                .creditAlertDecision), 5)
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "operator" }, "+", -1)),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull).creditScore), 1),
        _createElementVNode("div", _hoisted_11, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "summary-label" }, "Credit Score Decision", -1)),
          _createElementVNode("span", {
            class: "status-text",
            style: _normalizeStyle({ color: creditScoreDecisionColor.value.textColor })
          }, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull)
                .creditScoreDecision), 5)
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "operator" }, "=", -1)),
        _createElementVNode("div", null, [
          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "summary-label" }, "Applicant Decision", -1)),
          _createElementVNode("span", {
            class: "status-text",
            style: _normalizeStyle({ color: applicantDecisionColor.value.textColor })
          }, _toDisplayString(_unref(creditStore).CreditSelected(props.isPreviousPull).applicantDecision), 5)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tabs, (tab) => {
            return _createElementVNode("button", {
              key: tab,
              class: _normalizeClass([{ active: activeTab.value === tab }, "tab-button"]),
              onClick: ($event: any) => (activeTab.value = tab)
            }, _toDisplayString(tab), 11, _hoisted_14)
          }), 64))
        ]),
        (activeTab.value === 'Knockouts')
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("table", null, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableTabHeaders.value, (header, tabIndex) => {
                          return (_openBlock(), _createElementBlock("th", { key: tabIndex }, _toDisplayString(header), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).CreditSelected(_ctx.isPreviousPull).knockOuts, (knockout, knockoutIndex) => {
                        return (_openBlock(), _createElementBlock("tr", { key: knockoutIndex }, [
                          _createElementVNode("td", null, _toDisplayString(knockout.knockoutCode), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("span", {
                              style: _normalizeStyle({
                          color: getDecisionMapping(knockout.decision)
                            .textColor,
                        })
                            }, _toDisplayString(knockout.value), 5)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", {
                              class: "status-pill",
                              style: _normalizeStyle({
                          backgroundColor: getDecisionMapping(knockout.decision)
                            .color,
                        })
                            }, [
                              _createElementVNode("span", {
                                style: _normalizeStyle({
                            color: getDecisionMapping(knockout.decision)
                              .textColor,
                          })
                              }, _toDisplayString(knockout.decision), 5)
                            ], 4)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[13] || (_cache[13] = _createElementVNode("h4", null, "Score Factors", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).CreditSelected(
                  _ctx.isPreviousPull
                ).factors, (factor, factorIndex) => {
                    return (_openBlock(), _createElementBlock("p", { key: factorIndex }, _toDisplayString(factor.factorDescription), 1))
                  }), 128))
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (activeTab.value === 'Credit Alerts')
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("table", null, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableTabHeaders.value, (header, tabIndex) => {
                          return (_openBlock(), _createElementBlock("th", { key: tabIndex }, _toDisplayString(header), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).CreditSelected(_ctx.isPreviousPull)
                      .creditAlerts, (creditAlert, alertIndex) => {
                        return (_openBlock(), _createElementBlock("tr", { key: alertIndex }, [
                          _createElementVNode("td", null, _toDisplayString(creditAlert.knockoutCode), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("span", {
                              style: _normalizeStyle({
                          color: getLocalDecisionMapping(creditAlert.decision)
                            .textColor,
                        })
                            }, _toDisplayString(creditAlert.value), 5)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", {
                              class: "status-pill",
                              style: _normalizeStyle({
                          backgroundColor: getDecisionMapping(
                            creditAlert.decision
                          ).color,
                        })
                            }, [
                              _createElementVNode("span", {
                                style: _normalizeStyle({
                            color: getLocalDecisionMapping(creditAlert.decision)
                              .textColor,
                          })
                              }, _toDisplayString(creditAlert.decision), 5)
                            ], 4)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "score-factors" }, [
                  _createElementVNode("h4", null, "Score Factors"),
                  _createElementVNode("p", null, "Details about the alerts can be displayed here.")
                ], -1))
              ])
            ]))
          : _createCommentVNode("", true),
        (activeTab.value === 'Supplemental Information')
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("table", null, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableTabHeaders.value, (header, tabIndex) => {
                          return (_openBlock(), _createElementBlock("th", { key: tabIndex }, _toDisplayString(header), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).CreditSelected(_ctx.isPreviousPull)
                      .supplementals, (supplemental, supplementalIndex) => {
                        return (_openBlock(), _createElementBlock("tr", { key: supplementalIndex }, [
                          _createElementVNode("td", null, _toDisplayString(supplemental.knockoutCode), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("span", {
                              style: _normalizeStyle({
                          color: getLocalDecisionMapping(supplemental.decision)
                            .textColor,
                        })
                            }, _toDisplayString(supplemental.value), 5)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("div", {
                              class: "status-pill",
                              style: _normalizeStyle({
                          backgroundColor: getDecisionMapping(
                            supplemental.decision
                          ).color,
                        })
                            }, [
                              _createElementVNode("span", {
                                style: _normalizeStyle({
                            color: getDecisionMapping(supplemental.decision)
                              .textColor,
                          })
                              }, _toDisplayString(supplemental.decision), 5)
                            ], 4)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "score-factors" }, [
                  _createElementVNode("h4", null, "Score Factors"),
                  _createElementVNode("p", null, "Additional supplemental information about the applicant.")
                ], -1))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("button", {
          onClick: openCreditReport,
          class: "view-details-button"
        }, " View Credit Report & Liabilities "),
        _createElementVNode("p", null, [
          _cache[16] || (_cache[16] = _createTextVNode(" For a full list of the potential Knockout Results, ")),
          _createElementVNode("span", {
            class: _normalizeClass(["click-here", {
              disabled:
                !_unref(creditStore).KnockoutDetails ||
                _unref(creditStore).KnockoutDetails.length === 0,
            }]),
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
              _unref(creditStore).KnockoutDetails &&
              _unref(creditStore).KnockoutDetails.length > 0
                ? (showModal.value = true)
                : null
            ), ["prevent"]))
          }, "click here", 2),
          _cache[17] || (_cache[17] = _createTextVNode(". "))
        ])
      ]),
      (isCreditModalOpen.value)
        ? (_openBlock(), _createBlock(CreditReportLiabilitiesSLR, {
            key: 0,
            isOpen: isCreditModalOpen.value,
            isPreviousPull: props.isPreviousPull,
            onClose: closeCreditReport
          }, null, 8, ["isOpen", "isPreviousPull"]))
        : _createCommentVNode("", true),
      _createVNode(CreditTabKOModalSLR, {
        isOpen: showModal.value,
        onClose: _cache[3] || (_cache[3] = ($event: any) => (showModal.value = false))
      }, null, 8, ["isOpen"])
    ])
  ]))
}
}

})