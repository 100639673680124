import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "item" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "item" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "interest-rate-popover" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "item" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "item" }
const _hoisted_14 = { class: "value" }
const _hoisted_15 = { class: "item" }
const _hoisted_16 = { class: "value" }


    import Popover from '@/components/shared/Popover.vue';
    import { LoanDetails } from '@/models/loans';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'ServicerInterestDetailsCard',
  props: {
    loanDetails: {}
  },
  setup(__props: any) {

    

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Servicer Interest Rate Details", -1)),
      (_ctx.loanDetails.applicationUrl)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.loanDetails.applicationUrl,
            target: "_blank"
          }, " View Application ", 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "label" }, "Rate Type", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "dots" }, null, -1)),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.loanDetails.rateTypeHumanized), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "label" }, "Interest Rate", -1)),
      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "dots" }, null, -1)),
      _createElementVNode("span", _hoisted_7, [
        _createTextVNode(_toDisplayString(_ctx.loanDetails.servicerInterestRateFormatted) + " ", 1),
        _createVNode(Popover, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "label" }, "Index", -1)),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "dots" }, null, -1)),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.loanDetails.indexFormatted), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "label" }, "Margin", -1)),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "dots" }, null, -1)),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.loanDetails.marginFormatted), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "label" }, "Floor", -1)),
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "dots" }, null, -1)),
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.loanDetails.floorFormatted), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "label" }, "Ceiling", -1)),
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "dots" }, null, -1)),
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.loanDetails.ceilingFormatted), 1)
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})