import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "panels-container" }
const _hoisted_2 = { class: "additional-information" }
const _hoisted_3 = { class: "info-grid" }
const _hoisted_4 = { class: "info-item" }
const _hoisted_5 = { class: "info-result" }
const _hoisted_6 = { class: "info-item" }
const _hoisted_7 = { class: "info-result" }
const _hoisted_8 = { class: "info-item" }
const _hoisted_9 = { class: "info-result" }
const _hoisted_10 = { class: "info-item" }
const _hoisted_11 = { class: "info-result" }
const _hoisted_12 = { class: "info-item full-width" }
const _hoisted_13 = { class: "info-value" }
const _hoisted_14 = {
  key: 0,
  class: "repull-credit"
}
const _hoisted_15 = { class: "reissue-consent-container" }
const _hoisted_16 = { class: "checkbox-label-reissue" }
const _hoisted_17 = ["value"]
const _hoisted_18 = { class: "reissue-button-container" }
const _hoisted_19 = { class: "reissue-info-text" }
const _hoisted_20 = { class: "bold-text" }

import axios from "axios";
import {  ref } from "vue";
import { Permissions, PermissionLevels } from "@/models/permissions";
import PermissionsWrapper from "@/components/wrappers/PermissionsWrapper.vue";
import OpalButton from "@/components/shared/OpalButton.vue";
import { useApplicationStore } from "@/store/store/applicationVMStore";
import store from "@/store";

import { useCreditStore } from "@/store/store/creditStore";
import functions from "@/use/functions";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditReissueSLR',
  setup(__props) {

const applicationStore = useApplicationStore();
const creditStore = useCreditStore();

const checkboxChecked = ref(false);
const selectedReason = ref("");
const loading = ref<boolean>(false);
const currentCreditReport = ref<boolean>(false);
let isButtonClicked = ref(false);

const validations = {
  checkboxChecked: {
    condition: () => checkboxChecked.value,
    error: "Please check the consent box",
  },
  selectedReason: {
    condition: () => selectedReason.value,
    error: "Please select a reason",
  },
}

const validateFields = () => {
  for (const validation of Object.values(validations)) {
    if (!validation.condition()) {
      return validation.error;
    }
  }
  return null;
};

const repullCredit = async () => {
  isButtonClicked.value = true;

  const validationError = validateFields();
  if (validationError) {
    functions.openModal({
      title: "Error",
      message: validationError,
    })
    isButtonClicked.value = false;
    return;
  }

  if (!checkboxChecked.value) {
    functions.openModal({
      title: "Error",
      message: "Please check the consent box",
    })
    isButtonClicked.value = false;
    return;
  }

  if (!selectedReason.value) {
    functions.openModal({
      title: "Error",
      message: "Please select a reason",
    })
    isButtonClicked.value = false;
    return;
  }

  try {
    loading.value = true;
    const internalUserId = (store.state as any).oidcStore.user.sub;
    const applicantUserId = creditStore.ApplicantUserId(creditStore.selectedApplicantId);

    const payload = {
      creditId: creditStore.CreditSelected(false).creditReportId,
      creditRepullReasonId: selectedReason.value,
      internalUserId: internalUserId,
      applicantUserId: applicantUserId,
    };
    const response = await axios.post(
      `/api/applications/${applicationStore.applicationId}/creditrepullconsents/`,
      payload
    );
    console.log("Repull requested:", response);

    window.location.reload();
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(PermissionsWrapper, {
      disabled: !_unref(creditStore).creditTab.canEdit,
      showDisabledIfNotVisible: true,
      permissionIds: [_unref(Permissions).Credit],
      permissionLevel: _unref(PermissionLevels).Update
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "side-panel-title" }, "Additional Information", -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "info-label" }, "Stated Income", -1)),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(creditStore).CreditSelected(currentCreditReport.value).statedIncomeDisplay), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "info-label" }, "Stated DTI", -1)),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(creditStore).CreditSelected(currentCreditReport.value).statedDTIDisplay), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "info-label" }, "Verified Income", -1)),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(creditStore).CreditSelected(currentCreditReport.value).verifiedIncomeDisplay), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "info-label" }, "Verified DTI", -1)),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(creditStore).CreditSelected(currentCreditReport.value).verifiedDTIDisplay), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "info-label" }, "Total Monthly Obligation", -1)),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(creditStore).CreditSelected(currentCreditReport.value).monthlyDebtDisplay), 1)
            ])
          ]),
          (_unref(creditStore).creditTab.hasKnockOutCodeForReIssue)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _cache[11] || (_cache[11] = _createElementVNode("h4", null, "Re-Pull Credit", -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, [
                    _withDirectives(_createElementVNode("input", {
                      id: "checkbox",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkboxChecked).value = $event)),
                      class: "custom-checkbox"
                    }, null, 512), [
                      [_vModelCheckbox, checkboxChecked.value]
                    ]),
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "reissue-info-text-consent" }, [
                      _createTextVNode(" I have obtained consent from the applicant to re-pull their credit. "),
                      _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                    ], -1))
                  ])
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "reissue-section-title" }, [
                  _createTextVNode(" Credit Re-Pull Reason"),
                  _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                ], -1)),
                _createElementVNode("div", null, [
                  _withDirectives(_createElementVNode("select", {
                    class: "reissue-select-text",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedReason).value = $event))
                  }, [
                    _cache[9] || (_cache[9] = _createElementVNode("option", {
                      disabled: "",
                      value: ""
                    }, "Please select a reason", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(creditStore).creditRepullTypes, (type) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: type.id,
                        value: type.id
                      }, _toDisplayString(type.name), 9, _hoisted_17))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, selectedReason.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(OpalButton, {
                    class: "submit",
                    disabled: _unref(isButtonClicked),
                    type: "submit",
                    loading: loading.value,
                    text: "Re-pull & Re-Decision",
                    onClick: repullCredit
                  }, null, 8, ["disabled", "loading"])
                ]),
                _createElementVNode("span", _hoisted_19, [
                  _createTextVNode(" Current applicant " + _toDisplayString(_unref(creditStore).ApplicantFullName(_unref(creditStore).selectedApplicantId)) + " is eligible for a credit re-pull until ", 1),
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(creditStore).creditTab.reIssueEndDate), 1),
                  _cache[10] || (_cache[10] = _createTextVNode(". "))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["disabled", "permissionIds", "permissionLevel"])
  ]))
}
}

})