import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = { class: "knockout-table" }

import { computed, onMounted } from "vue";
import { useCreditStore } from "@/store/store/creditStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreditTabKOModalSLR',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const creditStore = useCreditStore();

const filteredKnockouts = computed(() => {
  return creditStore.KnockoutDetails
    .filter((k) => k.code !== "AnyED")
    .sort((a, b) => a.code.localeCompare(b.code));

});


const props = __props;

const emit: (event: "close") => void = __emit;

function close() {
  emit("close");
}

onMounted(async () => {
  try {
    if (creditStore.referenceId) {
      const currentCredit = creditStore.CreditSelected(false);
      const initialCreditHistory = currentCredit.creditHistories?.[0];
 
      if (initialCreditHistory) {
        await creditStore.getCreditReportByReference(
          creditStore.referenceId,
          initialCreditHistory,
          false
        );
      } else {
        console.warn('No credit histories available for initialization');
      }
    } else {
      console.warn('No reference ID available for initialization');
    }
  } catch (error) {
    console.error('Failed to initialize credit store:', error);
  }
});

return (_ctx: any,_cache: any) => {
  return (props.isOpen)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-overlay",
        onClick: _withModifiers(close, ["self"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", { class: "modal-header" }, [
            _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Knockout Modal", -1)),
            _createElementVNode("button", { onClick: close }, "X")
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("table", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Code"),
                  _createElementVNode("th", null, "Description")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredKnockouts.value, (knockout, index) => {
                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("strong", null, _toDisplayString(knockout.code), 1)
                    ]),
                    _createElementVNode("td", null, _toDisplayString(knockout.description), 1)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})