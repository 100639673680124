export interface LoanDetails {
	value: any | undefined;
	loan: Loan;
	availableToBorrow: AvailableToBorrow | null;
	locStatusHumanized: string | null;
	indexFormatted: string | null;
	marginFormatted: string | null;
	floorFormatted: string | null;
	ceilingFormatted: string | null;
	applicationUrl: string | null;
	rateTypeHumanized: string | null;
	applicationInterestRateFormatted: string | null;
	servicerInterestRateFormatted: string | null;
	repaymentTypeHumanized: string | null;
	lenderName: string | null;
	loanStatusHumanized: string | null;
	disbursedAmountFormatted: string | null;
	loanAmountTitle: string | null;
	loanAmountFormatted: string | null;
	repaymentTermFormatted: string | null;
	programName: string | null;
	loanStatusAtUtcFormatted: string;
	loanStatusReasonHumanized: string;
	filteredKeyDates: KeyDate[];
	program: any; // todo
	borrowers: Borrower[];
	servicerPartner: ServicerPartner | null;
	servicerLoanData: ServicerLoanData | null;
}

export interface Loan {
	id: number;
	referenceId: string;
	programTypeId: number;
	productType: number;
	lenderId: number;
	programAltCode: string | null;
}

export interface Borrower {
	id: number;
	initials: string;
	fullName: string;
	phoneNumber: string | null;
	emailAddress: string | null;
	nationalIdNumberFormatted: string;
	dateOfBirthFormatted: string;
	address: string;
}

export interface ServicerPartner {
	name: string;
	adminLoginUrl: string | null;
}

export interface ServicerLoanData {
	servicerLoanId: string;
}

export interface KeyDate {
	keyDateTypeHumanized: string;
	dateFormatted: string;
}

export interface AvailableToBorrow {
	availableToDrawAmount: number;
	drawsEnabled: boolean;
}

export class Draw {
	id = 0;
	drawStatusTypeId = 1;
	certificationMethodId = 1;
	enrollmentStatus = 1;
	cluid: string | null = null;
	sequenceNumber: string | null = null;
	schoolLevelTypeId: number | null = null;
	enrollmentTermId: number | null = null;
	requestedAmount: number | null = null;
	certifiedAmount: number | null = null;
	anticipatedGraduationDate: string | null = '';
	loanPeriodStartDate: string | null = '';
	loanPeriodEndDate: string | null = '';
	schoolCode: string | null = null;
	schoolBranch: string | null = null;

	constructor(initialValues?: Partial<Draw>) {
		if (initialValues) {
			Object.assign(this, initialValues);
		}
	}
}

export interface ServiceType {
	id: number;
	name: string | null;
	description?: string | null;
}

export interface School {
	id: number;
	schoolName: string;
	schoolDOEId: string;
	schoolBranchDOEId: string;
}

export const ValidDrawStatusIds = [
	1, // cert pending
	4, // school unable to cert
	8 // lender hold
];

export class Disbursement {
	id = 0;
	disbursementTypeId = 1;
	disbursementStatusTypeId = 1;
	drawDisbursementStatusReasonTypeId: number | null = null;
	sequenceNumber: string | null = null;
	certifiedAmount: number | null = null;
	disbursedAmount: number | null = null;
	transactionDate: string | null = '';

	constructor(initialValues?: Partial<Disbursement>) {
		if (initialValues) {
			Object.assign(this, initialValues);
		}
	}
}

export const ValidDisbursementStatusIds = [
	3, // school hold
	4, // cert on hold
	5 // scheduled
];

export interface Comment {
	id: number;
	entityId: number;
	commentType: number;
	comment: string | null;
	userDisplayName: string | null;
	createdAtUtc: Date;
	initials: string | null;
	createdAtFormatted: string | null;
}

export interface PayoffDetails {
	id: number;
	batchId: string;
	payoffDisbursementStatusId: number;
	payoffDisbursementStatusReasonId: number;
	payoffDisbursementTypeId: number;
	disbursementAmount: number;
	payoffFees: number;
	adjustedDisbursementAmount: number;
	adjustedFees: number;
	lenderName: string;
	subServicerName: string;
	servicerName: string;
	attentionTo: string;
	transactionDate: string | null;
	settleDate: string | null;
	disbursementStatusHumanized: string;
	disbursementStatusReasonHumanized: string;
	disbursementTypeHumanized: string;
	disbursementAmountFormatted: string;
	adjustedDisbursementAmountFormatted: string;
	transactionDateFormatted: string;
	settleDateFormatted: string;
	subServicerAddress: SubServicerAddress | null;
	financialInstitution: FinancialInstitution | null;
	accountNumber: string;
}

export interface SubServicerAddress {
	name: string;
	attentionTo: AttentionTo | null;
	address1: string | null;
	address2: string | null;
	city: string;
	state: string;
	zipCode: string;
	zipCodeExtension: string;
}

export interface AttentionTo {
	name: string;
}

export interface FinancialInstitution {
	name: string;
	accountingNumber: string | null;
	routingNumber: string | null;
}

export interface PayoffPostDisbursementChange {
	adjustedAmount: number;
	adjustedFeeAmount: number;
	refundProcessingDate: string;
	refundSettleDate: string;
	refundEffectiveDate: string;
}

export interface LoanDocument {
	id: number;
	name: string;
	uploadAtUtc: Date;
	documentTypeId: number;
	borrowerId: number;
	uploadAtUtcFormatted: string;
	documentTypeHumanized: string;
}

export interface UpdateDocumentRequest {
	documentTypeId: number;
	borrowerId: number;
}

export interface DocumentProperties {
	pageCount: number;
}

export interface SplitDocumentRequest {
	documentId: number;
	entityTypeId: number;
	options: SplitDocumentOptions[];
}

export interface SplitDocumentOptions {
	// todo: update app-centric naming in C# models
	documentType: number;
	applicantId: number;
	startIndex: number;
	endIndex: number;
	applicantViewable: boolean;
}
