import { FieldSchema, FormSchema } from "@/models/form";

export enum PayoffDisbursementTypes {
  ACH = 1,
  WIRE = 2,
  CHECK = 3,
  MASTER_CHECK = 4,
}

export class PayoffDisbursementTypeIds {
  public static get ACH(): number {
    return PayoffDisbursementTypes.ACH;
  }
  public static get CHECK(): number {
    return PayoffDisbursementTypes.CHECK;
  }
  public static get WIRE(): number {
    return PayoffDisbursementTypes.WIRE;
  }
  public static get MASTER_CHECK(): number {
    return PayoffDisbursementTypes.MASTER_CHECK;
  }
}

export interface LOSType {
  id: number;
  name: string;
}

export interface AttentionTo {
  id: number;
  name: string;
}

export interface Response {
  success: boolean;
  errors?: Array<string>;
}

export interface PayoffFormSchemas {
  [key: string]: FormSchema;
}

export interface PayoffForms {
  [key: string]: PayoffForm;
}

export interface ApplicationPayoffsResponse extends Response {
  results: ApplicationPayoffsResults;
}

export interface AddAttentionToResponse extends Response {
  results: Array<AttentionTo>;
}
export interface ApplicationPayoffsResults {
  applicationId: number;
  canEdit: boolean;
  canEditIncludeColumn: boolean;
  isAdmin: boolean;
  subServicers: Array<SubServicerViewModel>;
  disbursementTypes: Array<LOSType>;
  includedLoans: any;
  excludedLoans: any;
  payoffFinalized: boolean;
  totalConsolidatedLoanAmount?: number;
  totalConsolidatedLoanAmountDisplay?: string;
  payoffsVerified: boolean;
  availableDocuments: Array<PayoffDocumentViewModel>;
  attentionTos: Array<LOSType>;
}

export interface UpdateRefiLoanResponse extends Response {
  results: {
    thisRefiLoan: PayoffDetailViewModel;
    totalConsolidatedLoanAmount: number;
    totalConsolidatedLoanAmountDisplay: string;
  };
  linkRequests: LinkUnlinkDocumentToRefiLoanRequest[];
  unlinkRequests: LinkUnlinkDocumentToRefiLoanRequest[];
}

export interface AddRefiLoanResponse extends Response {
  results: {
    thisRefiLoan: PayoffDetailViewModel;
    totalConsolidatedLoanAmount: number;
    totalConsolidatedLoanAmountDisplay: string;
  };
  linkRequests: LinkUnlinkDocumentToRefiLoanRequest[];
  unlinkRequests: LinkUnlinkDocumentToRefiLoanRequest[];
}

export interface PayoffForm {
  id: number;
  refiLoanId?: number;
  servicerName?: string;
  creditorName: string;
  lender?: string;
  accountNumber?: string;
  include: boolean;
  excludedLoan: boolean;
  payoffFinalized: boolean;
  servicerId?: number;
  subServicerId?: number;
  subServicerName?: string;
  balanceEffectiveDate?: Date | string;
  balanceEffectiveDateDisplay?: string;
  submittedPayoffBalance?: number;
  submittedPayoffBalanceDisplay?: string;
  estimatedSettleDate?: Date | null;
  estimatedSettleDateDisplay?: string;
  payoffAmount?: number;
  payoffAmountDisplay?: string;
  isDocAttached: boolean;
  viewAdditionalDetails?: boolean;
  payoffsVerified?: boolean;
  payoffDisbursementTypeId?: number;
  payoffDisbursementType?: string;
  financialInstitutionsId?: number;
  financialInstitution?: string;
  addAttentionTo: boolean;
  mrcAccountNumber?: string;
  routingNumber?: string;
  mailedCheckDetailsId?: number;
  payableTo?: string;
  payoffAddressId?: number;
  address?: string;
  attentionTo?: string;
  attentionToId?: number;
  daysOfInterest?: number;
  additionalFees?: number;
  additionalFeesDisplay: string;
  interestRate?: number;
  interestRateDisplay: string;
  dailyInterest?: number;
  dailyInterestDisplay?: string;
  tradeLineId?: number | null;
  manualInput: boolean;
  finalizePayoff: boolean;
  interestRateAvailable: boolean;
  linkedDocuments: PayoffDocumentViewModel[];
  availableDocuments: PayoffDocumentViewModel[];
  viewLinkedDocuments: boolean;
  documentDropdownOpen: boolean;
  editLinkedDocuments: boolean;
  currentDocumentId: number;
  stipulationIds?: number[];
  linkRequests: LinkUnlinkDocumentToRefiLoanRequest[];
  unlinkRequests: LinkUnlinkDocumentToRefiLoanRequest[];
  saveChangesClicked: boolean;
  cancelChangesClicked: boolean;
}

export interface PayoffDetailViewModel {
  id: number;
  servicerName?: string;
  creditorName: string;
  accountNumber: string;
  include: boolean;
  servicerId: number;
  subServicerId: number;
  subServicerName?: string;
  balanceEffectiveDate?: Date | string;
  balanceEffectiveDateDisplay: string;
  submittedPayoffBalance?: number;
  submittedPayoffBalanceDisplay: string;
  estimatedSettleDate?: Date | null;
  estimatedSettleDateDisplay: string;
  payoffAmount: number;
  payoffAmountDisplay: string;
  isDocAttached: boolean;
  viewAdditionalDetails: boolean;
  additionalInformation: PayoffAdditionalInformationViewModel;
  payoffsVerified?: boolean;
}

export interface PayoffAdditionalInformationViewModel {
  payoffDisbursementTypeId?: number;
  payoffDisbursementType: string;
  financialInstitutionsId?: number;
  financialInstitution: string;
  addAttentionTo: boolean;
  accountNumber: string;
  routingNumber: string;
  mailedCheckDetailsId?: number;
  payableTo: string;
  payoffAddressId?: number;
  address: string;
  attentionTo: string;
  attentionToId?: number;
  daysOfInterest: number;
  additionalFees?: number;
  additionalFeesDisplay: string;
  interestRate?: number | undefined;
  interestRateDisplay: string;
  dailyInterest?: number;
  dailyInterestDisplay?: string;
  tradeLineId?: number;
  manualInput: boolean;
  finalizePayoff: boolean;
  linkedDocuments: PayoffDocumentViewModel[];
  availableDocuments: PayoffDocumentViewModel[];
  viewLinkedDocuments: boolean;
  documentDropdownOpen: boolean;
  editLinkedDocuments: boolean;
  currentDocumentId: number;
  interestRateAvailable: boolean;
  stipulationIds: number[];
}

export interface PayoffDocumentViewModel {
  applicantDocumentId: number;
  documentId: number;
  documentName: string;
}

export interface SubServicerViewModel {
  id: number;
  name: string;
  servicerId: number;
  servicerName: string;
  paymentMethods: PaymentMethodViewModel[];
}

export interface PaymentMethodViewModel {
  payoffDisbursementTypeId: number;
  payoffDisbursementType: string;
  isEffective: boolean;
  isCheck: boolean;
  isACH: boolean;
  financialInstitutions: any[];
  mailedChecks: MailedChecksViewModel[];
}

export interface MailedChecksViewModel {
  id: number;
  payableTo: string;
  isEffective: boolean;
  payables: PayableViewModel[];
}

export interface FinancialInstitutionViewModel {
  accountNumber: string;
  id: number;
  isEffective: boolean;
  name: string;
  routingNumber: string;
}

export interface PayableViewModel {
  id: number;
  attentionTo: string;
  defaultAttentionTo?: string;
  defaultAttentionToId?: number;
  address: string;
}
// LinkUnlinkDocumentToFromRefiLoanRequest
export interface LinkUnlinkDocumentToRefiLoanRequest {
  applicationId: number;
  refiLoanId: number;
  applicantDocumentId: number;
}

export interface AddAttentionToRequest {
  name: string;
}

export interface RefiLoanRequest {
  include: boolean;
  servicerFinancialInstitutionsId?: number | null | undefined;
  servicerMailedCheckDetailsId?: number | null | undefined;
  servicerAttentionToId?: number | null | undefined;
  servicerAddressId?: number | null | undefined;
  subServicerId?: number | null | undefined;
  submittedPayoffBalance?: number | null | undefined;
  tradeLineId?: number | null | undefined;
  estimatedSettleDate?: Date | null;
  creditorName?: string;
  accountNumber?: string;
  payoffAmount?: number | null | undefined;
  additionalFees?: number | null | undefined;
  dailyInterest?: number | null | undefined;
  balanceEffectiveDate?: Date | null;
  applicationId: number | null | undefined;
  interestRate?: number | null | undefined;
  finalized?: boolean;
}

export interface UpdateRefiLoanRequest extends RefiLoanRequest {
  refiLoanId: number;
  include: boolean;
  servicerFinancialInstitutionsId?: number | null | undefined;
  servicerMailedCheckDetailsId?: number | null | undefined;
  servicerAttentionToId?: number | null | undefined;
  servicerAddressId?: number | null | undefined;
  subServicerId?: number | null | undefined;
  submittedPayoffBalance?: number | null | undefined;
  tradeLineId?: number | null | undefined;
  estimatedSettleDate?: Date | null;
  creditorName?: string;
  accountNumber?: string;
  payoffAmount?: number | null | undefined;
  additionalFees?: number | null | undefined;
  dailyInterest?: number | null | undefined;
  balanceEffectiveDate?: Date | null;
  applicationId: number | null | undefined;
  interestRate?: number | null | undefined;
  finalized?: boolean;
}

export interface FormFieldViewModel {
  fieldName: string;
  fieldLabel: string;
  fieldValue?: null | string | number | object | Array<any>;
  fieldDisplayValue?: null | string | number | object | Array<any>;
  schema?: FieldSchema;
  options?: any;
  autocompleteOptions?: any;
  component?: string;
  readOnly?: boolean;
  display?: boolean;
  validateOnKeyUp?: boolean;
}
