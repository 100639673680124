import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 0,
  class: "item"
}
const _hoisted_3 = { class: "value" }
const _hoisted_4 = { class: "item" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "item" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "item" }
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 1,
  class: "value"
}
const _hoisted_11 = { class: "item" }
const _hoisted_12 = { class: "value" }

import { computed } from 'vue';
	import { LoanDetails } from '@/models/loans';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ServicerDetailsCard',
  props: {
    loanDetails: {}
  },
  setup(__props: any) {

	const props = __props;

	const servicerName = computed(() => props.loanDetails.servicerPartner?.name || 'N/A');
	const adminLoginUrl = computed(() => props.loanDetails.servicerPartner?.adminLoginUrl);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "header" }, [
      _createElementVNode("h3", null, "Servicer Details")
    ], -1)),
    (_ctx.loanDetails.loan.productType === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "label" }, "Draw Status", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "dots" }, null, -1)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.loanDetails.locStatusHumanized), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "label" }, "Repayment Type", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "dots" }, null, -1)),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.loanDetails.repaymentTypeHumanized), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "label" }, "Repayment Term", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "dots" }, null, -1)),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.loanDetails.repaymentTermFormatted), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "label" }, "Servicer Name", -1)),
      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "dots" }, null, -1)),
      (adminLoginUrl.value)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: adminLoginUrl.value,
            class: "value",
            target: "_blank"
          }, _toDisplayString(servicerName.value), 9, _hoisted_9))
        : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(servicerName.value), 1))
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "label" }, "Id at Servicer", -1)),
      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "dots" }, null, -1)),
      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.loanDetails.servicerLoanData?.servicerLoanId || 'N/A'), 1)
    ])
  ]))
}
}

})