import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, vModelSelect as _vModelSelect, vShow as _vShow, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "xpage-top-details-status-container" }
const _hoisted_2 = { class: "xpage-top-details-status" }
const _hoisted_3 = {
  key: 0,
  class: "icon-up-open-big"
}
const _hoisted_4 = {
  key: 1,
  class: "icon-down-open-big"
}
const _hoisted_5 = {
  key: 0,
  class: "xheader-search-dropdown",
  style: {"margin-top":"3px"}
}
const _hoisted_6 = {
  class: "application-st",
  style: {"padding":"10px 15px"}
}
const _hoisted_7 = ["name", "checked", "onInput"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "xdropdown-buttons" }
const _hoisted_10 = {
  key: 1,
  class: "denial-reason-box"
}
const _hoisted_11 = { class: "grid-container" }
const _hoisted_12 = { class: "column denial-reasons-column" }
const _hoisted_13 = { class: "new-denial-reason-entry" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "add-clear-buttons" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 0,
  class: "max-reasons-message"
}
const _hoisted_19 = { class: "column requested-for-column" }
const _hoisted_20 = { class: "applicant-labels-row" }
const _hoisted_21 = { class: "label-semi-bold" }
const _hoisted_22 = { class: "checkbox-container" }
const _hoisted_23 = ["value", "checked", "onChange", "disabled"]
const _hoisted_24 = { class: "added-denial-reasons-container" }
const _hoisted_25 = {
  key: 0,
  class: "added-denial-reasons"
}
const _hoisted_26 = { class: "reason-content" }
const _hoisted_27 = { class: "reason-number" }
const _hoisted_28 = { class: "reason-description" }
const _hoisted_29 = { class: "reason-applicants" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = ["required"]
const _hoisted_32 = { class: "xdropdown-buttons" }

import { computed, ref, reactive, onMounted, onBeforeMount, watch } from "vue";
import { FormSchema } from "@/models/form";
import { $api } from "@/services/api1";
import functions from "@/use/functions";
import useFunctions from "@/use/functions1";

interface Props {
  store?: any;
  application: any;
  animate: any;
  applicants: any;
}

interface DenialReasonEntry {
  reasonId: number;
  applicantId: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplicationStatus',
  props: {
    store: {},
    application: {},
    animate: {},
    applicants: {}
  },
  emits: [
  "submitForm",
  "dropdownClicked",
  "cancelClicked",
  "getApplication",
],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const { decodeHTMLEncodedStr } = useFunctions();

const applicants = computed(() => {
  if (!props.applicants || typeof props.applicants !== "object") {
    console.error("Applicants is not an object", props.applicants);
    return [];
  }

  return ["primary", "secondary"]
    .filter(
      (type) =>
        props.applicants[type] && Object.keys(props.applicants[type]).length > 0
    )
    .map((type) => ({
      ...props.applicants[type],
      applicantTypeName: type === "primary" ? "Primary" : "Co-Applicant",
      applicantTypeId: type === "primary" ? 1 : 2,
    }));
});

const denialReasonEntries = ref<DenialReasonEntry[]>([]);
const selectedReason = ref<number | null>(null);

// previously selected reasons
const previouslySelectedReasonIds = ref<number[]>([]);
const previouslySelectedReasons = ref<string[]>([]);

// form info
const info = reactive({} as any);
const props = __props;
const showDenialReasonDropdown = ref(false);
const showDenialReasonSection = ref(false);

// check if 'Other' denial reason is selected
const otherDenialReasonSelected = computed(() =>
  denialReasonEntries.value.some((entry) => entry.reasonId === 36)
);

// status dropdown visibility
const showStatusDropdown = ref<boolean>(false);

// denial reasons per applicant from vuex store
const jdts = computed(() => {
  const sortedReasons = [
    ...(props?.store?.state?.types["JudgmentDenials"]?.results ?? []),
  ]
    .filter((jdt: any) => jdt.visible === true)
    .sort((a: any, b: any) => a.description.localeCompare(b.description));

  const otherIndex = sortedReasons.findIndex(
    (jdt: any) => jdt.description.toLowerCase() === "other"
  );
  if (otherIndex > -1) {
    const [otherReason] = sortedReasons.splice(otherIndex, 1);
    sortedReasons.push(otherReason);
  }

  return sortedReasons;
});

const totalDenialReasons = computed(() => denialReasonEntries.value.length);

// remove a specific denial reason
function removeReason(index: number) {
  denialReasonEntries.value.splice(index, 1);
}

function getApplicantName(applicantId: number): string {
  const applicantTypes = ["primary", "secondary"];
  for (const type of applicantTypes) {
    if (props.applicants[type] && props.applicants[type].id === applicantId) {
      return type === "primary" ? "Primary" : "Co-Applicant";
    }
  }
  return "";
}

const getDenialReasonName = (reasonId: number | null): string => {
  if (!reasonId) return "";
  const reason = jdts.value.find((jdt: any) => jdt.id === reasonId);
  return reason ? reason.description : "";
};

function isApplicantSelectedForReason(applicantId: number): boolean {
  if (selectedReason.value == null) {
    return false;
  }
  return denialReasonEntries.value.some(
    (entry) =>
      entry.reasonId === selectedReason.value &&
      entry.applicantId === applicantId
  );
}

function addAnotherReason() {
  if (totalDenialReasons.value >= 4) {
    functions.openModal({
      title: "Alert",
      description: "You have reached the maximum of 4 denial reasons.",
    });
    return;
  }
  selectedReason.value = null;
}

function toggleApplicantForReason(applicantId: number, event: Event) {
  if (selectedReason.value == null) {
    functions.openModal({
      title: "Alert",
      description: "Please select a denial reason first.",
    });
    (event.target as HTMLInputElement).checked = false;
    return;
  }

  const isChecked = (event.target as HTMLInputElement).checked;

  if (isChecked) {
    if (totalDenialReasons.value >= 4) {
      functions.openModal({
        title: "Alert",
        description: "You have reached the maximum of 4 denial reasons.",
      });
      (event.target as HTMLInputElement).checked = false;
      return;
    }

    const exists = denialReasonEntries.value.some(
      (entry) =>
        entry.reasonId === selectedReason.value &&
        entry.applicantId === applicantId
    );
    if (!exists) {
      denialReasonEntries.value.push({
        reasonId: selectedReason.value as number,
        applicantId: applicantId,
      });
    }
  } else {
    // Remove the denial reason for this applicant
    denialReasonEntries.value = denialReasonEntries.value.filter(
      (entry) =>
        !(
          entry.reasonId === selectedReason.value &&
          entry.applicantId === applicantId
        )
    );
  }
}

let checkboxFieldsSchema = reactive({} as FormSchema);
// let denialReasons = reactive({} as any);
let selectedDenialReasons = ref([] as any[]);
let selectedDenialReasonIds = ref([] as number[]);
let reasonIds = ref([] as number[]);

// cancel status change
async function cancel() {
  showStatusDropdown.value = false;
  showDenialReasonSection.value = false;
  showDenialReasonDropdown.value = false;
  await resetDropdown();
}

// reset dropdown form
async function resetDropdown() {
  info.newApplicationStatusId = undefined;
  info.newStatusName = undefined;
  info.statusComment = undefined;
  info.otherDenialReason = undefined;
  resetDenialReasons();
  await setApplicationStatus();
}

// set application status info
async function setApplicationStatus() {
  info.applicationStatusName = props?.application.data.applicationStatusName;
  info.applicationStatusId = props?.application.data.applicationStatusId;
}

// update judgmental denials
async function updateJudgmentalDenials(request: any) {
  console.log("Request Body:", request);
  try {
    const otherReasonId = 36;
    let otherJudgmentTypeReasonText = "";

    const filteredApplicantJDTypeIds = request.ApplicantJDTypeIds.filter(
      (item: any) => {
        if (item.judgmentDenialTypeId === otherReasonId) {
          otherJudgmentTypeReasonText = info.otherDenialReason || "";
          return true; // Keep the "Other" reason
        }
        return item.judgmentDenialTypeId !== otherReasonId;
      }
    );

    const updatedRequest = {
      ...request,
      ApplicantJDTypeIds: filteredApplicantJDTypeIds,
      otherJudgmentTypeReasonText: otherJudgmentTypeReasonText,
    };

    console.log("Updated Request Body:", updatedRequest);

    const response = await $api.applications.addOrUpdateJudgementDenials(
      props?.application?.data?.id,
      updatedRequest
    );

    if (response.errors || !response.applicationId) {
      functions.openModal({
        title: "Error",
        description: "There was an issue updating the Application Status",
        buttons: [
          {
            title: "Close",
            onClick: async () => {
              functions.closeModal();
              await resetDropdown();
            },
          },
        ],
      });
      return false;
    } else {
      emit("getApplication");
      return true;
    }
  } catch (error) {
    console.log("Error:", error);
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the Application Status",
    });
    return false;
  }
}

// update application status
async function updateApplicationStatus() {
  let userDisplayName = `${decodeHTMLEncodedStr(
    props?.store?.state?.oidcStore?.user?.given_name
  )} ${decodeHTMLEncodedStr(
    props?.store?.state?.oidcStore?.user?.family_name
  )}`;

  let request = {
    id: 0,
    applicationId: props?.application.data.id,
    comment: info.statusComment,
    userId: props?.store.state.oidcStore.user.sub,
    userDisplayName: userDisplayName,
  };

  const response = await $api.applications.updateApplicationStatus(
    props?.application?.data?.id,
    info?.applicationStatusId,
    request
  );

  if (response.errors || !response.applicationId) {
    functions.openModal({
      title: "Error",
      description: "There was an issue updating the Application Status",
    });
    return false;
  } else {
    emit("getApplication");
    return true;
  }
}

// save status change
async function saveStatusChange() {
  functions.setModalLoading();

  const applicantJDTypeIds = denialReasonEntries.value
    .map((entry) => {
      const applicant = applicants.value.find(
        (applicant: any) => applicant.id === entry.applicantId
      );
      if (!applicant) {
        console.log("Applicant not found", entry.applicantId);
        return null;
      }

      let applicantTypeId: number | null = null;
      if (applicant.applicantTypeId) {
        applicantTypeId = applicant.applicantTypeId;
      } else if (applicant.applicantTypeName) {
        if (applicant.applicantTypeName === "Primary") {
          applicantTypeId = 1;
        } else if (applicant.applicantTypeName === "Co-Applicant") {
          applicantTypeId = 2;
        } else {
          console.log("Applicant Type not found", applicant.applicantTypeName);
          return null;
        }
      } else {
        console.log(
          `Applicant with id ${entry.applicantId} has no typeId or typeName`
        );
        return null;
      }

      return {
        applicantTypeId: applicantTypeId,
        judgmentDenialTypeId: entry.reasonId,
      };
    })
    .filter((item) => item !== null);

  const applicationJudgementDenialRequest = {
    applicationId: props?.application.data.id,
    ApplicantJDTypeIds: applicantJDTypeIds,
    otherJudgmentTypeReasonText: info?.otherDenialReason || "",
  };

  console.log("Request Body:", applicationJudgementDenialRequest);

  let response = false;

  if (
    info.newApplicationStatusId &&
    info.newApplicationStatusId !==
      props?.application?.data?.applicationStatusId
  ) {
    response = await updateApplicationStatus();
    if (!response) {
      functions.setModalLoading();
      return;
    }
  }
  if (
    info.newStatusName === "Declined" ||
    (info.applicationStatusName === "Declined" &&
      info.newStatusName === undefined)
  ) {
    // info?.applicationStatusName === "Declined" ||
    // judgmentDenialTypes.length > 0) {
    response = await updateJudgmentalDenials(applicationJudgementDenialRequest);
    if (!response) {
      functions.setModalLoading();
      return;
    }
  } else {
    response = true;
  }

  if (response) {
    window.scrollTo(0, 0);
    // closes any open modals
    functions.closeModal();
    showStatusDropdown.value = false;

    functions.openModal({
      title: "Success!",
      description: "The Application Status has been changed!",
      buttons: [
        {
          title: "Close",
          onClick: async () => {
            functions.closeModal();
            showStatusDropdown.value = false;
            info.statusComment = undefined;
            info.otherDenialReason = undefined;
            denialReasonEntries.value = [];
            showDenialReasonSection.value = false;
          },
        },
      ],
    });
  }
}

// handle dropdown click
function dropdownClicked() {
  showStatusDropdown.value = !showStatusDropdown.value;
  resetDenialReasons();
  setApplicationStatus();
}

// handle input change
function handleInput(event: any) {
  info.newStatusName = event.name;
  info.newApplicationStatusId = event.id;
  info.applicationStatusId = info.newApplicationStatusId;

  // only allow declined to be selected when in eval or submitted & processing
  if (
    (props?.application.data.applicationStatusName === "Evaluation" &&
      info.newStatusName === "Declined") ||
    (props?.application.data.applicationStatusName ===
      "Submitted & Processing" &&
      info.newStatusName === "Declined")
  ) {
    resetDenialReasons();
    showDenialReasonSection.value = true;
    showDenialReasonDropdown.value = true;
  } else if (
    info.newStatusName === props?.application?.data?.applicationStatusName &&
    props?.application?.data?.applicationStatusName !== "Declined"
  ) {
    info.newStatusName = undefined;
  } else if (
    (props?.application.data.applicationStatusName === "Declined" &&
      info.newStatusName === "Declined") ||
    (props?.application.data.applicationStatusName !== "Evaluation" &&
      props?.application.data.applicationStatusName !==
        "Submitted & Processing" &&
      info.newStatusName === "Declined")
  ) {
    functions.openModal({
      title: "Alert",
      description:
        "This status change is not allowed from the current application status",
      buttons: [
        {
          title: "Close",
          onClick: async () => {
            functions.closeModal();
            await setApplicationStatus();
          },
        },
      ],
    });
  } else {
    showDenialReasonSection.value = false;
    showDenialReasonDropdown.value = false;
  }
}

// reset denial reasons
function resetDenialReasons() {
  selectedDenialReasons.value = [];
  selectedDenialReasonIds.value = [];
  denialReasonEntries.value = [];
  previouslySelectedReasonIds.value = [];
  previouslySelectedReasons.value = [];
}

// confirm status change
function confirmStatusChange() {
  const isDeclinedStatus =
    info.newStatusName === "Declined" ||
    info.applicationStatusName === "Declined" ||
    (info.applicationStatusName === "Declined" &&
      info.newStatusName === undefined);

  let description = "Are you sure you want to change this status?";
  // adding denial reasons for first time
  if (isDeclinedStatus && previouslySelectedReasons.value.length === 0) {
    description =
      "Selected decline reasons will be used on AA Notices and CANNOT be changed once you proceed. Are you sure you want to change this status?";
  }
  functions.openModal({
    title: "Confirm",
    description: description,
    buttons: [
      { title: "Cancel", onClick: () => functions.closeModal() },
      { title: "Save Changes", onClick: () => saveStatusChange() },
    ],
  });
}

// handle form submit
function submit() {
  const isDeclinedStatus =
    info?.newStatusName === "Declined" ||
    (info?.newStatusName === undefined &&
      info?.applicationStatusName === "Declined");

  const cleanedComment = info?.statusComment
    ? info?.statusComment?.trim().length !== 0
      ? info?.statusComment?.trim()
      : undefined
    : undefined;

  info.statusComment = cleanedComment;

  if (isDeclinedStatus && denialReasonEntries.value.length === 0) {
    functions.openModal({
      title: "Alert",
      description: "Please select at least one denial reason.",
    });
    return;
  }

  if (isDeclinedStatus) {
    // Handle no changes
    if (
      previouslySelectedReasonIds.value.length === reasonIds.value.length &&
      info?.statusComment === undefined
    ) {
      functions.openModal({
        title: "Alert",
        description: "No changes were made. Do you want to make changes?",
        buttons: [
          {
            title: "Yes",
            onClick: async () => {
              functions.closeModal();
            },
          },
          {
            title: "No",
            onClick: async () => {
              functions.closeModal();
              showStatusDropdown.value = false;
              await resetDropdown();
            },
          },
        ],
      });
      return;
    }

    // Handle comment
    if (
      (info?.statusComment !== undefined && info?.statusComment?.length < 5) ||
      (previouslySelectedReasonIds.value.length > 0 &&
        info?.statusComment !== undefined &&
        info?.statusComment?.length < 5)
    ) {
      functions.openModal({
        title: "Alert",
        description:
          "A comment of at least 5 characters is required to change the status!",
      });
      return;
    }

    // Handle "Other" reason
    if (
      otherDenialReasonSelected.value &&
      (!info.otherDenialReason || info.otherDenialReason.trim().length < 5)
    ) {
      functions.openModal({
        title: "Alert",
        description:
          "Please provide a description of at least 5 characters for the Other reason.",
      });
      return;
    }

    confirmStatusChange();
  } else {
    if (
      (info?.newStatusName === undefined &&
        info?.statusComment === undefined) ||
      (info?.newStatusName === info?.applicationStatusName &&
        info?.statusComment === undefined)
    ) {
      functions.openModal({
        title: "Alert",
        description: "No changes were made. Do you want to make changes?",
        buttons: [
          {
            title: "Yes",
            onClick: async () => {
              functions.closeModal();
            },
          },
          {
            title: "No",
            onClick: async () => {
              functions.closeModal();
              showStatusDropdown.value = false;
              await resetDropdown();
            },
          },
        ],
      });
    } else if (
      (info?.newStatusName !== info?.applicationStatusName &&
        info?.statusComment === undefined) ||
      (info?.newStatusName !== info?.applicationStatusName &&
        info?.statusComment !== undefined &&
        info?.statusComment?.length < 5)
    ) {
      functions.openModal({
        title: "Alert",
        description:
          "A comment of at least 5 characters is required to change the status!",
      });
    } else {
      confirmStatusChange();
    }
  }
}

watch(
  () => info.newStatusName,
  (newValue: any) => {
    // rule: never show denial reasons on any other statuses
    if (newValue !== "Declined") {
      showDenialReasonSection.value = false;
      showDenialReasonDropdown.value = false;
    } else {
      // rule: always shown denial reasons when 'Declined' is selected
      showDenialReasonSection.value = true;
      showDenialReasonDropdown.value = true;
      // rule: original status is declined, do not wipe out reasons
      if (props?.application?.data?.applicationStatusName === "Declined") {
        resetDenialReasons();
      }
      // rule: original status is NOT declined, wipe out reasons when toggling
      // else {
      //   if (oldValue === "Declined" || newValue === "Declined") {
      //     resetDenialReasons();
      //   }
      // }
    }
  }
);

watch(selectedReason, (newValue: any) => {
  if (newValue === null) {
    selectedReason.value = null;
  }
});

watch(
  () => props?.application?.data?.applicationStatusName,
  async () => {
    info.applicationStatusId = props?.application?.data?.applicationStatusId;
    await setApplicationStatus();
  }
);

watch(totalDenialReasons, (newValue: any) => {
  console.log("Unique Denial Reasons:", newValue);
});

onMounted(async () => {
  await setApplicationStatus();
});

onBeforeMount(() => {
  if (jdts.value) {
    jdts.value.forEach((jdt: any) => {
      checkboxFieldsSchema[jdt.name] = {
        type: "checkbox",
        name: jdt.name,
        label: jdt.description,
        required: false,
        hideLabel: true,
      };
    });
  }
});

return (_ctx: any,_cache: any) => {
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", { onClick: dropdownClicked }, [
        _createTextVNode(_toDisplayString(_ctx.application?.data?.applicationStatusName) + " ", 1),
        (showStatusDropdown.value)
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : (_openBlock(), _createElementBlock("i", _hoisted_4))
      ]),
      (showStatusDropdown.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", {
                onSubmit: _withModifiers(submit, ["prevent"]),
                autocomplete: "off"
              }, [
                _createElementVNode("nav", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.types['ApplicationStatus']?.results?.filter((s) => s.visible === true), (status) => {
                    return (_openBlock(), _createElementBlock("label", {
                      class: "xradio label-text",
                      key: status.name
                    }, [
                      _createTextVNode(_toDisplayString(status.name) + " ", 1),
                      _createElementVNode("input", {
                        type: "radio",
                        name: `radio${status.id}`,
                        checked: info.applicationStatusId === status.id,
                        onInput: ($event: any) => (handleInput(status))
                      }, null, 40, _hoisted_7),
                      _cache[4] || (_cache[4] = _createElementVNode("span", null, null, -1))
                    ]))
                  }), 128)),
                  (info.newStatusName !== 'Declined')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _cache[5] || (_cache[5] = _createElementVNode("h3", {
                          class: "smallcapitals",
                          style: {"padding-top":"10px"}
                        }, " Reason For Change: ", -1)),
                        _createElementVNode("div", null, [
                          _withDirectives(_createElementVNode("textarea", {
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((info.statusComment) = $event)),
                            class: "reason-input"
                          }, "                  ", 512), [
                            [_vModelText, info.statusComment]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("button", {
                            onClick: _withModifiers(cancel, ["prevent"])
                          }, "Cancel"),
                          _createElementVNode("button", {
                            onClick: _withModifiers(submit, ["prevent"])
                          }, "Save Changes")
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 32)
            ])
          ]))
        : _createCommentVNode("", true),
      (showDenialReasonSection.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "label-head" }, [
                  _createTextVNode("Denial Reason(s)"),
                  _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                ], -1)),
                _cache[9] || (_cache[9] = _createElementVNode("p", null, "Please select a reason for the denial from the list below.", -1)),
                _cache[10] || (_cache[10] = _createElementVNode("span", { class: "label-semi-bold" }, "Denial Reason List:", -1)),
                _createElementVNode("div", _hoisted_13, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedReason).value = $event)),
                    disabled: totalDenialReasons.value >= 4
                  }, [
                    _cache[6] || (_cache[6] = _createElementVNode("option", {
                      value: null,
                      disabled: ""
                    }, "Not Selected", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jdts.value, (jdt) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: jdt.id,
                        value: jdt.id
                      }, _toDisplayString(jdt.description), 9, _hoisted_15))
                    }), 128))
                  ], 8, _hoisted_14), [
                    [_vModelSelect, selectedReason.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("button", {
                    onClick: _withModifiers(addAnotherReason, ["prevent"]),
                    disabled: totalDenialReasons.value >= 4,
                    class: "add-btn"
                  }, " + Add Another Reason ", 8, _hoisted_17)
                ]),
                (totalDenialReasons.value >= 4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[7] || (_cache[7] = [
                      _createElementVNode("p", null, "You have reached the maximum of 4 denial reasons.", -1)
                    ])))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "label-head" }, [
                  _createTextVNode("Requested For"),
                  _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                ], -1)),
                _cache[12] || (_cache[12] = _createElementVNode("p", null, "Select at least one applicant to apply the denial reason(s).", -1)),
                _createElementVNode("div", _hoisted_20, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applicants.value, (applicant) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "applicant-column",
                      key: applicant.id
                    }, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(applicant.applicantTypeName), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("input", {
                          type: "checkbox",
                          value: applicant.id,
                          checked: isApplicantSelectedForReason(applicant.id),
                          onChange: ($event: any) => (toggleApplicantForReason(applicant.id, $event)),
                          disabled: !selectedReason.value
                        }, null, 40, _hoisted_23)
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_24, [
                (denialReasonEntries.value.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("ul", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(denialReasonEntries.value, (entry, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: "added-denial-reason"
                          }, [
                            _createElementVNode("div", _hoisted_26, [
                              _createElementVNode("span", _hoisted_27, _toDisplayString(index + 1) + ".", 1),
                              _createElementVNode("span", _hoisted_28, _toDisplayString(getDenialReasonName(entry.reasonId)), 1),
                              _createElementVNode("span", _hoisted_29, [
                                _cache[13] || (_cache[13] = _createTextVNode(" - Applicant(s): ")),
                                _createElementVNode("span", null, _toDisplayString(getApplicantName(entry.applicantId)), 1)
                              ])
                            ]),
                            _createElementVNode("button", {
                              onClick: _withModifiers(($event: any) => (removeReason(index)), ["prevent"]),
                              class: "remove-reason"
                            }, " X ", 8, _hoisted_30)
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("h3", {
                class: "smallcapitals",
                style: {"padding-top":"10px"}
              }, [
                _createTextVNode(" Reason For Change:"),
                _createElementVNode("i", { style: {"color":"crimson"} }, "*")
              ], -1)),
              _createElementVNode("div", null, [
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((info.statusComment) = $event)),
                  class: "reason-input"
                }, "            ", 512), [
                  [_vModelText, info.statusComment]
                ])
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _cache[14] || (_cache[14] = _createElementVNode("h3", { class: "smallcapitals" }, [
                  _createTextVNode(" Other Reason:"),
                  _createElementVNode("i", { style: {"color":"crimson"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((info.otherDenialReason) = $event)),
                  required: otherDenialReasonSelected.value,
                  maxlength: "128",
                  class: "reason-input"
                }, "            ", 8, _hoisted_31), [
                  [_vModelText, info.otherDenialReason]
                ])
              ], 512), [
                [_vShow, otherDenialReasonSelected.value]
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("button", {
                  onClick: _withModifiers(cancel, ["prevent"])
                }, "Cancel"),
                _createElementVNode("button", {
                  onClick: _withModifiers(submit, ["prevent"])
                }, "Save Changes")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_auto_animate, _ctx.animate]
  ])
}
}

})